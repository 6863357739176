import React from "react";
import { Link } from "react-router-dom";

import Loaneligibility from "./LoanEligibility";
import Docrequired from "./documentsRequired";
import Namepurpose from "./youNameYourPurpose";
import Differentfrom from "./differentFromOthers";
import FreqAskedQuestions from "./frequentlyAskedQuestion";
import Featuresicon2 from "../assets/Features-icon2.svg";
import Featuresicon3 from "../assets/Features-icon3.svg";

import Featuresicon1 from "../assets/Features-icon1.svg";
import Featuresicon4 from "../assets/Features-icon4.svg";

import ABB_Loan_Page_Animation from "../assets/ABB_Loan_Page_Animation.gif";

import ProductHighlights from "../assets/Product_Highlights.svg";
import producthighlightmobile from "../assets/producthighlight-mobile.svg";
import Calc from "../functions/calculate";
import HidenavBar from "../functions/hideNavBar";

export default class averageloan extends React.Component {
  componentDidMount() {
    document.title = "Finplex: Term Loans";
  }
  render() {
    return (
      <>
        <div className="fontStyle about">
          <section>
            <div className="container-fluid container-xxl">
              <div className="margin-top d-none d-xl-block">
                <div className="heading-min row px-5">
                  <p className="textcolor">Secured Loans</p>
                </div>
                <div className="heading row px-5">
                  <p>
                    Searching for long-term financing for your business or
                    for a property?{" "}
                  </p>
                </div>

                <div className="row px-5">
                  <p className="heading-min">
                    Wide spectrum of financial solutions: Loan against Property,
                    Housing Loan, Refinancing of Property, Composite Loan,
                    Balance Transfer, Top-up Loan, Home Improvement and
                    Extension Loan, Construction Loan, and Commercial Property
                    Purchase.
                  </p>
                </div>
                <div className="row px-5 mt-4">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-3">
                        <img alt="" src={Featuresicon2} className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img alt="" src={Featuresicon3} className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img alt="" src={Featuresicon1} className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img alt="" src={Featuresicon4} className="img-fluid" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3 line">
                        Funds without the burden of securing assets against the
                        loan.
                      </div>
                      <div className="col-3 line">
                        Loans can be used for any purpose - Business growth,
                        Purchasing New Equipment, Working Capital Finance,
                        Expansion of business locales, Inventory purchase and
                        also to Hire more staff
                      </div>
                      <div className="col-3 line">
                        Simple documents submission
                      </div>
                      <div className="col-3">
                        Tenure and interest rate flexible and customisable{" "}
                      </div>
                    </div>
                    <div class="mt-5">
                      <Link class="text-white nav-btn mt-5" to="/quick">
                        QUICK APPLY
                      </Link>
                    </div>
                  </div>
                  <div className="col-5 d-flex align-items-end">
                    <img
                      alt=""
                      className="ABB-image"
                      src={ABB_Loan_Page_Animation}
                    />
                  </div>
                </div>
              </div>
              <div className="d-xl-none">
                <p className="heading-min margin-top text-center">
                  Secured Loans
                </p>
                <p className="heading text-center">
                  Searching for long-term financing for your business or for
                  a property?
                </p>
                <div className="row px-3 flex-column-reverse mt-5">
                  <div className="col-12 ">
                    <p className="heading-min mt-5">
                      Wide spectrum of financial solutions: Loan against
                      Property, Housing Loan, Refinancing of Property, Composite
                      Loan, Balance Transfer, Top-up Loan, Home Improvement and
                      Extension Loan, Construction Loan, and Commercial Property
                      Purchase.
                    </p>
                    <div className=" d-none d-md-block">
                      <div className="row mt-5 ">
                        <div className="col-3">
                          <img
                            alt=""
                            src={Featuresicon2}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-3">
                          <img
                            alt=""
                            src={Featuresicon3}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-3 ">
                          <img
                            alt=""
                            src={Featuresicon1}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-3">
                          <img
                            alt=""
                            src={Featuresicon4}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 content-text lh-sm">
                      <div className="d-md-none mt-5">
                        <div className="row">
                          <div className=" col-6 ">
                            <img
                              alt=""
                              src={Featuresicon2}
                              className="img-fluid"
                            />
                          </div>
                          <div className=" col-6 ">
                            <img
                              alt=""
                              src={Featuresicon3}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-3 col-6 col-md-3 mt-3 mt-md-0 line">
                        Funds without the burden of securing assets against the
                        loan.
                      </div>
                      <div className="px-2 col-6 col-md-3 mt-3 mt-md-0 line-md">
                        Loans can be used for any purpose - Business growth,
                        Purchasing New Equipment, Working Capital Finance,
                        Expansion of business locales, Inventory purchase and
                        also to Hire more staff
                      </div>
                      <div className="d-md-none mt-5">
                        <div className="row">
                          <div className=" col-6 ">
                            <img
                              alt=""
                              src={Featuresicon1}
                              className="img-fluid"
                            />
                          </div>
                          <div className=" col-6">
                            <img
                              alt=""
                              src={Featuresicon4}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 line mt-3 mt-md-0">
                        Simple documents submission
                      </div>
                      <div className="col-6 col-md-3 mt-3 mt-md-0">
                        Tenure and interest rate flexible and customisable
                      </div>
                      <div class="d-flex mt-3 mt-xl-5 justify-content-center">
                        <Link
                          class="text-white d-flex align-items-center justify-content-center nav-btn mt-5"
                          to="/quick"
                        >
                          QUICK APPLY
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <img
                      alt=""
                      className="img-fluid"
                      src={ABB_Loan_Page_Animation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="loan-bg d-none d-md-block mt-5">
            <div className="container-xxl container-xl container-lg container-md">
              <div className="text-center">
                <img
                  alt=""
                  src={ProductHighlights}
                  className="img-fluid my-5"
                />
              </div>
            </div>
          </div>
          <div className="loan-bg d-md-none mt-5">
            <div className="container-xxl container-xl container-lg container-md">
              <div className="text-center">
                <img
                  alt=""
                  src={producthighlightmobile}
                  className="img-fluid my-5"
                />
              </div>
            </div>
          </div>
          <Loaneligibility />
          <Docrequired />
          <Namepurpose />
          <Differentfrom />
          <div className="mt-5 loan-bg">
            <div className="container-fluid container-xxl">
              <div className="row mt-5">
                <div className="col-sm-12 mt-sm-4 px-xl-5">
                  <h1 className="text-center sub-title">
                    Secured Loans Calculator
                  </h1>
                  <Calc />
                  <p className="mb-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid container-xxl">
            <FreqAskedQuestions />
          </div>
        </div>
        <HidenavBar />
      </>
    );
  }
}
