// import Contact from './contactUs';
import React from 'react';
import TabsCaseStudyComponent from './tabNavigationCaseStudy';
import MarketCaseStudy from './marketCaseStudy';
// import Head from "next/head";



const CaseStudyPage = (props) => {
    
    return (

        <>
        {/* <Head>
            <meta name="title" content="API and Ecosystem Insights" />
            <meta name="description" content="Strategies, Tactics, Best Practices, Case Studies and more" />        
            <meta name="og:title" property="og:title" content="API and Ecosystem Insights" />   
            <meta name="og:description" property="og:description" content="Strategies, Tactics, Best Practices, Case Studies and more" />                        
            <meta name="image" src="./images/caseStudyHeader.jpg" />
            <meta name="og:image" property="og:image" src="./images/caseStudyHeader.jpg" />    
        </Head> */}
            {/* <div className="relative min-h-0">
            <img src="/images/caseStudyHeader.jpg" alt="" className="hidden md:block  w-screen object-cover    " style={{width:'1440' ,height: '350' }} />
                <div className="absolute top-0 right-0 bottom-0 left-0">
                    <div className="flex justify-center items-center h-full ">
                        <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Ecosystem Insights</p>
                            <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-24 xl:px-[12.5rem] 2xl:px-24  mt-3"> Strategies, Tactics, Best Practices, Case Studies and more...</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="relative min-h-0">
            <img src="/images/Insights.png" alt="" className="md:hidden block  w-screen   " style={{width:'375' ,height: '350' }} />
                <div className="absolute top-0 right-0 bottom-0 left-0">
                    <div className="flex justify-center items-center h-full">
                        <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 md:hidden block">
                            <p className="  text-5xl sm:text-7xl   font-bold mt-0  text-white lg:leading-[58px]">API & Ecosystem Insights</p>
                            <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Strategies, Tactics, Best Practices, Case Studies and more...</p>
                        </div>
                    </div>
                </div>
            </div> */}

                    {/* <div className="relative overflow-hidden bg-no-repeat bg-cover bg-caseStudyHeader hidden 2xl:block" style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Ecosystem Insights</p>
                            <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-24 xl:px-[12.5rem] 2xl:px-24  mt-3"> Strategies, Tactics, Best Practices, Case Studies and more...</p>
                        </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-hidden bg-no-repeat bg-cover bg-InsightsHeader1 hidden 2xl:hidden lg:block" style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Ecosystem Insights</p>
                            <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-24 xl:px-[12.5rem] 2xl:px-24  mt-3"> Strategies, Tactics, Best Practices, Case Studies and more...</p>
                        </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-hidden bg-no-repeat bg-cover bg-InsightsHeader2 hidden sm:block lg:hidden " style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 sm:block lg:hidden ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Ecosystem Insights</p>
                            <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-24 xl:px-[12.5rem] 2xl:px-24  mt-3"> Strategies, Tactics, Best Practices, Case Studies and more...</p>
                            </div>
                            </div>
                        </div>
                    </div>
            
           
                <div className="relative overflow-hidden bg-no-repeat bg-cover bg-InsightsHeader3 block sm:hidden" style={{ backgroundPosition: '50%',  height: '350px' }}>
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                    <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12  block sm:hidden ">
                    <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Ecosystem Insights</p>
                            <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-24 xl:px-[12.5rem] 2xl:px-24  mt-3"> Strategies, Tactics, Best Practices, Case Studies and more...</p>
                          </div>
                </div>
            </div>
        </div> */}

            <div className="container-xxl m-auto ">
                <div className='space-top'>
                <div className="  text-light_gray_950 mx-lg-5 mx-3 "><a href="./blog" className='text-decoration-none home-text' >  Blog </a> <span className="heading-text"> / </span> <a className='heading-text text-decoration-none'> Trending</a></div>
                <p className="subtitle  text-left mx-lg-5 mx-3  ">Trending</p>
                </div>
                </div>
              
              <div className='lg:px-2 mb-10 '>
            <TabsCaseStudyComponent insightsprops={props}/>
            </div>
            
        </>
    )
}
export default CaseStudyPage;
