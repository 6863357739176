import axios from 'axios';
import { useEffect, useState } from 'react';
import CardLayoutTwoCol from './cardLayout';
import { baseCMSUrl } from "./constant";
import { Link } from "react-router-dom";
import qs from "qs";
import ReactPaginate from 'react-paginate';
import ScrollToTop from "./scrollToTop";

const MarketEvent = (props) => {
    const [marketEventsData, setMarketEventsData] = useState([]);   
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const item = 9; 
    const pageCount = Math.ceil(total / item);
  
    useEffect(() => {

        const fetchPosts = async () => {
            const query = qs.stringify(
                {
                    pagination: {
                        page: currentPage,
                        pageSize: 9
                    },
                    filters: {
                        technology_tags: {
                            title: {
                                $contains: props.filter,
                            },
                        },
                    },

                    populate: '*',
                },
                {
                    encodeValuesOnly: true
                }
            );
            const response = await axios.get(`${baseCMSUrl}/api/technologies?${query}`);
            setMarketEventsData(response.data.data.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)));
            setTotal(response.data.meta.pagination.total);
        };
        fetchPosts();
    }, [currentPage, props]);
    const handlePageChange = (page) => {
        setCurrentPage(page.selected + 1);
        ScrollToTop();
    };
    return (

        <>         <div className="container-xxl m-auto"> 
                <div className="mx-lg-5 mx-md-3 row ">
                {marketEventsData?.slice(0, props.noOfTiles).map((blog) =>
                        <div className='col-12 px-md-0'>
                        <Link className='text-decoration-none' key={blog.id} to={`/TechnologyStory?id=${blog.id}`}>
                           <CardLayoutTwoCol date={blog.attributes.date} view={blog.attributes.view} venue={blog.attributes.venue} title={blog.attributes.title} desc={blog.attributes.description} videoUrl={blog?.attributes?.VideoId} image={`${baseCMSUrl}` + blog.attributes.image.data.attributes.url} flexClass="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 lg:gap-1  sm:mx-20 md:m-auto rounded overflow-hidden md:h-[16rem] lg:h-[15.5rem]  shadow-3xl" />
                        </Link>
                        </div>
                    )}
                </div>
                {total > 9 ? <ReactPaginate

                    previousLabel={currentPage === 1 ? "" : "<"}
                    nextLabel={currentPage === pageCount ? "" : ">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={" custom-container mr-5 pr-4"}
                    pageClassName={"custom-button mr-1"}
                    pageLinkClassName={"page-link"}
                    previousClassName={currentPage === 1 ? "disabled" : "page-item custom-element"}
                    previousLinkClassName={"page-link"}
                    nextClassName={currentPage === pageCount ? "disabled" : "page-item custom-element"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active-element"}
                    /> : ""}     
                </div>      
        </>
    )
}
export default MarketEvent;