import React from 'react'
import { FormGroup, Label, Input } from "reactstrap";
import { Form, Field } from "react-final-form";

function PersonalDetails(props) {
    return (
        <div className="">
        <Form
            onSubmit={props.sub}
            initialValues={{ 
                stepForm : 3,
                 firstname : props.stateValue.firstname,
                lastname : props.stateValue.lastname,
                email : props.stateValue.email,
                mobileNumber : props.stateValue.mobileNumber,
                loanAmount : props.stateValue.loanAmount,
                dateOfBirth : props.stateValue.dateOfBirth,
                panNumber : props.stateValue.panNumber,
                fatherName : props.stateValue.fatherName,
                spouseName : props.stateValue.spouseName,
                address : props.stateValue.address,
                addressType : props.stateValue.addressType,
                personalOwnershipType : props.stateValue.personalOwnershipType,
                cityTown : props.stateValue.cityTown,
                state : props.stateValue.state,
                pinCode : props.stateValue.pinCode
                }}
            validate={values => {
          const errors = {};
  
          function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          }
    
          function validateTextOnly(data) {
            var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;// /^[a-zA-Z]*$/;
            return re.test(String(data));
          }
    
          function validateNumberOnly(data) {
              var re = /^[0-9]*$/;
              return re.test(Number(data));
          }
    
          function validateNumberLoan(data) {
            var re = /^[0-9]*$/;
            return re.test(Number(data));
          }
    
          function validateNumberText (data) {
            var re = /^[a-zA-Z0-9]*$/;
            return re.test(data);
          }
    
          function validateAddress(data) {
              var re = /[a-zA-Z0-9,\.\_]/;
              return re.test(data);
          }
        
          if (!values.firstname) {
            errors.firstname = "Required";
          } else if (!validateTextOnly(values.firstname)) {
            errors.firstname = "Please enter letters only";
          }
    
          if (!values.lastname) {
            errors.lastname = "Required";
          } else if (!validateTextOnly(values.lastname)) {
            errors.lastname = "Please enter letters only";
          }
    
          if (!values.email) {
            errors.email = "Required";
          } else if (!validateEmail(values.email)) {
            errors.email = "Please enter valid email";
          }
    
          if (!values.mobileNumber) {
            errors.mobileNumber = "Required";
          } else if (!validateNumberOnly(values.mobileNumber)) {
            errors.mobileNumber = "Please enter valid number";
          } else if (values.mobileNumber.length < 10 ) {
            errors.mobileNumber = "Phone Number should contain 10 numbers";
          }

          
          if (!values.dateOfBirth) {
            errors.dateOfBirth = "Required";
          }
    
          if (!values.panNumber) {
              errors.panNumber = "Required";
          } else if(!validateNumberText(values.panNumber)) {
              errors.panNumber = "Please enter letters and numbers only";
          } else if (values.panNumber.length < 10 ) {
              errors.panNumber = "PAN should contain 10 digits";
          } else if (values.panNumber.length > 10 ) {
            errors.panNumber = "PAN should contain 10 digits";
          }
    
          if(!values.fatherName) {
            errors.fatherName = "Required";
          } else if (!validateTextOnly(values.fatherName)) {
              errors.fatherName = "Please enter letters only";
          } else if( values.fatherName.length < 3) {
            errors.fatherName = 'Please enter minimum 3 characters';
          }
    
          if(values.spouseName) {
            if (!validateTextOnly(values.spouseName)) {
              errors.spouseName = "Please enter letters only";
            } else if( values.spouseName.length < 3) {
              errors.spouseName = 'Please enter minimum 3 characters';
            }
          }
          
          if(!values.address) {
            errors.address = "Required";
          } else if (!validateAddress(values.address)) {
              errors.address = "Please enter valid address";
          } else if ( values.address.length < 3) {
            errors.address = 'Please enter minimum 3 characters';
          }

          if (!values.addressType) {
            errors.addressType = "Required";
          }

          if (!values.personalOwnershipType) {
            errors.personalOwnershipType = "Required";
          }
          
          if(!values.cityTown) {
            errors.cityTown = "Required";
          } else if (!validateTextOnly(values.cityTown)) {
            errors.cityTown = "Please enter letters only";
          } else if ( values.cityTown.length < 3) {
            errors.cityTown = 'Please enter minimum 3 characters';
          }
          
          if(!values.state) {
            errors.state = "Required" ;
          } else if (!validateTextOnly(values.state)) {
            errors.state = "Please enter letters only";
          } else if ( values.state.length < 3) {
            errors.state = 'Please enter minimum 3 characters';
          }
          
          if(!values.pinCode) {
            errors.pinCode = "Required";
          } else if (!validateNumberOnly(values.pinCode)) {
              errors.pinCode = "Please enter numbers  only";
          } else if (values.pinCode.length < 6 ) {
              errors.pinCode = "Pincode should contain 6 numbers";
          }
        return errors;
      }}
      render={({ handleSubmit }) => (
        <section id="contact-form" className="form-section wf-section loan about">
        <main className="form-container w-container">
          <h2 className="heading text-center margin-top">Apply Now</h2>
          <div className="row d-flex justify-content-center">
          <form className="field-text col-12 col-lg-9 px-3 px-xl-0" onSubmit={handleSubmit}>
          
          <h3 className="sub-heading">Primary Applicant</h3>
          <div className="formgroup row px-1 mt-4">
          <div className="fieldgroup left  col-md-6">
              <FormGroup>
                  <Label for="firstname" className="field-label">First Name</Label>
                  <Field name="firstname">
                  {({ input, meta }) => (
                      <div>
                      <Input
                          {...input}
                          type="text"
                          className="text-field w-input mb-2"
                          invalid={meta.error && meta.touched}
                      />
                      {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                      </div>
                  )}
                  </Field>
              </FormGroup>
          </div>
          <div className="fieldgroup right  col-md-6 mt-4 mt-md-0">
              <FormGroup>
                <Label for="lastname" className="field-label">Last Name</Label>
                <Field name="lastname">
                {({ input, meta }) => (
                    <div>
                    <Input
                        {...input}
                        type="text"
                        className="text-field w-input mb-2"
                        invalid={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                    </div>
                )}
                </Field>
              </FormGroup>
          </div>
        </div>
        <div className="formgroup row mt-4">
            <div className="fieldgroup left  col-md-6">
                <FormGroup>
                    <Label for="email" className="field-label">Email</Label>
                    <Field name="email">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="text"
                            className="text-field w-input mb-2"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
            <div className="fieldgroup right  col-md-6 mt-4 mt-md-0">
                <FormGroup>
                    <Label for="mobileNumber" className="field-label">Mobile Number<span className="fontSize"> OTP will be sent to this mobile number</span></Label>
                    <Field name="mobileNumber">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="tel"
                            className="text-field w-input mb-2"
                            maxLength="10"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
          </div>
          <div className="formgroup row mt-4">
            <div className="fieldgroup left  col-md-6">
                <FormGroup>
                    <Label for="dateOfBirth" className="field-label">Date of Birth</Label>
                    <Field name="dateOfBirth">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="date"
                            className="text-field w-input mb-2"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
            <div className="fieldgroup right  col-md-6 mt-4 mt-md-0">
                <FormGroup>
                    <Label for="panNumber" className="field-label">PAN</Label>
                    <Field name="panNumber">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="text"
                            className="text-field w-input mb-2"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
          </div>
          <div className="formgroup row mt-4">
            <div className="fieldgroup left  col-md-6">
                <FormGroup>
                    <Label for="fatherName" className="field-label">Father Name</Label>
                    <Field name="fatherName">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="text"
                            className="text-field w-input mb-2"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
            <div className="fieldgroup right  col-md-6 mt-4 mt-md-0">
                <FormGroup>
                    <Label for="spouseName" className="field-label">Spouse Name <span className="fontSize">(Optional)</span></Label>
                    <Field name="spouseName">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="text"
                            className="text-field w-input mb-2"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
          </div>
          <div className="formgroup row mt-4">
          <div className="fieldgroup left  col-md-6">
            <FormGroup>
                <Label for="address" className="field-label">Address</Label>
                <Field name="address">
                {({ input, meta }) => (
                    <div>
                    <Input
                        {...input}
                        type="text"
                        className="text-field w-input mb-2"
                        invalid={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                    </div>
                )}
            </Field>
            </FormGroup>
          </div>
          <div className="fieldgroup right col-md-6 mt-4 mt-md-0">
            <FormGroup>
                <Label for="addressType" className="field-label">Address Type</Label>
                <Field name="addressType">
                {({ input, meta }) => (
                    <div>
                    <Input
                        {...input}
                        type="select"
                        className="text-field w-input mb-2"
                        invalid={meta.error && meta.touched}
                    >
                    <option value="" disabled>--Choose--</option>
                    <option value="Current Residential Address">Current Residential Address</option>
                    <option value="Permanent Address">Permanent Address</option>
                    <option value="Third Property Address">Third Property Address</option>
                    <option value="Resi cum Office Address">Resi cum Office Address</option>
                    </Input>
                    {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                    </div>
                )}
                </Field>
            </FormGroup>
          </div>
        </div>
        <div className="formgroup row mt-4">
            <div className="fieldgroup left  col-md-6">
            <FormGroup>
                      <Label for="personalOwnershipType" className="field-label">OwnershipType</Label>
                      <Field name="personalOwnershipType">
                      {({ input, meta }) => (
                          <div>
                          <Input
                          {...input}
                          type="select"
                          className="text-field w-input mb-2"
                          invalid={meta.error && meta.touched}
                      > 
                        <option value="" disabled>--Choose--</option>
                        <option value="Self-Owned">Self-Owned</option>
                        <option value="Rented">Rented</option>
                        <option value="Leased">Leased</option>
                        <option value="Owned by Family">Owned by Family</option>
                        <option value="Owned by Relatives">Owned by Relatives</option>
                      </Input>
                          {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                          </div>
                      )}
                      </Field>
                  </FormGroup>
            </div>
            <div className="fieldgroup right  col-md-6 mt-4 mt-md-0">
            <FormGroup>
                    <Label for="cityTown" className="field-label">City</Label>
                    <Field name="cityTown">
                    {({ input, meta }) => (
                        <div>
                        <Input
                            {...input}
                            type="text"
                            className="text-field w-input mb-2"
                            invalid={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                        </div>
                    )}
                    </Field>
                </FormGroup>
            </div>
        </div>
        <div className="formgroup row mt-4">
          <div className="fieldgroup left  col-md-6">
          <FormGroup>
                <Label for="state" className="field-label">State</Label>
                <Field name="state" allowNull={true}>
                {({ input, meta }) => (
                    <div>
                    <Input
                        {...input}
                        type="text"
                        className="text-field w-input mb-2"
                        invalid={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                    </div>
                )}
                </Field>
            </FormGroup>
          </div>
          <div className="fieldgroup right  col-md-6 mt-4 mt-md-0">
            <FormGroup>
            <Label for="pinCode" className="field-label">Pincode</Label>
            <Field name="pinCode" allowNull={true}>
            {({ input, meta }) => (
                <div>
                <Input
                    {...input}
                    type="text"
                    className="text-field w-input mb-2"
                    maxLength="6"
                    invalid={meta.error && meta.touched}
                />
                {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                </div>
            )}
            </Field>
          </FormGroup>
          </div>
        </div>
        <div className="row marginTop justify-content-md-end align-items-center px-3">
        <a href="#" onClick={()=> props.preBtn(1)} className="link-color text-decoration-underline content-text text-center col-12 col-md-2 my-3 m-md-0 ">PREVIOUS</a>
                      <button className="nav-btn float-end content-text  text-white text-center col-12 col-md-4  my-3 m-md-0" type="submit" value="Next" data-wait="Please wait...">Next</button>
                    </div>
        
        {/* <input type="submit" value="Next" data-wait="Please wait..." className="primarybuttoncolor w-button"  /> */}
        <div className="copy-right-text-2"><label className="mt-3">© 2022 Finplex. All rights reserved and registered trademarks of Finplex.in. Terms and conditions, features, support, pricing, and service options subject to change without notice.</label></div>
        </form>
        </div>
        </main>
    </section>
      )}
    />
    </div>
    )
}

export default PersonalDetails;
