import React from "react";
import Logo from "../assets/LOGO.svg";
import Avg_Loan from "../assets/Avg_Loan.svg";
import IncomeLoan from "../assets/incomeLoan.svg";
import ProfitLoan from "../assets/ProfitLoan.svg";
import RevLoan from "../assets/Revenueloan.svg";
import CustomerSupport from "../assets/CustomerSupport.png";
// import arrow from "../assets/arrow.png"
import { Link } from "react-router-dom";
import "../styles/main.scss";
import Hidenavbar from "./Hidenavbar";
import Polygon1 from "../assets/Polygon 1.svg";
export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: false }

  }
  menuclick = () => {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    return (
      <>
        <div className="fontStyle">
          <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light active__menu" id="navbar">

            {/*    <nav
          className="navbar active__menu navbar-expand-sm fixed-top navbar-light bg-light flex-sm-nowrap flex-wrap animate__animated animate__fadeInDown"
          id="navbar"
        >
      */}<div className="container-fluid container-xxl fontStyle">
              <a href="/" className="navbar-brand my-3 my-lg-4 nav-sm ">
                <img className="brand-img " alt="" src={Logo} />
              </a>

              <div className=" d-lg-none">
                {this.state.visible === false ?
                  <button type="button" onClick={() => this.menuclick()} class="navbar-toggle navmenu collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNavbar" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <i class="fa-solid fa-bars fa-2x "></i>

                  </button> :
                  <button type="button" onClick={() => this.menuclick()} class="navbar-toggle navmenu collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNavbar" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <i class="fa-solid fa-xmark fa-2x"></i>
                  </button>}
                {console.log(this.state.visible, "visibleState")}
              </div>
              {/* <input
                type="checkbox"
                id="checkbox2"
                className="checkbox2 visuallyHidden"
              ></input>
               <label htmlFor="checkbox2">
                <div
                  className="hamburger hamburger2 navbar-toggle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNavbar"
                >
                  <span className="bar bar1" />
                  <span className="bar bar2" />
                  <span className="bar bar3" />
                  <span className="bar bar4" />
                </div>
              </label> */}

              <div className="navbar-collapse collapse nav-margin height justify-content-center" id="collapseNavbar">
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item dropdown">
                    <div className="d-lg-none">
                      <a
                        className="nav-link   nav-sm  underline px-lg-2 dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Loans
                      </a>
                      <div className="dropdown-menu px-3">

                        <a href="/securedLoans" className="dropdown-item">
                          Secured Loans
                        </a>
                        <a href="/unSecuredLoan" className="dropdown-item">
                          Unsecured Loans
                        </a>                        
                      </div>
                    </div>

                    <div className=" d-none d-lg-block">
                      <a className="nav-link dropdown-toggle nav-sm  underline  px-md-3 px-lg-2" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Loans
                      </a>
                      <div className="dropdown-menu shadow" aria-labelledby="navbarDropdown ">
                        <a href="/securedLoans" className="dropdown-item">
                          Secured Loans
                        </a>
                        <div className="border-bottom opacity"></div>
                        <a href="/unSecuredLoan" className="dropdown-item">
                          Unsecured Loans
                        </a>
                      </div>
                    </div>
                  </li>
               <li className="nav-item nav-sm mt-2 ">
                    <a
                      href="/aboutus"
                      className="nav-link underline px-lg-2"
                      id="navbarMargin"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item nav-sm mt-2 ">
                    <a
                      href="/partnerWithUs"
                      className="nav-link underline px-lg-2"
                      id="navbarMargin"
                    >
                      Partner With Us
                    </a>
                </li>

                <div className=" d-lg-none">
                    <li className="nav-item nav-sm mt-2 dropdown">
                      <a
                        href=""
                        className="nav-link underline px-lg-2 dropdown-toggle"
                        id="navbarMargin" data-bs-toggle="dropdown" aria-expanded="false"
                      >
                        Customer Center
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarMargin">
                        <li><a class="dropdown-item" href="./customerSupport">Customer Support</a></li>
                        <li><a class="dropdown-item" href="./freqaskedques">FAQ</a></li>
                        <li><a class="dropdown-item" href="./blog">Blog</a></li>
                        <li><a class="dropdown-item" href="./calculator">Calculator</a></li>

                      </ul>
                    </li>
                  </div>
                  
                  <div className=" d-none d-lg-block">
                    <li className="nav-item nav-sm mt-2 dropdown">
                      <a
                        href=""
                        className="nav-link underline px-lg-2 dropdown-toggle"
                        id="navbarMargin" data-bs-toggle="dropdown" aria-expanded="false"
                      >
                        Customer Center
                      </a>
                      <ul class="dropdown-menu shadow" aria-labelledby="navbarMargin">
                        <li><a class="dropdown-item" href="./customerSupport">Customer Support</a></li>
                        <div className="border-bottom opacity"></div>
                        <li><a class="dropdown-item" href="./freqaskedques">FAQ</a></li>
                        <div className="border-bottom opacity"></div>
                        <li><a class="dropdown-item" href="./blog">Blog</a></li>
                        <div className="border-bottom opacity"></div>
                        <li><a class="dropdown-item" href="./calculator">Calculator</a></li>

                      </ul>
                    </li>
                  </div>
                </ul>
                <hr />
                <div className="d-flex justify-content-center justify-content-lg-end">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a
                        href="https://lmsapp.dev.finplex.in/loanmgmtapp/signin"
                        className="nav-link underline py-lg-3  text-center px-lg-3 "
                        id="navbarMargin"
                        target="_blank"
                      >
                        Sign In
                      </a>
                    </li>
                    <li className="nav-item d-flex justify-content-center mt-3 mt-lg-0">
                      <a
                        href="/quick"
                        id="navbarMargin"
                        className="text-white nav-btn"
                      >
                        QUICK APPLY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div >
          </nav >
        </div >
        <Hidenavbar />
      </>
    );
  }
}