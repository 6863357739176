import React from "react";
import { Link } from "react-router-dom";

import Loaneligibility from "./LoanEligibility";
import Docrequired from "./documentsRequired";
import Namepurpose from "./youNameYourPurpose";
import Differentfrom from "./differentFromOthers";
import FreqAskedQuestions from "./frequentlyAskedQuestion";
import ProfitMarginloanAnimation from "../assets/ProfitMargin_loan_Animation.gif";
import percentage from "../assets/competitiveInterest_Rate.svg";
import rupee from "../assets/Rupee_Icon.svg";
import LoanIcon from "../assets/Term-Loan Icon.svg";
import Calc from "../functions/calculate";
import HidenavBar from "../functions/hideNavBar";

export default class unSecuredLoan extends React.Component {
  componentDidMount() {
    document.title = "Finplex: Unsecured Loans";
  }
  render() {
    return (
      <>
        <div className="fontStyle about">
          <section>
            <div className="container-fluid container-xxl">
              <div className="margin-top d-none d-xl-block">
                <div className="row px-5 px-xxl-1 mt-4">
                  <div className="col-8">
                    <div className="heading-min row">
                      <p>Unsecured Loans</p>
                    </div>
                    <div className="heading row">
                      <p>
                        Dreaming of innovation, Ready to conquer business
                        growth?
                      </p>
                    </div>

                    <div className="row pe-4">
                      <p className="heading-min pe-5">
                        Finplex stands with small and medium entrepreneurs,
                        offering seamless term loans at competitive rates,
                        enabling you to grow your business worry-free and turn
                        dreams into achievements{" "}
                      </p>
                    </div>

                    <div class="mt-1 mb-5">
                      <Link class="text-white nav-btn" to="/quick">
                        QUICK APPLY
                      </Link>
                    </div>
                  </div>
                  <div className="col-4 d-flex align-items-end">
                    <img
                      className="PBL-image"
                      src={ProfitMarginloanAnimation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="d-xl-none px-3 px-md-4">
                <p className="heading-min margin-top">Unsecured Loans</p>
                <p className="heading">
                  Dreaming of innovation, Ready to conquer business growth?
                </p>
                <div className="row mt-3">
                  <div className="col-11 col-md-10">
                    <p className="heading-min">
                      Finplex stands with small and medium entrepreneurs,
                      offering seamless term loans at competitive rates,
                      enabling you to grow your business worry-free and turn
                      dreams into achievements{" "}
                    </p>

                    <div class="mt-3 mt-xl-5 mb-5">
                      <Link class="text-white nav-btn mt-5" to="/quick">
                        QUICK APPLY
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="col-12 col-md-7 col-lg-5 text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src={ProfitMarginloanAnimation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="loan-bg">
            <div className="container-xxl container-xl container-lg px-sm-5 px-md-3 px-lg-0">
              <div className="px-3 px-sm-5 px-md-3 py-5 mt-5 mt-xl-0 p-xl-5">
                <section className="loan-bg card p-3 shadow border-0">
                  <div className="row justify-content-center align-items-center p-3 px-sm-5 px-md-0">
                    <div className="col-md-10">
                      <div className="row justify-content-center align-items-center ">
                    <div className="col-12 col-md-2 py-4 py-md-0 text-center">
                      <img alt="" className="img-fluid" src={LoanIcon} />
                      <hr className="mt-5 px-5 d-block d-md-none" />
                    </div>
                    <div className=" col-md-10 mt-1 border-left d-none d-md-block">
                      <div className="row">
                        {" "}
                        <p className="fw-400">
                          Term Loans & Working Capital Loans
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-4 border-right py-3  py-sm-4 py-md-0">
                          <div className="fw-400">Loan amount</div>
                          <div className="loanAmount">3 - 40 Lakhs</div>
                        </div>
                        <div className="col-12 col-md-4 border-right py-3  py-sm-4 py-md-0 pl-md-4 pr-md-2 pr-lg-4">
                          <div className="fw-400">Tenure</div>
                          <div className="loanAmount">1 - 3 Years</div>
                        </div>
                        <div className="col-12 col-md-4 pl-md-4 py-3 py-sm-4 py-md-0">
                          <div className="fw-400">Loan approval with in</div>
                          <div className="loanAmount">72 Hours*</div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>

                    <div className=" col-md-10 mt-1 d-block d-md-none ">
                      <div className="row">
                        {" "}
                        <p className="fw-400">
                          Term Loans & Working Capital Loans
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3">
                          <div className="fw-400">Loan amount</div>
                          <div className="loanAmount">3 - 40 Lakhs</div>
                          <hr className=" px-5 my-4" />
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 pl-md-4 pr-md-2 pr-lg-4">
                          <div className="fw-400">Tenure</div>
                          <div className="loanAmount">1 - 3 Years</div>
                          <hr className="px-5 my-4" />
                        </div>
                        <div className="col-12 col-md-4 pl-md-4">
                          <div className="fw-400">Loan approval with in</div>
                          <div className="loanAmount">72 Hours*</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="mt-5">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-1 col-lg-2"></div>
                    <div className="col-md-5 col-lg-4 text-md-center">
                      <span>
                        {" "}
                        <img alt="" className="img-fluid" src={percentage} />
                      </span>
                      <span className="ml-2 fw-400">
                        Competitive Interest Rate
                      </span>
                    </div>
                    <div className="col-md-5 col-lg-4 mt-3 mt-md-0 text-md-center">
                      <span>
                        {" "}
                        <img alt="" className="img-fluid" src={rupee} />
                      </span>
                      <span className="ml-2 fw-400">No Hidden Charges</span>
                    </div>
                    <div className="col-md-1 col-lg-2"></div>
                  </div>
                </div>
                <div className="mt-4 text-md-center loanImgTxtcolor">
                  * Receive loan amount in your bank account within 72 hours* of approval, and in certain instances, possibly even sooner.
                </div>
              </div>
            </div>
          </div>
          <Loaneligibility />
          <Docrequired />
          <Namepurpose />
          <Differentfrom />
          <div className="mt-5 loan-bg">
            <div className="container-fluid container-xxl">
              <div className="row mt-5">
                <div className="col-sm-12 mt-sm-4 px-xl-5">
                  <h1 className="text-center sub-title">
                    Unsecured Loan Calculator
                  </h1>
                  <Calc />
                  <p className="mb-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid container-xxl">
            <FreqAskedQuestions />
          </div>
        </div>
        <HidenavBar />
      </>
    );
  }
}
