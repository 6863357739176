import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
export default function CardLayoutTwoCol(props) {
    const onImageError = (e) => {
        e.target.src = placeholderImage
    }
    const placeholderImage =
        'https://images.unsplash.com/photo-1597484661973-ee6cd0b6482c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80';

        let description = props.desc?.split('.').slice(0, 2);
        let shortenedDescription = description?.join('.') + '...';

    return (
        <>
        
            

                
            <div className="card-shadow row my-3  gx-0 zoom-card">
               
                <div className="col-lg-5">
                    <img className=" img-fluid w-100 cardlayout-image" src={props.image? props.image : placeholderImage}  onError={onImageError} alt="Market News" />
                </div>

                
                {/* <img className="w-full rounded sm:h-full max-w-full h-auto" src={props.image? props.image : placeholderImage}  onError={onImageError} alt="Market News" /> */}
                <div className="my-auto col-lg-7 p-3">
                    <p className="card-title mt-0-md mb-3 ">{props.title}</p>
                    {props.date ? <><span className={`date-text text-light_gray-400 ${props.view ? ' border-light_gray-400 pr-2' : ''}`}>{props.date}</span> </> : null}
                    {props.view ?<> <span className={` ${props.view ? 'text-light_gray-400 pr-2 date-text' : ''} `}> | </span> <span className="date-text"> {props.view}</span> </> : null}
                    {props.venue ? <span className="date-text"> {props.venue}</span> : null}
                    {props.videoUrl ? (<><span className="mr-5 "></span> <span className="date-text"><svg className="date-text mr-1 " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.575 16.25L16.25 12L9.575 7.75V16.25ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20.5C14.3667 20.5 16.375 19.6708 18.025 18.0125C19.675 16.3542 20.5 14.35 20.5 12C20.5 9.63333 19.675 7.625 18.025 5.975C16.375 4.325 14.3667 3.5 12 3.5C9.65 3.5 7.64583 4.325 5.9875 5.975C4.32917 7.625 3.5 9.63333 3.5 12C3.5 14.35 4.32917 16.3542 5.9875 18.0125C7.64583 19.6708 9.65 20.5 12 20.5Z" fill="#999999"/>
                      </svg>Contains Video</span></>):""}
                    <div className="prose">
                    <ReactMarkdown className="card-desc line-clamp line-clamp-4 mt-3" children={props.desc}/>
                    </div>
                </div>
                
            </div>
            

        </>
    )
}