import React from "react";
import bgcardicon from "../assets/bg-cardicon.svg";
import Group1361 from "../assets/Group 1361.svg";
import newEquipment from "../assets/newEquipment.svg";
import capitalFinance from "../assets/capitalFinance.svg";
import locationadd from "../assets/location-add.svg";
import category from "../assets/category.svg";
import profile2user from "../assets/profile-2user.svg";

export default class namepurpose extends React.Component {
  render() {
    return (
      <>
        <section>
          <div className="container-xxl container-xl container-lg container-md">
            <div className="row d-flex justify-content-center mt-lg-5">
              <div className="col-10 col-md-9 col-xl-7">
                <p className="sub-title text-center">
                  Get funds for your Purpose
                </p>
              </div>
            </div>
            <div className="row px-4">
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={Group1361}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Business Growth</p>
                  <p className="cardText lh-sm">
                    Go ahead in your business journey with confidence. When you
                    are planning for expansion and finances block your path, our
                    loans clear the way for your success.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={newEquipment}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Purchasing New Equipment</p>
                  <p className="cardText lh-sm">
                    We turn your decision into reality. Achieving greater
                    scalability relies on having the right equipment. Our
                    specially crafted loan simplifies equipment purchase,
                    enabling cost spreading over years with ideal loan terms.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={capitalFinance}
                      alt=""
                      className="card-icon img-fluid mt-2 "
                    />
                  </div>
                  <p className="mt-4 cardTitle">Working Capital Finance</p>
                  <p className="cardText lh-sm">
                    Sometimes, businesses might face a shortage of funds for
                    daily operations due to other expenses, leaving a gap in
                    working capital. We provide financial solutions to ensure
                    smooth functioning.
                  </p>
                </div>
              </div>
              {/* </div>
            <div class="row mt-5">
             */}
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={locationadd}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Go Global</p>
                  <p className="cardText lh-sm">
                    Make a mark in the global business stage and serve customers
                    far and wide. Seize every opportunity to expand locally and
                    internationally, connecting with a diverse multinational
                    audience. Count on us for the financial support needed to
                    turn these visions into reality.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={category}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Inventory Purchase</p>
                  <p className="cardText lh-sm">
                    Getting the inventory right is crucial for any business.
                    Inventory not only ensures readiness for unexpected high
                    demands but also serves as a buffer for unexpected business
                    challenges. Our loans are crafted for obtaining the
                    necessary inventory.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={profile2user}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Hire More Staff</p>
                  <p className="cardText lh-sm">
                    Achieving complete business growth requires a skilled
                    staff that guides you toward the peaks of success. When you
                    hire people, you have to spend money. We're here with the
                    perfect credit solution for this.
                  </p>
                </div>
              </div>
              <p className="mb-5" />
            </div>
          </div>
        </section>
      </>
    );
  }
}
