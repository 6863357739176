import React from "react";
import { Link } from "react-router-dom";

import Loaneligibility from "./LoanEligibility";
import Docrequired from "./documentsRequired";
import Namepurpose from "./youNameYourPurpose";
import Differentfrom from "./differentFromOthers";
import FreqAskedQuestions from "./frequentlyAskedQuestion";
import Calc from "../functions/calculate";

import Featuresicon2 from "../assets/Features-icon2.svg";
import Featuresicon3 from "../assets/Features-icon3.svg";

import Featuresicon1 from "../assets/Features-icon1.svg";
import Featuresicon4 from "../assets/Features-icon4.svg";
import ProfitMarginloanAnimation from "../assets/ProfitMargin_loan_Animation.gif";

import ProductHighlights from "../assets/Product_Highlights.svg";
import producthighlightmobile from "../assets/producthighlight-mobile.svg";
import Hidenavbar from "../functions/hideNavBar";

export default class profitmargin extends React.Component {
  componentDidMount() {
    document.title = "Finplex: Loan Against Property";
  }
  render() {
    return (
      <>
        <div className="fontStyle about">
          <section>
            <div className="container-fluid container-xxl">
              <div className="margin-top d-none d-xl-block">
                <div className="heading-min row px-5">
                  <p className>Loan Against Property</p>
                </div>
                <div className="heading row px-5">
                  <p>Looking to amplify business dreams to assert?</p>
                </div>
                <div className="row px-5">
                  <p className="heading-min">
                    Finplex admires those prioritizing innovation, business
                    growth, and profit. Leave funding worries behind; our
                    low-interest property loans fuel your advancement.
                  </p>
                </div>
                <div className="row px-5 mt-4">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-3">
                        <img src={Featuresicon2} alt="" className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img src={Featuresicon3} alt="" className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img src={Featuresicon1} alt="" className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img src={Featuresicon4} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3 line">
                        Loan given against mortgage of property
                      </div>
                      <div className="col-3 line">
                        Loans can be used for any purpose - Business growth,
                        Purchasing New Equipment, Working Capital Finance,
                        Expansion of business locales, Inventory purchase and
                        also to Hire more staff
                      </div>
                      <div className="col-3 line">
                        Simple documents submission
                      </div>
                      <div className="col-3">
                        Tenure and interest rate flexible and customisable{" "}
                      </div>
                    </div>
                    <div className="mt-5">
                      <Link className="text-white nav-btn mt-5" to="/quick">
                        QUICK APPLY
                      </Link>
                    </div>
                  </div>
                  <div className="col-5 d-flex align-items-end">
                    <img
                      className="PBL-image"
                      src={ProfitMarginloanAnimation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="d-xl-none">
                <p className="heading-min margin-top  text-center">
                  Loan Against Property
                </p>
                <p className="heading text-center">
                  Looking to amplify business dreams to assert?
                </p>
                <div className="row px-3 flex-column-reverse">
                  <div className="col-12 ">
                    <p className="heading-min mt-4">
                      {" "}
                      Finplex admires those prioritizing innovation, business
                      growth, and profit. Leave funding worries behind; our
                      low-interest property loans fuel your advancement.
                    </p>
                    <div className=" d-none d-md-block">
                      <div className="row mt-5 ">
                        <div className="  col-3">
                          <img
                            src={Featuresicon2}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="  col-3">
                          <img
                            src={Featuresicon3}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className=" col-3 ">
                          <img
                            src={Featuresicon1}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className=" col-3">
                          <img
                            src={Featuresicon4}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 content-text lh-sm">
                      <div className="d-md-none mt-5">
                        <div className="row">
                          <div className=" col-6 ">
                            <img
                              src={Featuresicon2}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className=" col-6 ">
                            <img
                              src={Featuresicon3}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-3 col-6 col-md-3 mt-3 mt-md-0 line">
                        Loan given against mortgage of property
                      </div>
                      <div className="px-2 col-6 col-md-3 mt-3 mt-md-0 line-md">
                        Loans can be used for any purpose - Business growth,
                        Purchasing New Equipment, Working Capital Finance,
                        Expansion of business locales, Inventory purchase and
                        also to Hire more staff
                      </div>
                      <div className="d-md-none mt-5">
                        <div className="row">
                          <div className=" col-6 ">
                            <img
                              src={Featuresicon1}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className=" col-6">
                            <img
                              src={Featuresicon4}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 line mt-3 mt-md-0">
                        Simple documents submission
                      </div>
                      <div className="col-6 col-md-3 mt-3 mt-md-0">
                        Tenure and interest rate flexible and customisable
                      </div>
                      <div className="d-flex mt-3 justify-content-center">
                        <Link
                          className="text-white d-flex align-items-center justify-content-center nav-btn mt-5"
                          to="/quick"
                        >
                          QUICK APPLY
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <img
                      className="img-fluid"
                      src={ProfitMarginloanAnimation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="loan-bg d-none d-md-block mt-5">
            <div className="container-xxl container-xl container-lg container-md">
              <div className="text-center">
                <img
                  src={ProductHighlights}
                  alt=""
                  className="img-fluid my-5"
                />
              </div>
            </div>
          </div>
          <div className="loan-bg d-md-none mt-5">
            <div className="container-xxl container-xl container-lg container-md">
              <div className="text-center">
                <img
                  src={producthighlightmobile}
                  alt=""
                  className="img-fluid my-5"
                />
              </div>
            </div>
          </div>
          <Loaneligibility />
          <Docrequired />
          <Namepurpose />
          <Differentfrom />
          <section>
            <div className="loan-bg">
              <div className="container-fluid container-xxl mt-5">
                <div className="row mt-5">
                  <div className="col-sm-12 mt-sm-4 px-xl-5 ">
                    <h1 className="text-center sub-title">
                      Loans Against Properties Calculator
                    </h1>
                    <Calc />
                    <p className="mb-5" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid container-xxl ">
            <FreqAskedQuestions />
          </div>
          <Hidenavbar />
        </div>
      </>
    );
  }
}
