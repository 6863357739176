import React from "react";
import Footer from "./footer";
// import Header from "./header";
import { Link } from "react-router-dom";
import Hidenavbar from "./Hidenavbar";

export default class CRIF_TermsandUse extends React.Component {
  render() {
    return (
      <div className="about">
        {/* <Header /> */}
        <div className="container px-sm-5 px-lg-5 mt-5 ">
          <div className=" mt-5  content-text">
            <p
              className="text-center heading margin-top"
              style={{ lineHeight: "70px !important" }}
            >
              CRIF High Mark Credit Score Terms of Use
            </p>
            <p className="mt-5">
              In connection with submission of the application for my credit
              information ("Consumer Credit Information") offered by CRIF High
              Mark Credit Information Services Pvt. Ltd ("CIC") through Finplex
              Technologies Private Limited (referred to as the "Company") and
              delivery of the Consumer Credit Information to the Company, I
              hereby acknowledge and agree to the following:
            </p>
            <p className="mt-4">
              A. The Company is my lawfully appointed agent and he / it has
              agreed to be my agent obtain my Credit Information Report and
              Credit Score from CRIF High Mark Credit Information Services Pvt.
              Ltd (CRIF High Mark) for the purpose of assessing my personal or a
              business loan application for a new loan and then monitoring my
              credit information during the tenure of such loan, if approved and
              disbursed ("Purpose") and not for any other purposes. This consent
              shall be valid for a maximum period of 6 months or till such time
              the credit information is required to be retained to satisfy the
              purpose for which it was intended, or I withdraw my consent to
              store such credit information, whichever is earlier.
            </p>
            <p className="mt-4">
              B. I hereby expressly grant unconditional consent to, and direct,
              CIC to deliver and / or transfer my Consumer Credit Information to
              the Company on my behalf.
            </p>
            <p className="mt-4">
              C. I shall not hold CIC responsible or liable for any loss, claim,
              liability, or damage of any kind resulting from, arising out of,
              or in any way related to: (a) delivery of my Consumer Credit
              Information to the Company; (b) any use, modification or
              disclosure by the Company of the contents, in whole or in part, of
              my Consumer Credit Information, wherever authorized by me; (c) any
              breach of confidentiality or privacy in relation to delivery of my
              Consumer Credit Information to the Company.
            </p>
            <p className="mt-4">
              D. I acknowledge and accept that: (a) CIC has not made any
              promises or representations to me in order to induce me to provide
              my Consumer Credit Information or seek any consent or
              authorization in this regard; and (b) the implementation of the
              Agreement between CIC and the Company is solely the responsibility
              of the Company.
            </p>
            <p className="mt-4">
              E. I agree that I may be required to record my consent / provide
              instructions electronically or physically as the case may be, and
              in all such cases I understand that by clicking on the "I Accept"
              button below or signing this Consent physically, I am providing
              "written instructions" to Company authorizing Company to obtain my
              Consumer Credit Information from my personal credit profile from
              CRIF High Mark. I further authorize Company to obtain such
              information solely to confirm my identity and display my Consumer
              Credit Information to me. Further in all such cases by checking
              this box and clicking on the Authorize button or signing this
              Consent physically, I agree to the terms and conditions,
              acknowledge receipt of CIC privacy policy and agree to its terms,
              and confirm my authorization for Company to obtain my Consumer
              Credit Information.
            </p>
            <p className="mt-4">
              F. I understand that in order to deliver the product to me, I
              hereby authorize Company, to obtain my Consumer Credit Information
              from CIC.
            </p>
            <p className="mt-4">
              G. By submitting this registration form, I understand that I am
              providing express written instructions for Company to request and
              receive a copy of my consumer credit report and score from CIC.
            </p>
            <p className="mt-4">
              H. I understand that the product is provided on an "as-is", "as
              available" basis and CIC expressly disclaims all warranties,
              including the warranties of merchantability, fitness for a
              particular purpose, and non-infringement.
            </p>
            <p className="mt-4">
              I. I shall not sue or otherwise make or present any demand or
              claim, and I irrevocably, unconditionally and entirely release,
              waive and forever discharge CIC, its officers, directors,
              employees, agents, licensees, affiliates, successors and assigns,
              jointly and individually (hereinafter "Releasee"), from any and
              all manner of liabilities, claims, demands, losses, claims, suits,
              costs and expenses (including court costs and reasonable attorney
              fees) ("Losses"), whatsoever, in law or equity, whether known or
              unknown, which I ever had, now have, or in the future may have
              against the Releasee with respect to the submission of my Consumer
              Credit Information and / or my decision to provide CIC with the
              authority to deliver my Consumer Credit Information to the
              Company. I agree to defend, indemnify, and hold harmless the
              Releasee from and against any and all losses resulting from claims
              made against CIC by third parties arising from and in connection
              with this letter.
            </p>
            <p className="mt-4">
              J. I agree that the terms of this confirmation letter shall be
              governed by the laws of India and shall be subject to the
              exclusive jurisdiction of the courts located in Mumbai in regard
              to any dispute arising hereof.
            </p>
          </div>
        </div>
        <Hidenavbar />
      </div>
    );
  }
}
