import {React,useEffect,useState} from "react";
import axios from "axios";
import { baseCMSUrl } from "./constant";
import { useLocation } from 'react-router-dom';
import Storypage from "./storyPage";
// import Contact from "./contactUs";
export default function NewsStoryPage(props){
    const [eventsData, setEventsData] = useState([]);
    const [responseStatus, setResponseStatus] = useState([]);
    const searchParams = new URLSearchParams(useLocation().search);
    let relatedId = sessionStorage.getItem('newId');
    // let relatedId = 1;
    const id = searchParams.get('id');
    
    useEffect(() => {
        document.title = "finplex : Blog";
        const fetchPosts = async() => {
           await axios.get(`${baseCMSUrl}/api/bankings/${id}?populate=*`)
           .then(response => {
            setEventsData(response?.data.data);   
            setResponseStatus(response.status);
            // if(eventsData?.attributes?.title!==null){
            // }
            })
          
          };
        fetchPosts();
        
      }, [id]);
    
      useEffect(() => {
        document.title = "finplex : Blog";
        const fetchPosts = async() => {
           await axios.get(`${baseCMSUrl}/api/bankings/${relatedId}?populate=*`)
           .then(response => {
            setEventsData(response?.data.data);   
              })
          
          };
        fetchPosts();
        
      }, [relatedId]);
   
   
 
return(

    <>
     <div> 
      <Storypage Link="banking" tagsSearch='banking-tags' tags={eventsData?.attributes?.banking_tags.data} id={id} linkTo="BankingStory" tableName="bankings" response={responseStatus} videoUrl={eventsData?.attributes?.VideoId} backgroundImage={`${baseCMSUrl}` + eventsData?.attributes?.image?.data?.attributes.url} date={eventsData?.attributes?.date} view={eventsData?.attributes?.view} title={eventsData?.attributes?.title1} title2={eventsData?.attributes?.title2} title3={eventsData?.attributes?.title3} desc={eventsData?.attributes?.description1} description2={eventsData?.attributes?.description2} description3={eventsData?.attributes?.description3} relatedNews={eventsData?.attributes?.relatedNews}/>
         {/* <Contact/> */}
         </div>
    </>
)
}

// tagsSearch='news-tags' tags={eventsData?.attributes?.news_tags.data}