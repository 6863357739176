import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import {getIndustries} from '../functions/function';
import _ from "lodash";
import '../styles/formCreation.scss';
function BusinessDetails(props) {
  let [industrylist, setindustrylist] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await getIndustries().then(
        async function (response){
          var DATA = _.map(_.groupBy(response.data, 'industry'),function(obj, key){
            var temp = {};
            temp[key] = _.groupBy(obj, 'type')
            return temp;
          });
         setindustrylist(industrylist => [...industrylist, DATA]);
        }
      );
    };
    fetchData();
    console.log(props)
  }, []);
  return (
    <div>
      <Form
        onSubmit={props.sub}
        initialValues={{
          stepForm: 1,
          businessName: props.stateValue.businessName,
          businessNature: props.stateValue.businessNature,
          businessType: props.stateValue.businessType,
          businessVintage: props.stateValue.businessVintage,
          businessPan: props.stateValue.businessPan,
          addressline1: props.stateValue.addressline1,
          businessAddressType: props.stateValue.businessAddressType,
          businessOwnershipType: props.stateValue.businessOwnershipType,
          turnOver: props.stateValue.turnOver,
          cityTown: props.stateValue.cityTown,
          state: props.stateValue.state,
          pinCode: props.stateValue.pinCode 
         }}
        validate={values => {
          const errors = {};

          function validateNumberOnly(data) {
            var re = /^[0-9]*$/;
            return re.test(Number(data));
          }
          function validateNumberText(data) {
            var re = /^[a-zA-Z0-9]*$/;
            return re.test(data);
          }
          function validateTextOnly(data) {
            var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;// /^[a-zA-Z]*$/;
            return re.test(String(data));
          }

          function validateTextAndSpecial(data) {
            var re = /^[A-Za-z_@./#&+-\s]*$/;// /^[a-zA-Z]*$/;
            return re.test(String(data));
          }

          function validateAddress(data) {
            var re = /[a-zA-Z0-9,\.\_]/;
            return re.test(data);
          }

          if (!values.businessName) {
            errors.businessName = "Required";
          } else if (!validateTextAndSpecial(values.businessName)) {
            errors.businessName = "Please enter letters only";
          } else if (values.businessName.length < 3) {
            errors.businessName = 'Please enter minimum 3 characters';
          }

          if (!values.businessNature) {
            errors.businessNature = "Required";
          }

          if (!values.businessType) {
            errors.businessType = "Required";
          }

          if (!values.businessVintage) {
            errors.businessVintage = "Required";
          } else if (!validateNumberOnly(values.businessVintage)) {
            errors.businessVintage = "Please enter valid number";
          }

          if (!values.businessPan) {
            errors.businessPan = "Required";
          } else if (values.businessPan) {
            if (!validateNumberText(values.businessPan)) {
              errors.businessPan = "Please enter letters and numbers only";
            } else if (values.businessPan.length < 10) {
              errors.businessPan = "PAN should contain 10 digits";
            }
          }
          if (!values.addressline1) {
            errors.addressline1 = "Required";
          } else if (values.addressline1) {
            if (!validateAddress(values.addressline1)) {
              errors.addressline1 = "Please enter valid address";
            } else if (values.addressline1.length < 3) {
              errors.addressline1 = 'Please enter minimum 3 characters';
            }
          }

          if (!values.businessAddressType) {
            errors.businessAddressType = "Required";
          }

          if (!values.businessOwnershipType) {
            errors.businessOwnershipType = "Required";
          }

          if (!values.cityTown) {
            errors.cityTown = "Required";
          } else if (values.cityTown) {
            if (!validateTextOnly(values.cityTown)) {
              errors.cityTown = "Please enter letters only";
            } else if (values.cityTown.length < 3) {
              errors.cityTown = 'Please enter minimum 3 characters';
            }
          }

          if (!values.state) {
            errors.state = "Required";
          } else if (values.state) {
            if (!validateTextOnly(values.state)) {
              errors.state = "Please enter letters only";
            } else if (values.state.length < 3) {
              errors.state = 'Please enter minimum 3 characters';
            }
          }

          if (!values.pinCode) {
            errors.pinCode = "Required";
          } else if (values.pinCode) {
            if (!validateNumberOnly(values.pinCode)) {
              errors.pinCode = "Please enter numbers  only";
            } else if (values.pinCode.length < 6) {
              errors.pinCode = "Pincode should contain 6 numbers";
            }
          }

          if (!values.turnOver) {
            errors.turnOver = "Required";
          } else if (!validateNumberOnly(values.turnOver)) {
            errors.turnOver = "Please enter valid number";
          }


          return errors;
        }}
        render={({ handleSubmit }) => (
          <section id="contact-form" className="content-text about">
            <main className="form-container container">
              <h2 className="heading text-center margin-top">Apply Now</h2>
              <div className="row d-flex justify-content-center">
                <form onSubmit={handleSubmit} className="col-lg-9 px-3 px-xl-0 field-text">

                  <h3 className="sub-heading">Business Details</h3>
                  <div className=" row px-1 mt-4">
                    <div className="   left col-12 col-md-6">
                      <FormGroup>
                        <Label for="businessName" className="field-label">Business Name</Label>
                        <Field name="businessName">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                    <div className="fieldgroup right  col-md-6">
                      <FormGroup>
                        <Label for="businessNature" className="field-label">Business Nature</Label>
                        <Field name="businessNature" component="select">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              >
                                <option value="" disabled>--Choose--</option>
                                <option value="Manufacturer">Manufacturer</option>
                                <option value="Service">Service</option>
                                {industrylist.map((item) => {
                                  return (
                                    <>
                                      {item && Object.entries(item).map((data) => {
                                        return (
                                          <>
                                            {data[1] && Object.entries(data[1]).map((c) => {
                                              return (
                                                <option value={c[0]}>{c[0]}</option>
                                              )
                                            })}
                                          </>
                                        )
                                      })}
                                    </>
                                  )
                                })}
                              </Input>
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>

   
                      </FormGroup>
                    </div>
                  </div>
                  <div className="formgroup row px-1 mt-4">
                    <div className="fieldgroup left  col-md-6">
                      <FormGroup>
                        <Label for="businessType" className="field-label">Business Segment</Label>
                        <Field name="businessType">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              >
                                <option value="" disabled>--Choose--</option>
                                <option value="Manufacturer">Manufacturer</option>
                                <option value="Service">Service</option>
                                <option value="Trader">Trader</option>
                              </Input>
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                    <div className="fieldgroup right col-md-6">
                      <FormGroup>
                        <Label for="businessVintage" className="field-label">Business Vintage</Label>
                        <Field name="businessVintage">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="formgroup row px-1 mt-4">
                    <div className="fieldgroup left col-md-6">
                      <FormGroup>
                        <Label for="businessPan" className="field-label">Business Pan</Label>
                        <Field name="businessPan">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                maxLength="10"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                    <div className="fieldgroup right col-md-6">
                      <FormGroup>
                        <Label for="addressline1" className="field-label">Business Address</Label>
                        <Field name="addressline1">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error ">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="formgroup  row px-1 mt-4">
                    <div className="fieldgroup left col-md-6">
                      <FormGroup>
                        <Label for="businessAddressType" className="field-label">Business AddressType</Label>
                        <Field name="businessAddressType">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              >
                                <option value="" disabled>--Choose--</option>
                                <option value="Operating Address">Operating Address</option>
                                <option value="Register Office Address">Register Office Address</option>
                                <option value="Factory Address">Factory Address</option>
                                <option value="Godown Address">Godown Address</option>
                                <option value="Third Property Address">Third Property Address</option>
                                <option value="Resi cum Office Address">Resi cum Office Address</option>
                              </Input>
                              {meta.error && meta.touched && <span className="error-message error error-message">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                    <div className="fieldgroup right col-md-6">
                      <FormGroup>
                        <Label for="businessOwnershipType" className="field-label">Business OwnershipType</Label>
                        <Field name="businessOwnershipType">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="select"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              >
                                <option value="" disabled>--Choose--</option>
                                <option value="Self-Owned">Self-Owned</option>
                                <option value="Rented">Rented</option>
                                <option value="Leased">Leased</option>
                                <option value="Owned by Family">Owned by Family</option>
                                <option value="Owned by Relatives">Owned by Relatives</option>
                              </Input>
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="formgroup row px-1 mt-4">
                    <div className="fieldgroup left col-md-6">
                      <FormGroup>
                        <Label for="cityTown" className="field-label">City</Label>
                        <Field name="cityTown">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                    <div className="fieldgroup right col-md-6">
                      <FormGroup>
                        <Label for="state" className="field-label">State</Label>
                        <Field name="state">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="formgroup row px-1 mt-4">
                    <div className="fieldgroup left col-md-6">
                      <FormGroup>
                        <Label for="pinCode" className="field-label">Pincode</Label>
                        <Field name="pinCode">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                maxLength="6"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                    <div className="fieldgroup right col-md-6">
                      <FormGroup>
                        <Label for="turnOver" className="field-label">Turn Over</Label>
                        <Field name="turnOver">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="text-field w-input mb-2"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-12 marginTop flex">
                      <button className="nav-btn float-end content-text  text-white text-center" type="submit" value="Next" data-wait="Please wait...">Next</button>
                    </div>
                  <div className="copy-right-text-2 "><label className="label-8 mt-3">© 2022 Finplex. All rights reserved and registered trademarks of Finplex.in. Terms and conditions, features, support, pricing, and service options subject to change without notice.</label></div>
                </form>
              </div>
            </main>
          </section>
        )}
      />
    </div>
  )
}

export default BusinessDetails;
