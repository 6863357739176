import React, { useState, useEffect } from "react";
import '../styles/main.scss';
import Hidenavbar from "./Hidenavbar";
import Validation from "./Validation";
import Button from "../functions/button";
import { Form, Field } from 'react-final-form';

export default function GrievanceRedressal() {

  const [requestSuccess, setRequestSuccess] = useState(false);
  useEffect(() => {
    document.title = "Finplex: Grievance Redressal";
  }, []);

  const onSubmit = async (values) => {
    try {
      if ((values.name !== undefined) && (values.email !== undefined) && (values.mobilenumber !== undefined)) {
        alert("Form submitted successfully")
        window.location.reload();
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="about col-12">
        <div className="container px-3 margin-top">
          <div className="row ">
            {requestSuccess ? (<div class="succes-message">
              <div class="success-text">Request sent Successfully.</div>
            </div>) : ('')}
            <p className="heading text-center "> Grievance Redressal</p>
          </div>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-9 px-3  px-lg-5 mt-5 mt-lg-0">

              <Form
                onSubmit={onSubmit}
                initialValues={{ name: '', email: '', mobilenumber: '', message: '' }}
                validate={values => {
                  const errors = {};

                  function validateEmail(email) {
                    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(email).toLowerCase());
                  }

                  function validateTextOnly(data) {
                    var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                    return re.test(String(data));
                  }

                  function validateNumberOnly(data) {
                    var re = /^[0-9]*$/;
                    return re.test(Number(data));
                  }

                  if (!
                    values.name
                  ) {

                    errors.name
                      = "Required";
                  } else if (!validateTextOnly(
                    values.name
                  )) {

                    errors.name
                      = "Please enter letters only";
                  } else if (values.name.length < 3) {

                    errors.name
                      = 'Please enter minimum 3 characters';
                  }

                  if (!
                    values.email
                  ) {

                    errors.email
                      = "Required";
                  } else if (!validateEmail(
                    values.email
                  )) {

                    errors.email
                      = "Please enter valid email";
                  }

                  if (!values.mobilenumber) {
                    errors.mobilenumber = "Required";
                  } else if (!validateNumberOnly(values.mobilenumber)) {
                    errors.mobilenumber = "Please enter numbers  only";
                  } else if (values.mobilenumber.length < 10) {
                    errors.mobilenumber = "Phone Number should contain 10 numbers";
                  }

                  if (values.organization) {
                    if (!validateTextOnly(values.organization)) {
                      errors.organization = "Please enter letters only";
                    } else if (values.organization.length < 3) {
                      errors.organization = 'Please enter minimum 3 characters';
                    }
                  }

                  return errors;
                }}
                render={({ handleSubmit, values }) => (
                  <form className="row g-5" onSubmit={handleSubmit}>
                    <div className="col-md-6 marginTop">
                      <Field
                        name="name"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-md-6 marginTop">
                      <Field
                        name="email"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-md-6 marginTop">
                      <Field
                        name="mobilenumber"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="mobilenumber" className="form-label">
                              Phone Number
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-md-6 marginTop">
                      <Field
                        name="message"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="message" className="form-label">
                              Message  <span className="fontSize">(Optional)</span>
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>


                        )}
                      />
                    </div>
                    <div className="col-12 marginTop flex">
                      <Button
                        type="submit"
                        label="SUBMIT"
                        className="nav-btn float-end content-text mt-3 text-white text-center"
                        handleClick={onSubmit}
                      />
                    </div>


                  </form>
                )}
              ></Form>

            </div>
          </div>
        </div>
        <Hidenavbar />
        <Validation />
      </div>
    </>
  );
}

