import React from "react";
import bgcardicon from "../assets/bg-cardicon.svg";
import Group1361 from "../assets/Group 1361.svg";
import newEquipment from "../assets/newEquipment.svg";
import capitalFinance from "../assets/capitalFinance.svg";
import locationadd from "../assets/location-add.svg";
import category from "../assets/category.svg";
import profile2user from "../assets/profile-2user.svg";

export default class namepurpose extends React.Component {
  render() {
    return (
      <>
        <section>
          <div className="container-xxl container-xl container-lg container-md">
            <div className="row d-flex justify-content-center mt-lg-5">
              <div className="col-10 col-md-9 col-xl-7">
                <p className="sub-title text-center">
                Get funds for your Purpose
                </p>
              </div>
            </div>
            <div className="row px-4">
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={Group1361}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Business Growth</p>
                  <p className="cardText lh-sm">
                    In the path along the way of business there are bound to be
                    decisions involving finance but most times when the
                    entrepreneur just needs to make the milestone decision on
                    that dream move of expansion, finance may be a roadblock. We
                    clear these and offer a smooth drive to success with our
                    loans for expanding business.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={newEquipment}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Purchasing New Equipment</p>
                  <p className="cardText lh-sm">
                    In any business scalability is always possible on a large
                    measure only with the accurate equipment and we have just
                    the precise loan that makes purchasing equipment a breeze.
                    The cost can be spread across the years with the perfect
                    match of loan and interest. We make that decision possible
                    for you.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={capitalFinance}
                      alt=""
                      className="card-icon img-fluid mt-2 "
                    />
                  </div>
                  <p className="mt-4 cardTitle">Working Capital Finance</p>
                  <p className="cardText lh-sm">
                    Sometimes Businesses may have used up finance for other
                    aspects and may run short on working capital. This could be
                    stifling. We offer Working Capital loans to ease your daily
                    functioning.
                  </p>
                </div>
              </div>
              {/* </div>
            <div class="row mt-5">
             */}
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={locationadd}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">
                    Go Global
                  </p>
                  <p className="cardText lh-sm">
                    To be on the global map in your business, being close to all
                    those who need you across the country or globe requires
                    business acumen, for every opportunity that presents itself
                    the business may need to open branches within the country
                    and abroad to embrace a larger multinational clientele. The
                    requirement of finance for this is also available with us.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={category}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Inventory Purchase</p>
                  <p className="cardText lh-sm">
                    Accuracy in Inventory purchase is of utmost importance in
                    any business. Inventory offers both the confidence of
                    meeting sudden surges of high demand and the cushioning
                    collateral needed for any business emergencies. We offer
                    loans for inventory purchase.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card mt-5 p-4" id="loan-Card">
                  <div className>
                    <img
                      src={bgcardicon}
                      alt=""
                      className="position-relative"
                    />
                    <img
                      src={profile2user}
                      alt=""
                      className="card-icon img-fluid"
                    />
                  </div>
                  <p className="mt-4 cardTitle">Hire More Staff</p>
                  <p className="cardText lh-sm">
                    All round business growth needs fully trained staff that
                    help in reaching the pinnacles of success. Hiring them costs
                    money and we just offer you the right credit for it.
                  </p>
                </div>
              </div>
              <p className="mb-5" />
            </div>
          </div>
        </section>
      </>
    );
  }
}
