import { useEffect } from "react";
import React from "react";
import Header from "./header";
import Button from "../functions/button";
import { Form, Field } from 'react-final-form';
import { useHistory } from "react-router-dom";
import { saveQuickApply} from '../functions/function';
import { accessToken } from "../functions/awaAuth";


function QuickApply() {
  let history = useHistory();
  useEffect(() => {
    document.title = "Finplex: Quick Apply";
    accessToken();
    }, []);

  const onSubmit = async (values) => {
    try {
      await saveQuickApply(values);
      history.push({
        pathname: '/quickApplyOtp',
        state: { detail: values }
      });
    }

    catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {/* <div w3-include-html="./header.html"></div> */}
      <Header />
      <div className="container-fluid margin-top about">
        <div className="row justify-content-center px-xxl">
          <div className="px-3 px-xl-0 col-lg-9">
            <div className="text-center">
              <p className="heading">Quick Apply</p>
              <p className="content-text text-center">Let's fill up your personal details</p>
            </div>
            <Form
              onSubmit={onSubmit}
              initialValues={{ panNumber: '' , date :'dd-mm-yyyy', fatherName : '' , spouseName : '', address : '' , cityTown : '' , state : '' , pinCode : ''}}
              validate={values => {
                const errors = {};

                function validateEmail(email) {
                  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return re.test(String(email).toLowerCase());
                }

                function validateTextOnly(data) {
                  var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                  return re.test(String(data));
                }

                function validateNumberOnly(data) {
                  var re = /^[0-9]*$/;
                  return re.test(Number(data));
                }
                function validateAddress(data) {
                  var re = /[a-zA-Z0-9,\.\_]/;
                  return re.test(data);
                }

                function validateNumberLoan(data) {
                  var re = /^[0-9]*$/;
                  return re.test(Number(data));
                }

                function validateNumberText(data) {
                  var re = /^[a-zA-Z0-9]*$/;
                  return re.test(data);
                }

                if (!values.firstname) {
                  errors.firstname = "Required";
                } else if (!validateTextOnly(values.firstname)) {
                  errors.firstname = "Please enter letters only";
                } else if (values.firstname.length < 3) {
                  errors.firstname = 'Please enter minimum 3 characters';
                }


                if (!values.lastname) {
                  errors.lastname = "Required";
                } else if (!validateTextOnly(values.lastname)) {
                  errors.lastname = "Please enter letters only";
                } else if (values.lastname.length < 3) {
                  errors.lastname = 'Please enter minimum 3 characters';
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (!validateEmail(values.email)) {
                  errors.email = "Please enter valid email";
                }

                if (!values.mobileNumber) {
                  errors.mobileNumber = "Required";
                } else if (!validateNumberOnly(values.mobileNumber)) {
                  errors.mobileNumber = "Please enter numbers  only";
                } else if (values.mobileNumber.length < 10) {
                  errors.mobileNumber = "Phone Number should contain 10 numbers";
                }

                if (!values.loanAmount) {
                  errors.loanAmount = "Required";
                } else if (!validateNumberOnly(values.loanAmount)) {
                  errors.loanAmount = "Please enter numbers  only";
                } else if (!validateNumberLoan(values.loanAmount)) {
                  errors.loanAmount = "Please enter valid amount";
                } else if (values.loanAmount < 300000) {
                  errors.loanAmount = "Loan Amount should be greater than are equal to 3 Lakhs"
                } else if (values.loanAmount > 4000000) {
                  errors.loanAmount = "Loan Amount should be less than are equal to 40 Lakhs"
                }

                if (!values.dateOfBirth) {
                  errors.dateOfBirth = "Required";
                }

                if (values.panNumber) {
                  if (!validateNumberText(values.panNumber)) {
                    errors.panNumber = "Please enter letters and numbers only";
                  } else if (values.panNumber.length < 10) {
                    errors.panNumber = "PAN should contain 10 digits";
                  }
                  else if (values.panNumber.length > 10) {
                    errors.panNumber = "PAN should contain 10 digits";
                  }
                }

                if (values.fatherName) {
                  if (!validateTextOnly(values.fatherName)) {
                    errors.fatherName = "Please enter letters only";
                  } else if (values.fatherName.length < 3) {
                    errors.fatherName = 'Please enter minimum 3 characters';
                  }
                }

                if (values.spouseName) {
                  if (!validateTextOnly(values.spouseName)) {
                    errors.spouseName = "Please enter letters only";
                  } else if (values.spouseName.length < 3) {
                    errors.spouseName = 'Please enter minimum 3 characters';
                  }
                }

                if (values.address) {
                  if (!validateAddress(values.address)) {
                    errors.address = "Please enter valid address";
                  }
                }

                if (values.cityTown) {
                  if (!validateTextOnly(values.cityTown)) {
                    errors.cityTown = "Please enter letters only";
                  } else if (values.cityTown.length < 3) {
                    errors.cityTown = 'Please enter minimum 3 characters';
                  }
                }

                if (values.state) {
                  if (!validateTextOnly(values.state)) {
                    errors.state = "Please enter letters only";
                  } else if (values.state.length < 3) {
                    errors.state = 'Please enter minimum 3 characters';
                  }
                }

                if (values.pinCode) {
                  if (!validateNumberOnly(values.pinCode)) {
                    errors.pinCode = "Please enter numbers  only";
                  } else if (values.pinCode.length < 6) {
                    errors.pinCode = "Pincode should contain 6 numbers";
                  } else if (values.pinCode.length > 6) {
                    errors.pinCode = "Pincode should contain 6 numbers";
                  }
                }

                return errors;
              }}
              render={({ handleSubmit, values }) => (
                <form className="row g-3 field-text" onSubmit={handleSubmit}>
                  <div className="col-md-6 mt-5 form">
                    <Field
                      name="firstname"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="firstname" className="form-label ">
                            First Name
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 mt-5">
                    <Field
                      name="lastname"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="lastname" className="form-label ">
                            Last Name
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>

                  <div className="col-md-6 marginTop">
                    <Field
                      name="email"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="email" className="form-label ">
                            Email
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="mobileNumber"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="mobileNumber" className="form-label ">
                            Mobile Number <span className="fontSize">(OTP will be sent to this mobile number)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="loanAmount"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="loanAmount" className="form-label ">
                            Loan Amount
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control empty"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <label for="dateOfBirth" className="form-label ">Date of Birth</label>
                    <Field name="dateOfBirth"
                      render={({ input, meta }) => (

                        <div>
                          <input
                            {...input}
                            type="date" 
                            max="9999-12-31"
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : values.dateOfBirth !== undefined? "form-control":"form-control defaultDateFormat"
                            }
                            invalid={meta.error && meta.touched}
                            />
                          {meta.error && meta.touched && <span className="invalid-feedback">{meta.error}</span>}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="panNumber"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="panNumber" className="form-label ">
                            PAN  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="fatherName"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="fatherName" className="form-label ">
                            Father Name  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="spouseName"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="spouseName" className="form-label ">
                            Spouse Name  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="address"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="address" className="form-label ">
                            Address  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>


                      )}
                    />
                  </div>


                  <div className="col-md-6 marginTop">
                    <Field
                      name="cityTown"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="cityTown" className="form-label ">
                            City  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="state"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="state" className="form-label ">
                            State  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="pinCode"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="pinCode" className="form-label ">
                            Pincode  <span className=" fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">{meta.error}</span>
                          )}
                        </div>

                      )}
                    />
                  </div>

                  <div className="col-12 marginTop flex">
                    <Button
                      type="submit"
                      label="Proceed"
                      className="nav-btn float-end content-text mt-3 text-white text-center"
                      handleClick={onSubmit}
                    />
                  </div>
                </form>
              )}
            ></Form>
            <p className="mt-5">© 2022 Finplex. All rights reserved and registered trademarks of Finplex.in. Terms and conditions,
              features, support, pricing, and service options subject to change without notice.</p>
          </div>
        </div>
        
      </div>
    </>
  )
}


export default QuickApply;
