import {React,useEffect,useState} from "react";
import axios from "axios";
import { baseCMSUrl } from "./constant";
import { useLocation } from 'react-router-dom';
import Storypage from "./storyPage";
// import Storypage from "./storyPage";
// import Contact from "./contactUs";
export default function CaseStudyStoryPage(props){
    const [caseStudyData, setCaseStudyData] = useState([]);
    const [loading, setLoading] = useState([]);
    const searchParams = new URLSearchParams(useLocation().search);
    const id = searchParams.get('id');
    let relatedId=sessionStorage.getItem('newId');
    // let relatedId = 1;
    useEffect(() => {
        document.title = "finplex-blog";
    
        const fetchPosts = async() => {
           await axios.get(`${baseCMSUrl}/api/trendings/${id}?populate=*`)
           .then(response => {
            setCaseStudyData(response?.data.data);  
            setLoading(response.status); 
            })         
        };
        fetchPosts();
      }, [id]);
      useEffect(() => {
        document.title = "finplex-blog";
    
        const fetchPosts = async() => {
           await axios.get(`${baseCMSUrl}/api/trendings/${relatedId}?populate=*`)
           .then(response => {
            setCaseStudyData(response?.data.data);   
            })         
        };
        fetchPosts();
      }, [relatedId]);
   
  

return(

    <>
      <div> 
      <Storypage id={id} Link="trending" tagsSearch='trending-tags' tags={caseStudyData?.attributes?.trending_tags.data} backgroundImage={`${baseCMSUrl}` + caseStudyData?.attributes?.image?.data?.attributes.url} linkTo="TrendingStory" tableName="trendings" response={loading} date={caseStudyData?.attributes?.date} view={caseStudyData?.attributes?.view} title={caseStudyData?.attributes?.title} title2={caseStudyData?.attributes?.title2} title3={caseStudyData?.attributes?.title3}  desc={caseStudyData?.attributes?.description}  description1={caseStudyData?.attributes?.description1} description2={caseStudyData?.attributes?.description2} description3={caseStudyData?.attributes?.description3} description4={caseStudyData?.attributes?.description4} subtitle1={caseStudyData?.attributes?.subtitle1} subtitle2={caseStudyData?.attributes?.subtitle2} subtitle3={caseStudyData?.attributes?.subtitle3} subtitle4={caseStudyData?.attributes?.subtitle4} border="false" videoUrl={caseStudyData?.attributes?.VideoId} relatedNews={caseStudyData?.attributes?.relatedNews}/>
      {/* <Contact/> */}
      </div>

    </> 
)
}

// tagsSearch='insight-tags'
// tags={caseStudyData?.attributes?.insight_tags.data}