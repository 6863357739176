import React, { useState, useEffect } from "react";
import "../styles/main.scss";
import contact_img from "../assets/contact_img.svg";
import call from "../assets/call_Icon.svg";
import chat from "../assets/chat_icon.png";
import mail from "../assets/mail_icon.svg";
import Hidenavbar from "./Hidenavbar";
import Validation from "./Validation";
import Button from "../functions/button";
import { Form, Field } from "react-final-form"; /* 
import { saveContactUs } from "../functions/function" */

export default function CustomerSupport() {
  useEffect(() => {
    document.title = "Finplex: Customer Support";
  }, []);

  const [requestSuccess, setRequestSuccess] = useState(false);

  const onSubmit = async (values) => {
    try {
      /*    const result = await saveContactUs(values);
      if (result.data.status === "Mail Sent") {
        setRequestSuccess(true)
        setTimeout(() => {
          setRequestSuccess(false);
          window.location.reload();
        }, 4000);
      }
    */
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="about">
        <div className="container px-3 px-lg-5 margin-top">
          <div className="row ">
            {requestSuccess ? (
              <div className="succes-message">
                <div className="success-text">Request sent Successfully.</div>
              </div>
            ) : (
              ""
            )}
            <p className="heading text-center ">Customer Support</p>
          </div>
          <div className="row mt-5 px-2 px-md-4">
            <div className="col-1"></div>
            <div className="col-lg-4">
              <div className="row">
                <img src={contact_img} className="img-fluid" />
                {/*                */}
              </div>
            </div>
            <div
              className="col-lg-6 col-xl-5 px-3  px-lg-5 mt-5 mt-lg-0"
              id="sub-heading"
            >
              <p className="sub-heading mt-4  center-sm">
                Get In Touch With Us
              </p>
              <p className="content-text center-sm content-text1 text-lg-center lh-sm ">
                Reach out to us &amp; we will respond as soon as we can.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center px-lg-5 mt-0 mt-lg-3">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                name: "",
                email: "",
                mobilenumber: "",
                organization: "",
                message: "",
              }}
              validate={(values) => {
                const errors = {};

                function validateEmail(email) {
                  var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return re.test(String(email).toLowerCase());
                }

                function validateTextOnly(data) {
                  var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                  return re.test(String(data));
                }

                function validateNumberOnly(data) {
                  var re = /^[0-9]*$/;
                  return re.test(Number(data));
                }

                if (!values.name) {
                  errors.name = "Required";
                } else if (!validateTextOnly(values.name)) {
                  errors.name = "Please enter letters only";
                } else if (values.name.length < 3) {
                  errors.name = "Please enter minimum 3 characters";
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (!validateEmail(values.email)) {
                  errors.email = "Please enter valid email";
                }

                if (!values.mobilenumber) {
                  errors.mobilenumber = "Required";
                } else if (!validateNumberOnly(values.mobilenumber)) {
                  errors.mobilenumber = "Please enter numbers  only";
                } else if (values.mobilenumber.length < 10) {
                  errors.mobilenumber =
                    "Phone Number should contain 10 numbers";
                }

                if (values.organization) {
                  if (!validateTextOnly(values.organization)) {
                    errors.organization = "Please enter letters only";
                  } else if (values.organization.length < 3) {
                    errors.organization = "Please enter minimum 3 characters";
                  }
                }

                return errors;
              }}
              render={({ handleSubmit, values }) => (
                <form className="row g-md-5 field-text" onSubmit={handleSubmit}>
                  <div className="col-md-6 mt-3 mt-md-2">
                    <Field
                      name="name"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="name" className="form-label">
                            Name
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 mt-4 mt-md-1">
                    <Field
                      name="email"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="mobilenumber"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="mobilenumber" className="form-label">
                            Phone Number
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="message"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="message" className="form-label">
                            Message <span className="fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-md-6 marginTop">
                    <Field
                      name="organization"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="organization" className="form-label">
                            Organisation{" "}
                            <span className="fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="col-12 marginTop flex">
                    <Button
                      type="submit"
                      label="SUBMIT"
                      className="nav-btn float-end content-text mt-3 text-white text-center"
                      handleClick={onSubmit}
                    />
                  </div>
                </form>
              )}
            ></Form>
            <p className=" btn-center mt-3">
              Finplex's vision is to reshape the Indian financial landscape
              using innovative technology and advanced data analytics. Our
              direct credit access empowers small businesses, fueling job
              creation and economic growth. Join us on this transformative
              journey as we pave the way for the future of financial services.
              Contact us at{" "}
              <a
                className="link-color"
                href="mailto:help@finplex.in
                  "
              >
                help@finplex.in
              </a>{" "}
              to be a part of the revolution.
            </p>
          </div>
        </div>
        <p className="mb-5"></p>
      </div>
      <div className="loan-bg about">
        <div className="container px-5">
          <div className="row d-flex justify-content-center px-lg-5">
            <p className="heading text-center mt-5">Have Any Questions?</p>
            <div className="col-md-5 mt-5 mt-md-4">
              <div className="row text-center text-md-start g-md-0">
                <div className="col-md-5 px-md-3">
                  <img src={call} className="img-fluid" />
                </div>
                <div className="col-md-7  mt-md-1 mt-lg-2 mt-xl-3">
                  <p className="mt-2 mb-0 content-text1">
                    <strong>Call Us</strong>
                  </p>
                  <a
                    className="text-decoration-none color content-text2"
                    href="tel:18002701233"
                  >
                    1800-2701-233
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-5 mt-md-4">
              <div className="row text-center text-md-start g-md-0">
                <div className="col-md-5 px-md-3">
                  <img src={mail} className="img-fluid" />
                </div>
                <div className="col-md-7 mt-md-1 mt-lg-2 mt-xl-3">
                  <p className="mt-2 mb-0 content-text1">
                    <strong>Mail Us</strong>
                  </p>
                  <a
                    href="mailto:help@finplex.in"
                    className="text-decoration-none color content-text2"
                  >
                    Help@Finplex.in
                  </a>
                </div>
              </div>
            </div>
            <p className="mb-5"></p>
          </div>
        </div>
      </div>
      <div className="container-fluid container-xxl about">
        <div className="row mt-2">
          <div className="col-12">
            <p className="heading text-center mt-5">Locations We Serve</p>
            <div
              className="card mt-5 tab-card box-border mx-lg-5  pb-0 mb-0"
              id="CustomerSupport"
            >
              <div className="card-header tab-card-header px-xl-5">
                <ul
                  className="nav nav-tabs card-header-tabs d-flex justify-content-center tab-responsive"
                  id="myTab"
                  role="tablist px-xl-5"
                >
                  <li className="nav-item px-xl-3">
                    <a
                      className="nav-link active"
                      id="one-tab"
                      data-bs-toggle="tab"
                      href="#south"
                      role="tab"
                      aria-controls="One"
                      aria-selected="true"
                    >
                      South Zone
                    </a>
                  </li>
                  <li className="nav-item px-xl-3">
                    <a
                      className="nav-link"
                      id="two-tab"
                      data-bs-toggle="tab"
                      href="#west"
                      role="tab"
                      aria-controls="Two"
                      aria-selected="false"
                    >
                      West Zone
                    </a>
                  </li>
                  <li className="nav-item px-xl-4">
                    <a
                      className="nav-link"
                      id="three-tab"
                      data-bs-toggle="tab"
                      href="#north"
                      role="tab"
                      aria-controls="Three"
                      aria-selected="false"
                    >
                      North Zone
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active px-3"
                  id="south"
                  role="tabpanel"
                  aria-labelledby="one-tab"
                >
                  <div className="row">
                    <div className="col-md-3 px-0 rightBorder">
                      <div
                        className="nav flex-column nav-pills text-center"
                        id="States"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className="nav-link active"
                          id="TamilNadu"
                          data-bs-toggle="pill"
                          href="#v-pills-link1"
                          role="tab"
                          aria-controls="v-pills-link1"
                          aria-selected="true"
                        >
                          Tamil Nadu
                        </a>
                        <a
                          className="nav-link"
                          id="Karnataka"
                          data-bs-toggle="pill"
                          href="#v-pills-link2"
                          role="tab"
                          aria-controls="v-pills-link2"
                          aria-selected="false"
                        >
                          Karnataka
                        </a>
                        <a
                          className="nav-link"
                          id="Telangana"
                          data-bs-toggle="pill"
                          href="#v-pills-link3"
                          role="tab"
                          aria-controls="v-pills-link3"
                          aria-selected="false"
                        >
                          Telangana
                        </a>
                      </div>
                    </div>

                    <div className="col-md-9 px-lg-5">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-link1"
                          role="tabpanel"
                          aria-labelledby="v-pills-link1-tab"
                        >
                          <div className="row d-flex justify-content-center justify-content-md-start">
                            <div className="col-sm-10 col-xl-9">
                              <ul
                                class="nav  nav-pills tab-responsive mt-3"
                                id="cities"
                              >
                                <li class="nav-item">
                                  <a
                                    class="nav-link active me-md-3 ps-1"
                                    data-bs-toggle="pill" /* href="#chennai" */
                                  >
                                    Chennai
                                  </a>
                                </li>
                                {/*   <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Coimbatore</a>
                                </li>
                                <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Madurai</a>
                                </li>
                               */}
                              </ul>

                              <div class="tab-content text-center text-md-start">
                                <div class="px-1 active" id="chennai">
                                  {" "}
                                  <p className="mt-3">
                                    Finplex Technologies Private Limited.
                                    <br /> Building: The Office Express
                                    No.32/33, Third Floor,
                                    <br /> Railway Border Road, Kodambakkam,
                                    Chennai - 600 024, Tamilnadu.
                                  </p>
                                </div>
                                {/*   <div class="tab-pane container fade" id="menu1">...</div>
                                <div class="tab-pane container fade" id="menu2">...</div>
                               */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="v-pills-link2"
                          role="tabpanel"
                          aria-labelledby="v-pills-link2"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-link2"
                            role="tabpanel"
                            aria-labelledby="v-pills-link2-tab"
                          >
                            <div className="row d-flex justify-content-center justify-content-md-start">
                              <div className="col-sm-10 col-xl-9">
                                <ul
                                  class="nav  nav-pills tab-responsive mt-3"
                                  id="cities"
                                >
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active me-md-3 ps-1"
                                      data-bs-toggle="pill" /* href="#Bangalore" */
                                    >
                                      Bangalore
                                    </a>
                                  </li>
                                  {/*   <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Coimbatore</a>
                                </li>
                                <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Madurai</a>
                                </li>
                               */}
                                </ul>

                                <div class="tab-content text-center text-md-start">
                                  <div class="px-1 active" id="Bangalore">
                                    {" "}
                                    <p className="mt-3">
                                      Finplex Technologies Private Limited.
                                      <br /> 59, 1st floor, 10th A cross,
                                      <br /> 2nd stage Mahalakshmipuram West of
                                      Cord Road,
                                      <br /> Next to Rajajinagar Metro Station,
                                      <br /> Bangalore, Karnataka - 560086{" "}
                                    </p>
                                  </div>
                                  {/*   <div class="tab-pane container fade" id="menu1">...</div>
                                        <div class="tab-pane container fade" id="menu2">...</div>
                                      */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="v-pills-link3"
                          role="tabpanel"
                          aria-labelledby="v-pills-link3-tab"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-link2"
                            role="tabpanel"
                            aria-labelledby="v-pills-link2-tab"
                          >
                            <div className="row d-flex justify-content-center justify-content-md-start">
                              <div className="col-sm-10 col-xl-9">
                                <ul
                                  class="nav  nav-pills tab-responsive mt-3"
                                  id="cities"
                                >
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active me-md-3 ps-1"
                                      data-bs-toggle="pill" /* href="#Hyderabad" */
                                    >
                                      Hyderabad
                                    </a>
                                  </li>
                                  {/*   <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Coimbatore</a>
                                </li>
                                <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Madurai</a>
                                </li>
                               */}
                                </ul>

                                <div class="tab-content text-center text-md-start">
                                  <div class="px-1 active" id="Hyderabad">
                                    {" "}
                                    <p className="mt-3">
                                      Finplex Technologies Private Limited.
                                      <br /> Hatch Station, 19/3RT LANE 2,
                                      Street No - 5, Prakesh Nagar, Begumpet,
                                      Hyderabad, Telangana - 500016
                                    </p>
                                  </div>
                                  {/*   <div class="tab-pane container fade" id="menu1">...</div>
                                        <div class="tab-pane container fade" id="menu2">...</div>
                                      */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade px-3"
                  id="west"
                  role="tabpanel"
                  aria-labelledby="two-tab"
                >
                  <div className="row">
                    <div className="col-md-3 px-0 rightBorder">
                      <div
                        className="nav flex-column nav-pills text-center"
                        id="States"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className="nav-link active"
                          id="MadhyaPradesh"
                          data-bs-toggle="pill"
                          href="#v-pills-link1"
                          role="tab"
                          aria-controls="v-pills-link1"
                          aria-selected="true"
                        >
                          Madhya Pradesh
                        </a>
                      </div>
                    </div>

                    <div className="col-md-9 px-lg-5">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-link1"
                          role="tabpanel"
                          aria-labelledby="v-pills-link1-tab"
                        >
                          <div className="row d-flex justify-content-center justify-content-md-start">
                            <div className="col-sm-10 col-xl-9">
                              <ul
                                class="nav  nav-pills tab-responsive mt-3"
                                id="cities"
                              >
                                <li class="nav-item">
                                  <a
                                    class="nav-link active me-md-3 ps-1"
                                    data-bs-toggle="pill"
                                  >
                                    Indore
                                  </a>
                                </li>
                                {/*   <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Coimbatore</a>
                                </li>
                                <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Madurai</a>
                                </li>
                               */}
                              </ul>

                              <div class="tab-content text-center text-md-start">
                                <div class="px-1 active" id="Indore">
                                  {" "}
                                  <p className="mt-3">
                                    Finplex Technologies Private Limited.
                                    <br />
                                    Office No: 220, Ratnamani Complex, 7/1, New
                                    Palasiya, New Janjeerwala Square, Indore -
                                    452001
                                  </p>
                                </div>
                                {/*   <div class="tab-pane container fade" id="menu1">...</div>
                                <div class="tab-pane container fade" id="menu2">...</div>
                               */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade px-3"
                  id="north"
                  role="tabpanel"
                  aria-labelledby="three-tab"
                >
                  <div className="row">
                    <div className="col-md-3 px-0 rightBorder">
                      <div
                        className="nav flex-column nav-pills text-center"
                        id="States"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className="nav-link active"
                          id="Delhi"
                          data-bs-toggle="pill"
                          href="#v-pills-link1"
                          role="tab"
                          aria-controls="v-pills-link1"
                          aria-selected="true"
                        >
                          New Delhi
                        </a>
                        {/* <a className="nav-link" id="Karnataka" data-bs-toggle="pill" href="#v-pills-link2" role="tab" aria-controls="v-pills-link2" aria-selected="false">Karnataka</a>
                        <a className="nav-link" id="Telangana" data-bs-toggle="pill" href="#v-pills-link3" role="tab" aria-controls="v-pills-link3" aria-selected="false">Telangana</a> */}
                      </div>
                    </div>

                    <div className="col-md-9 px-lg-5">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-link1"
                          role="tabpanel"
                          aria-labelledby="v-pills-link1-tab"
                        >
                          <div className="row d-flex justify-content-center justify-content-md-start">
                            <div className="col-sm-10 col-xl-9">
                              <ul
                                class="nav  nav-pills tab-responsive mt-3"
                                id="cities"
                              >
                                <li class="nav-item">
                                  <a
                                    class="nav-link active me-md-3 ps-1"
                                    data-bs-toggle="pill"
                                  >
                                    New Delhi
                                  </a>
                                </li>
                                {/*   <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Coimbatore</a>
                                </li>
                                <li class="nav-item me-md-3">
                                  <a class="nav-link" data-bs-toggle="pill" href="#chennai">Madurai</a>
                                </li>
                               */}
                              </ul>

                              <div class="tab-content text-center text-md-start">
                                <div class="px-1 active" id="Delhi">
                                  {" "}
                                  <p className="mt-3">
                                    Finplex Technologies Private Limited.
                                    <br /> Ground Floor, K-12, Green Park
                                    Extension,
                                    <br /> New Delhi - 110016.
                                  </p>
                                </div>
                                {/*   <div class="tab-pane container fade" id="menu1">...</div>
                                <div class="tab-pane container fade" id="menu2">...</div>
                               */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Hidenavbar />
        <Validation />
      </div>
    </>
  );
}
