import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '../functions/button';

class ThankYou extends Component {
    constructor(props) {
        super(props);
        this.state = {
          consentPageThankyou : false,
          }
    }
    componentDidMount(){
      document.title ="Finplex: Quick Apply";
      if(window.history.state && window.history.state.state.data) {
        if( window.history.state.state.data === 'consent') {
          this.setState({
            consentPageThankyou : true
          })
        }
      }
      if (!window.history.state) {
        this.props.history.push("/home");
      }
      }
    loanProcess () {
      if (window.history.state && window.history.state.state.detail) {
          this.props.history.push({
            pathname: '/loan-process',
            state: { detail: window.history.state.state.detail }
          });
      } else {
        console.log("Error");
      }
    }
    render() { 
        return ( 
          <div className=' text-center margin-top about' >
            <div className="thankyou-section wf-section ">
              <div className={this.state.consentPageThankyou ? "thankyou-container consent w-container" : "thankyou-container w-container"}>
                <h1 className="sub-heading font-colors">Thank you!</h1>
                <div className="subtitle-1">
                  <div className="subtitle-logo"><img src="https://uploads-ssl.webflow.com/610b7098ce12c75c02c64425/610cd07fd538077f35f5a7e0_Vector%2058.svg" loading="lazy" width={22} alt="" className="image-34" /></div>
                  <div className="subtitle-text">
                  {this.state.consentPageThankyou ?
                     (<div className="thankyou-text-1">Many thanks for your consent. We are now processing your application and will be in touch with you very shortly.</div>) 
                     : 
                     (<div className="m-4">Your application was successfully submitted for Quick Loan</div>)}
                    
                  </div>
                </div>
                {/* <div className="subtitle-2">
                    <div className="subtitle-logo"><img src="https://uploads-ssl.webflow.com/610b7098ce12c75c02c64425/610cd07fd538077f35f5a7e0_Vector%2058.svg" loading="lazy" width={22} alt="" className="image-34" /></div>
                    <div className="subtitle-text">
                      <div className="thankyou-text">Your WhatsApp number is successfully linked to get loan updates</div>
                    </div>
                  </div>
                */}
                <div className="home-btn-div">
                  <Link to="/" id="go-home-link" class="font-colors mt-5" >GO TO HOME</Link>
                </div>
              </div>
            </div>
            
            { !this.state.consentPageThankyou ?
               (
                <div className='more-loan-process'>
                <div className=" py-3 mt-3 row  justify-content-center w-100">
                <div className="col-12 col-md-12">To expedite loan process, please proceed to provide more information and upload required documents</div>
              <div className="col-12 col-md-12 text-center">
               
               <a href='#' className='text-decoration-none ' onClick={()=>this.loanProcess()}>  <button className="font-colors mt-3 primary-button text-white" >PROCEED</button></a> 
                </div>
              </div>
              </div>
               ) 
               : 
               ('')}
            
          </div>
         );
    }
}
 
export default ThankYou;