import React , {useState} from "react";
import '../styles/main.scss';
import contact_img from "../assets/contact_img.svg";
import Hidenavbar from "./Hidenavbar";
import Validation from "./Validation";
import Button from "../functions/button";
import { Form, Field } from 'react-final-form';
import { saveContactUs } from "../functions/function"

export default function Contactus() {

  const [requestSuccess, setRequestSuccess] = useState(false);

  const onSubmit = async (values) => {
    try {
      const result = await saveContactUs(values);
      if( result.data.status === "Mail Sent") {
        setRequestSuccess (true)
        setTimeout(() => {
          setRequestSuccess(false);
          window.location.reload();
        },4000);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <>
    <div className="about">
        <div className="container px-3 px-lg-5 margin-top">
          <div className="row ">
          { requestSuccess ? (<div class="succes-message">
            <div class="success-text">Request sent Successfully.</div>
              </div>) :('')}
            <p className="heading text-center ">Contact Us</p>
          </div>
          <div className="row mt-5 px-md-4">
            <div className="col-lg-6">
              <div className="row">
                <img src={contact_img} className="img-fluid mt-lg-5" />
                <p className="content-text btn-center mt-3">Finplex aims to transform the way the Indian financial services market operates by offering a disruptive solution leveraging technology and next generation data analytics. Access to credit directly helps our small-business clients grow, create jobs and supercharge the Indian economy. Email to us at <a className="link-color" 
                  href="mailto:help@finplex.in
                  "> 
                  help@finplex.in
                  </a></p>
              </div>
            </div>
            <div className="col-lg-6 px-3  px-lg-5 mt-5 mt-lg-0 leftBorder">
              <p className="sub-heading mt-1  center-sm">Get In Touch With Us</p>
              <p className="content-text lh-sm  center-sm">Reach out to us &amp; we will respond as soon as we can.</p>
              <Form
                onSubmit={onSubmit}
                initialValues={{ name: '', email: '', mobilenumber: '', organization: '', message: '' }}
                validate={values => {
                  const errors = {};

                  function validateEmail(email) {
                    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(email).toLowerCase());
                  }

                  function validateTextOnly(data) {
                    var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                    return re.test(String(data));
                  }

                  function validateNumberOnly(data) {
                    var re = /^[0-9]*$/;
                    return re.test(Number(data));
                  }

                  if (!
                      values.name
                      ) {
                                          
                      errors.name
                      = "Required";
                                        } else if (!validateTextOnly(
                      values.name
                      )) {
                                          
                      errors.name
                      = "Please enter letters only";
                                        } else if (values.name.length < 3) {
                                          
                      errors.name
                      = 'Please enter minimum 3 characters';
                                        }

                                        if (!
                      values.email
                      ) {
                                          
                      errors.email
                      = "Required";
                                        } else if (!validateEmail(
                      values.email
                      )) {
                                          
                      errors.email
                      = "Please enter valid email";
                                        }

                  if (!values.mobilenumber) {
                    errors.mobilenumber = "Required";
                  } else if (!validateNumberOnly(values.mobilenumber)) {
                    errors.mobilenumber = "Please enter numbers  only";
                  } else if (values.mobilenumber.length < 10) {
                    errors.mobilenumber = "Phone Number should contain 10 numbers";
                  }

                  if (values.organization) {
                    if (!validateTextOnly(values.organization)) {
                      errors.organization = "Please enter letters only";
                    } else if (values.organization.length < 3) {
                      errors.organization = 'Please enter minimum 3 characters';
                    }
                  }

                  return errors;
                }}
                render={({ handleSubmit, values }) => (
                  <form className="row g-3 field-text" onSubmit={handleSubmit}>
                    <div className="col-12 mt-5">
                      <Field
                        name="name"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="email"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="mobilenumber"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="mobilenumber" className="form-label">
                              Phone Number
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="organization"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="organization" className="form-label">
                              Organisation <span className="fontSize">(Optional)</span>
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="message"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="message" className="form-label">
                              Message  <span className="fontSize">(Optional)</span>
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>


                        )}
                      />
                    </div>
                    <div className="col-12 marginTop flex">
                      <Button
                        type="submit"
                        label="SUBMIT"
                        className="nav-btn float-end content-text mt-3 text-white text-center"
                        handleClick={onSubmit}
                      />
                    </div>
                  </form>
                )}
              ></Form>
            </div>
          </div>
        </div>
        <Hidenavbar />
        <Validation />
      </div>
    </>
  );
}