import React from "react";
import Hidenavbar from "./Hidenavbar";
import Footer from "./footer";
// import Header from "./header";
import { Link } from "react-router-dom";

export default class FreqAskedQuestions extends React.Component {
  componentDidMount() {
    document.title = "Finplex: FAQ";
  }
  render() {
    return (
      <>
        {/* <Header /> */}
        <div className="loancss margin-top about container col-12">
          <div className="row mt-lg-5 px-md-5">
            <p className="sub-title text-center">Frequently Asked Questions</p>
            <div className="col-12">
              <div className="accordion accordion-flush mt-5" id="faqlist">
                <div className="accordion-item mt-5">
                  <h2 className="accordion-header mb-3 ">
                    <button
                      className="accordion-button collapsed "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      WHO IS FINPLEX ?
                    </button>
                  </h2>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse "
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        Finplex Technologies Private Limited is a fintech
                        platform where businesses can get loan financing for
                        almost any purpose, including to expand their
                        operations, buy new equipment, manage working capital,
                        consolidate existing debt etc.,
                      </p>
                      <p>
                        Finplex works with Banks and NBFCs, our lending
                        partners, to provide the loans and supports the small
                        business sector in India, helping the core of Indian
                        economy.
                      </p>
                      <p>
                        Finplex arranges for loans for only established and
                        creditworthy businesses, with a minimum two years of
                        history. In order to assess the loan applications, we
                        source information on credit bureaus such as CIBIL, CRIF
                        Highmark and Experian as well as from other sources.
                        After approval by Finplex, the loan applications are
                        sent to the lending partners for a review.
                      </p>
                      <p>
                        Finplex also provides various reporting tools to the
                        lending Banks and NBFCs. If payments are missed by the
                        borrower, our in-house collections team and our external
                        collections partners will chase the borrowers to recover
                        the dues.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      WHAT IS A FINPLEX PLATFORM?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Finplex platform is a marketplace for loans, where
                      different lenders will compete with each other to provide
                      loan to our customer, so that our customers can avail the
                      best possible deal. We plan to work with several reputed
                      lenders across the country.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      IS FINPLEX REGULATED BY THE RBI?
                    </button>
                  </h2>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      While we are not regulated by the RBI in the sense that
                      Banks and NBFCs are, we follow guidelines on managing
                      risks and code of conduct issued by RBI on November 3,
                      2006. The lenders on our platform are regulated and we
                      comply with all regulations which impact our relationship
                      with our lenders.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      WHY SHOULD WE BORROW FROM FINPLEX?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Finplex is focused on providing easy and quick loans to
                      our customers at competitive rates. We can assess a loan
                      application in as little as 24 hours, so that our
                      customers can stay focused on their business. In addition,
                      Finplex operates a marketplace for loans so that our
                      customers get the capital they require at competitive
                      rates.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-5"
                    >
                      WHAT DOCUMENTS ARE REQUIRED TO GET A LOAN FROM FINPLEX?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>We require:</p>
                      <ul>
                        <li>
                          <span>
                            <b>Proof of Identity & Age Proof Passport</b>
                            <br /> Valid driving license, Voters ID Card, Pan
                            card, Aadhaar card
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Proof of Address & Signature</b>
                            <br /> Valid driving license, Leave & License
                            Agreement, Register sale deed or sale agreement
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Proof of Address of the Firm </b>
                            <br />
                            Latest Telephone Bill (or) Electricity Bill (or)
                            Bank Statement (or) Leave and licence agreement
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Proof of Financial Statement</b>
                            <br /> Audited Financial Statement, ITR of the last
                            2 or 3 years along with the schedules, Notes to
                            Accounts and Audit Report & All relevant annexures
                          </span>
                        </li>

                        <li>
                          <span>
                            {" "}
                            <b>Proof of Bank Statement</b>
                            <br /> Last 6 or 12 months, Self-Certified
                            Provisional Balance Sheet and P&L Account of latest
                            Year Sheet
                          </span>
                        </li>

                        <li>
                          <span>
                            <b> Proof of Company Documents</b>
                            <br /> Firm Registration (or) Shop Establishment
                            (or) CST (or) VAT (or) GST Certificate.
                          </span>
                        </li>

                        <li>
                          <span>
                            {" "}
                            <b> Proof of Property</b>
                            <br /> Property paper including Title Deed,
                            Completion Certificate, Occupancy Certificate, Tax
                            Receipt, Sanction Plan, etc.
                          </span>
                        </li>
                      </ul>
                      <p />
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-6"
                    >
                      <p>
                        {" "}
                        WHAT ARE THE PURPOSES FOR WHICH A BUSINESS CAN GET A
                        LOAN FROM FINPLEX?{" "}
                      </p>
                    </button>
                  </h2>
                  <div
                    id="faq-content-6"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        We provide loans for almost all business purposes,
                        including:
                      </p>
                      <ul>
                        <li>
                          <span>Business expansion</span>
                        </li>
                        <li>
                          <span>Working capital finance</span>
                        </li>
                        <li>
                          <span>Inventory finance</span>
                        </li>
                        <li>
                          <span>Purchase of equipment and machinery</span>
                        </li>
                        <li>
                          <span>Renovation of business premises</span>
                        </li>
                      </ul>
                      <p />
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-7"
                    >
                      WHAT ARE THE LOCATIONS YOU SERVE?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-7"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>We currently provide loans within 60 kms radius of:</p>
                      <ul>
                        <li>
                          <span>Bangalore </span>
                        </li>
                        <li>
                          <span> Hyderabad</span>
                        </li>
                        <li>
                          <span> Vizag</span>
                        </li>
                        <li>
                          <span>Vijayawada </span>
                        </li>
                        <li>
                          <span> Hubli</span>
                        </li>
                        <li>
                          <span>Mysore </span>
                        </li>
                        <li>
                          <span>Delhi </span>
                        </li>
                        <li>
                          <span>Gurgaon</span>
                        </li>
                        <li>
                          <span>Faridabad </span>
                        </li>
                        <li>
                          <span>Noida </span>
                        </li>
                        <li>
                          <span> Greater Noida</span>
                        </li>
                        <li>
                          <span>Indore </span>
                        </li>
                        <li>
                          <span> Ghaziabad </span>
                        </li>
                      </ul>
                      <p>
                        We are constantly adding new locations, so please check
                        this page again shortly if your city or town is not
                        included above.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-8"
                    >
                      HOW MUCH CAN A BUSINESS BORROW FROM FINPLEX?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-8"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        We give loans starting with Rs. 3 lakhs, up to a maximum
                        of Rs. 40 lakhs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-9"
                    >
                      WHAT IS THE DURATION OF LOANS PROVIDED BY FINPLEX?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-9"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        We provide flexible terms for repayment, starting with
                        12 months and up to a maximum of 36 months.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-10"
                    >
                      HOW DO I REPAY THE LOAN?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-10"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        The loans will be repaid through equated monthly
                        instalments (EMIs) directly to the lending partner,
                        through NACH.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-11"
                    >
                      DO I NEED TO PAY A FEE TO APPLY FOR A LOAN?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-11"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>There is no fee for applying for a loan.</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-12"
                    >
                      I HAVE MORE QUESTIONS, WHO SHOULD I CONTACT?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-12"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        You can either call our toll-free number at{" "}
                        <a
                          className="text-decoration-none link-color"
                          href="tel:18002701233"
                        >
                          {" "}
                          1800-2701-233{" "}
                        </a>{" "}
                        or write to us at
                        <a
                          href="mailto:customercare@finplex.in "
                          className="text-decoration-none link-color"
                        >
                          {" "}
                          customercare@finplex.in.
                        </a>{" "}
                        Our customer support executives are available between
                        9:30 am and 6:00 pm, Monday to Friday except on bank or
                        public holidays.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header mb-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-13"
                    >
                      CAN I WITHDRAW MY CONSENT TO ACCESS MY CREDIT INFORMATION
                      REPORT?{" "}
                    </button>
                  </h2>
                  <div
                    id="faq-content-13"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                        Yes, you can rescind your consent to access your credit
                        information report at any time. Please email us at{" "}
                        <a
                          href="mailto:consent@finplex.in"
                          className="text-decoration-none link-color"
                        >
                          {" "}
                          consent@finplex.in{" "}
                        </a>
                        and we will respond within 7 days. But please note that,
                        we have a right to retain your credit information data
                        as long as the loan is outstanding.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Hidenavbar />
      </>
    );
  }
}
