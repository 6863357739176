import React, { Component } from "react";
import "../styles/main.scss";
import Hidenavbar from "../functions/hideNavBar";
import Calc from "../functions/calculate";
import { Link } from "react-router-dom";
// import Laptop_mobile from "../assets/Laptop_Ani.gif";
import Laptop_mobile from "../assets/Finplex_Technology_Ani.gif";
import Man_Animation3 from "../assets/animated_man_image.gif";
import Application_mobile_image from "../assets/Application_mobile_image.svg";
import Application_image from "../assets/Application_image.svg";
import Group1829 from "../assets/Group1829.svg";
import Group_1829_4 from "../assets/Group_1829_4.svg";
import Transparent from "../assets/Transparent.svg";
import Group_1829_1 from "../assets/Group_1829_1.svg";
import Group_1829_5 from "../assets/Group_1829_5.svg";
import Speed from "../assets/Speed.svg";
import Group_1829_2 from "../assets/Group_1829_2.svg";
import Group_1829_6 from "../assets/Group_1829_6.svg";
import Home from "../assets/Home.svg";
import Group1851 from "../assets/Group1851.svg";
import Group1852 from "../assets/Group1852.svg";
import green_quotation from "../assets/green_quotation.svg";
import NetworkSolutionPic from "../assets/NetworkSolutionPic.jpg";
import Clarion from "../assets/Clarion.jpg";
import MohdAfzalandSons from "../assets/MohdAfzalandSons.jpg";
import DolphinAircon from "../assets/DolphinAircon.jpg";
import AdvanceOrthoCentrepiccopy from "../assets/AdvanceOrthoCentrepiccopy.jpg";
import Gayathri from "../assets/Gayathri.jpg";
import IMG_20210818_WA0021 from "../assets/IMG_20210818_WA0021.jpg";
import RBL_Bank from "../assets/RBL_Bank.svg";
import Vector from "../assets/Vector.svg";
import Group from "../assets/Group.svg";
import Maskgroup from "../assets/Maskgroup.svg";
import Group_1 from "../assets/Group_1.svg";
import Group1835 from "../assets/Group1835.svg";
import yes_bank_logo1 from "../assets/yes_bank_logo1.svg";
// import Slider from '../functions/slider';
import { Slide } from "react-toastify";
import Arrow1 from "../assets/arrow_left.svg";
import Rightarrow from "../assets/arrow_right.png";

class home extends Component {
  componentDidMount() {
    document.title = "Finplex";
  }
  render() {
    return (
      <>
        <div className="fontStyle">
          <div className="container-fluid container-xxl">
            <section>
              <div
                id="carouselExampleIndi"
                className="carousel slide"
                data-bs-pause="none"
              >
                <div className="row margin-top d-flex justify-content-center">
                  <div className="col-12 col-md-12">
                    <div className="carousel-inner min-hgt">
                      <div className="carousel-item active text-center">
                        <img
                          alt=""
                          src={Man_Animation3}
                          className="carousel_Manimg"
                        />
                      </div>
                      <div className="carousel-item text-center">
                        <img
                          alt=""
                          src={Laptop_mobile}
                          className="carousel_Laptop"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndi"
                    data-bs-slide="prev"
                  >
                    <span>
                      <img
                        className="sliderleftarrow"
                        alt=""
                        src={Arrow1}
                        style={{
                          width: "54px",
                          height: "54px",
                          marginTop: "9vw",
                        }}
                      />
                    </span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndi"
                    data-bs-slide="next"
                  >
                    <span>
                      <img
                        className="sliderrightarrow"
                        alt=""
                        src={Rightarrow}
                        style={{
                          width: "54px",
                          height: "54px",
                          marginRight: "5vw",
                          marginTop: "9vw",
                        }}
                      />
                    </span>
                  </button>
                </div>
              </div>

              <div className="container-fluid container-xxl">
                <div className=" d-md-none home">
                  <p className="Quick-title mt-1 text-center">
                  Swift, Easy and Affordable Business Loans
                  </p>
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-11 col-sm-10">
                      <div className="innerText">
                        <p className="mt-3">
                          Expand your business and grow your cash flow with
                          quick loans at competitive rates.
                        </p>
                        <hr className="my-4" />
                        <p className="mt-3">
                          Experience the convenience of monthly repayments over
                          1-3 years, with competitive interest rates without
                          mortgage collateral. Take advantage of our instant
                          Loan approval process ranging from Rs.3 Lakhs to Rs.40
                          Lakhs.
                        </p>
                        <hr className="my-4" />
                        <p className="">
                          Take advantage of our instant loan approval process,
                          ranging from Rs.3 lakhs to Rs.75 lakhs.
                        </p>
                      </div>
                      <div className=" btn-center d-flex justify-content-center">
                        <Link
                          to="/quick"
                          className=" text-white  nav-btn  px-md-5 mt-4"
                        >
                          QUICK APPLY
                        </Link>
                      </div>
                    </div>
                    {/*  <div className="col-12 col-lg-5 text-center">
                      <img className="img-fluid" alt="" src={Man_Animation3} />
                    </div>
                   */}
                  </div>
                </div>
                <div className="d-none d-md-block">
                  <div className="row home  d-flex justify-content-center">
                    <div className="col-12 text-center">
                      <p className="Quick-title text-center mt-0">
                      Swift, Easy and Affordable Business Loans
                      </p>
                    </div>
                    <div className="col-12 text-center mt-md-4 ">
                      <div className="innerText px-1 d-flex align-items-center">
                        <div className="container-fluid container-xxl">
                          {/* <div className="px-2 text-center finplex-label mt-5 pt-1"></div> */}
                          <div className="text-center px-sm-2 px-md-1 px-xl-4 px-xxl-0">
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-4 col-lg-3">
                                <div className="row">
                                  <p className=" ">
                                    Expand your business and grow your cash flow
                                    with quick loans at competitive rates.
                                  </p>
                                </div>
                              </div>
                              {/* <div className="col-md-2 col-lg-1 d-none d-md-block vertical-line mr-vl" /> */}
                              <div className="col-md-4 border-left border-right">
                                <div className="row ml-xl">
                                  <p className="">
                                    Experience the convenience of monthly
                                    repayments over a flexible 1–20 year period
                                    with the advantage of competitive interest
                                    rates, whether or not you choose to provide
                                    collateral.
                                  </p>
                                </div>
                              </div>
                              {/* <div className=" col-md-0 col-lg-1 vertical-line    d-none d-lg-block" /> */}
                              <div className="col-md-4 col-lg-3">
                                <div className="row">
                                  <p className="">
                                    Take advantage of our instant loan approval
                                    process, ranging from Rs.3 lakhs to Rs.75
                                    lakhs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <p className>
                          Experience the convenience of monthly repayments over
                          1-3 years, with competitive interest rates without
                          mortgage collateral.
                        </p>
                        <p>
                          Take advantage of our instant Loan approval process
                          ranging from Rs.
                          <span className="number-innerText">3</span> Lakhs to
                          Rs. <span className="number-innerText"> 40 </span>
                          <span>Lakhs</span>
                        </p> */}
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link
                          to="/quick"
                          className=" text-white  nav-btn  px-md-5 mt-4"
                        >
                          QUICK APPLY
                        </Link>
                        {/* window.location.reload() */}
                        {/* <a className=" text-white  nav-btn  px-md-5 mt-4" href="./quickApply.html">QUICK
                        APPLY</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="loan-bg">
            <div className="container-fluid container-xxl">
              <div className="px-2 text-center finplex-label mt-5 pt-1"></div>
              <div className="text-center mt-5 pb-5 px-sm-2 px-md-5 px-lg-1 px-xl-4 px-xxl-0">
                <div className="row justify-content-center mt-2">
                  <div className=" col-6 col-md-5 col-lg-2 mt-lg-1">
                    <div className="row px-sm-5 px-lg-0 px-xl-0  ">
                      <p className="txt ">LOANS PROCESSED</p>
                      <p className="number-txt">7000+</p>
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-1 d-none d-md-block vertical-line mr-vl" />
                  <div className="col-6 col-lg-2 col-md-5 col-lg-3 col-xl-3 mt-lg-1">
                    <div className="row ml-xl">
                      <p className="txt">ACTIVE CURRENT USERS</p>
                      <p className="number-txt">15000+</p>
                    </div>
                  </div>
                  <div className=" col-md-0 col-lg-1 vertical-line    d-none d-lg-block" />
                  <div className="col-6 col-md-5 col-lg-2 mt-md-4 mt-lg-1">
                    <div className="row px-sm-5 px-lg-0 px-xl-0  ">
                      <p className="txt">DISTRIBUTED LOANS</p>
                      <p className="number-txt"> 300 Cr+</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container-fluid container-xxl">
              <div className="row justify-content-center">
                <div className="col-12">
                  <p className="text-center Quick-title">
                    Quick And Easy Application Process
                  </p>
                </div>
                <div className="col-11 col-lg-9">
                  <p className="innerText text-center">
                    Focus on your business development; We will handle your
                    financing with our streamlined process from application to
                    loan disbursal.
                  </p>
                </div>
              </div>
              <div className="container-fluid text-center container-xxl">
                <div className="d-md-none">
                  <img
                    className=" img-fluid mt-2  text-center"
                    src={Application_mobile_image}
                  />
                </div>
              </div>
              <div className="container-fluid container-xxl text-center">
                <div className="d-none d-md-block">
                  <img
                    className="img-fluid mt-lg-2 text-center centre px-md-5"
                    src={Application_image}
                  />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container-fluid  container-xxl mt-5">
              <div className="col-12 mt-sm-4  ">
                <h1 className="text-center Quick-title">
                  Business Loan Calculator
                </h1>
                <Calc />
              </div>
            </div>
          </section>
          <section>
            <section className="testimonial-section  d-flex align-items-center mt-5">
              <div className="container mt-5">
                <div className="row align-items-center mt-3 mt-lg-5">
                  <p className="title text-center">
                    Your Success, Our Commitment
                  </p>
                  <div className="col-md-12 mt-5 pt-3">
                    <div
                      id="myCarousel"
                      className="carousel slide"
                      data-bs-interval={15000}
                      data-bs-pause="none"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner slider-inner">
                        <div
                          className="carousel-item testi-item active"
                          data-color="#fb9c9a"
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-7 col-lg-7">
                              <div className="row">
                                <div className="col-md-2 d-none d-md-block">
                                  <img className="d-inline" src={Group1829} />
                                </div>
                                <div className="col-md-10 d-none d-md-block px-md-5 px-lg-3">
                                  <p className="d-inline slider-title ">
                                    Personal Account Advisor
                                  </p>
                                  <br />
                                  <p className="slider-text mt-lg-4">
                                    Dedicated advisor for guiding the process
                                    and addressing any queries along the way. We
                                    Offer a Diverse Range of Business Loans
                                    Ensuring Transparency and Security
                                    Throughout the Process.
                                  </p>
                                </div>
                                <div className="  d-md-none">
                                  <img
                                    className="d-inline px-1"
                                    src={Group_1829_4}
                                  />
                                  <p className="d-inline slider-title px-1">
                                    Personal Account Advisor
                                  </p>
                                  <p className="slider-text mt-3 px-3">
                                    Dedicated advisor for guiding the process
                                    and addressing any queries along the way. We
                                    Offer a Diverse Range of Business Loans
                                    Ensuring Transparency and Security
                                    Throughout the Process.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 px-lg-5 flex">
                              <img
                                className="img-fluid px-4"
                                src={Transparent}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item testi-item "
                          data-color="#fb9c9a"
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-7 col-lg-7">
                              <div className="row">
                                <div className="col-md-2 d-none d-md-block">
                                  <img
                                    className="d-inline"
                                    alt=""
                                    src={Group_1829_1}
                                  />
                                </div>
                                <div className="col-md-10 d-none d-md-block px-md-5 px-lg-3">
                                  <p className="d-inline slider-title ">
                                    Lightning-fast Decisions
                                  </p>
                                  <br />
                                  <p className="slider-text mt-lg-4">
                                    <br />
                                    We match our speed to your potential; this
                                    lightning-fast approach aids in maintaining
                                    your business's rapid growth by providing
                                    funds and empowering business growth and
                                    problem-solving.
                                  </p>
                                </div>
                                <div className="  d-md-none">
                                  <img
                                    className="d-inline px-2"
                                    alt=""
                                    src={Group_1829_5}
                                  />
                                  <p className="d-inline slider-title px-1">
                                    Lightening-fast Decisions
                                  </p>
                                  <p className="slider-text mt-3 px-3">
                                    <br />
                                    We match our speed to your potential; this
                                    lightning-fast approach aids in maintaining
                                    your business's rapid growth by providing
                                    funds and empowering business growth and
                                    problem-solving.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 px-lg-5 pb-sm-5 flex">
                              <img
                                className="img-fluid px-4 pb-sm-5"
                                alt=""
                                src={Speed}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item testi-item "
                          data-color="#fb9c9a"
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-7 col-lg-7">
                              <div className="row">
                                <div className="col-md-2 d-none d-md-block">
                                  <img
                                    className="d-inline"
                                    alt=""
                                    src={Group_1829_2}
                                  />
                                </div>
                                <div className="col-md-10 d-none d-md-block px-md-5 px-lg-3">
                                  <p className="d-inline slider-title ">
                                    Customised Loan
                                  </p>
                                  <br />
                                  <p className="slider-text mt-lg-4">
                                    <br />
                                    Enjoy Attractive Competitive Rates, Free
                                    from Financial Limitations Handcrafted Loans
                                    for Dreams of Expansion Reaches New Heights.
                                  </p>
                                </div>
                                <div className="  d-md-none">
                                  <img
                                    className="d-inline px-2"
                                    alt=""
                                    src={Group_1829_6}
                                  />
                                  <p className="d-inline slider-title px-2">
                                    Customised Loan
                                  </p>
                                  <p className="slider-text mt-3 px-3">
                                    <br />
                                    Enjoy Attractive Competitive Rates, Free
                                    from Financial Limitations Handcrafted Loans
                                    for Dreams of Expansion Reaches New Heights.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 px-lg-5">
                              <img
                                className="img-fluid px-4 "
                                alt=""
                                src={Home}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-none d-md-block">
                          <div className="carousel-indicators ">
                            <button
                              type="button"
                              data-bs-target="#myCarousel"
                              data-bs-slide-to={0}
                              className="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            />
                            <button
                              type="button"
                              data-bs-target="#myCarousel"
                              data-bs-slide-to={1}
                              aria-label="Slide 2"
                            />
                            <button
                              type="button"
                              data-bs-target="#myCarousel"
                              data-bs-slide-to={2}
                              aria-label="Slide 3"
                            />
                          </div>
                        </div>
                        <div className="d-none d-md-block">
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            />
                            <span>
                              <img
                                alt=""
                                src={Group1852}
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  marginTop: "-10vw",
                                  marginLeft: "-8.5vw",
                                }}
                              />
                            </span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            />
                            <span>
                              <img
                                alt=""
                                src={Group1851}
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  marginTop: "-10vw",
                                  marginRight: "-4vw",
                                }}
                              />
                            </span>
                          </button>
                        </div>
                        <div className="d-md-none">
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            />
                            <span>
                              <img
                                alt=""
                                src={Group1852}
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  marginTop: "50vw",
                                  marginLeft: "-6vw",
                                }}
                              />
                            </span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            />
                            <span>
                              <img
                                alt=""
                                src={Group1851}
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  marginTop: "50vw",
                                }}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*
             */}
            <div className="container  text-center d-none d-md-block ">
              <p className="title">What Our Customers Say</p>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-interval={20000}
                data-bs-ride="carousel"
                data-bs-pause="none"
              >
                <div className="row mt-md-5">
                  <div className="col-12 col-md-12">
                    <div
                      className="carousel-inner"
                      style={{ minHeight: "430px" }}
                    >
                      <div className="carousel-item active">
                        <img alt="" src={green_quotation} />
                        <p className="text-center customer-para">
                          {" "}
                          I am happy with their service and they managed to make
                          a <br />
                          complicated process seamless and hassle-free.
                        </p>
                        <p className="customer-Name">Pawan Dubey</p>
                        <p className="customer-pos">
                          Proprietor, Regard Network Solution
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <img alt="" src={green_quotation} />
                        <p className="text-center customer-para">
                          {" "}
                          Excellent customer support and applied for the loan{" "}
                          <br /> and it was granted on time.
                          <br /> Thank you for the support for getting the loan.{" "}
                        </p>
                        <p className="customer-Name">Sunnam Raju</p>
                        <p className="customer-pos px-md-5 px-lg-0">
                          {" "}
                          Proprietor, Clarion Security And Integrated Services
                          India Pvt Ltd
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <img alt="" src={green_quotation} />
                        <p className="text-center customer-para ">
                          {" "}
                          I am very happy to have relied on Finplex
                          <br />
                          and will use them again for any future needs
                        </p>
                        <p className="customer-Name"> Mohd Aslam</p>
                        <p className="customer-pos">
                          {" "}
                          Managing Partner, Mohd Afzal &amp; Sons
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <img alt="" src={green_quotation} />
                        <p className="customer-para text-center">
                          I have looked to find a best financial solution for my
                          business. <br />
                          Finplex provides good service and deals us politely.
                        </p>
                        <p className="customer-Name">Sanjay Kumar Hirawat</p>
                        <p className="customer-pos">
                          {" "}
                          Managing Partner, Dolphin Aircon
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <img alt="" src={green_quotation} />
                        <p className="customer-para">
                          The online Application process at finplex is very easy
                          to use
                          <br />
                          and we were able to upload all documents
                          <br /> directly on the website this saved a lot time
                          and hassle.
                        </p>
                        <p className="customer-Name">Rajbahadur Prajapati</p>
                        <p className="customer-pos">Advance Ortho center.</p>
                      </div>
                      <div className="carousel-item  ">
                        <img alt="" src={green_quotation} />
                        <p className="customer-para text-center">
                          The service is good. During the process the
                          information <br />
                          was given to me in first hand and the process
                          completed smoothly.
                        </p>
                        <p className="customer-Name">Phani Kumar Toodi</p>
                        <p className="customer-pos">
                          Proprietor, Gayathri Enterprises
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <img alt="" src={green_quotation} />
                        <p className="customer-para text-center">
                          {" "}
                          The service is good. Finplex helped me get to a
                          business loan <br />
                          when we really needed it and when I was being turned
                          down by other lenders
                          <br /> due to erroneous data. The Finplex team took
                          time to understand
                          <br /> our business and helped us when we needed it.
                        </p>
                        <p className="customer-Name"> CH.V.P. Prasad</p>
                        <p className="customer-pos">
                          {" "}
                          Proprietor, Lalitha Traders
                        </p>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        />
                        <span>
                          <img
                            alt=""
                            src={Group1852}
                            style={{
                              width: "54px",
                              height: "54px",
                              marginTop: "9vw",
                            }}
                          />
                        </span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        />
                        <span>
                          <img
                            alt=""
                            src={Group1851}
                            style={{
                              width: "54px",
                              height: "54px",
                              marginRight: "5vw",
                              marginTop: "9vw",
                            }}
                          />
                        </span>
                      </button>
                    </div>
                    <div className="carousel-indicators indicator customer-img ">
                      <img
                        alt=""
                        src={NetworkSolutionPic}
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={0}
                        className="active px-md-1 px-lg-2 customer-slider-img"
                        aria-current="true"
                        aria-label="Slide 1"
                      />
                      <img
                        alt=""
                        src={Clarion}
                        className="px-lg-2 px-md-1 customer-slider-img"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={1}
                        aria-label="Slide 2"
                      />
                      <img
                        alt=""
                        src={MohdAfzalandSons}
                        className="px-lg-2 px-md-1 customer-slider-img"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={2}
                        aria-label="Slide 3"
                      />
                      <img
                        alt=""
                        src={DolphinAircon}
                        className="px-lg-2 px-md-1 customer-slider-img"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={3}
                        aria-current="true"
                        aria-label="Slide 4"
                      />
                      <img
                        alt=""
                        src={AdvanceOrthoCentrepiccopy}
                        className="px-lg-2 px-md-1 customer-slider-img"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={4}
                        aria-label="Slide 5"
                      />
                      <img
                        alt=""
                        src={Gayathri}
                        className="px-lg-2 px-md-1 customer-slider-img"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={5}
                        aria-label="Slide 6"
                      />
                      <img
                        alt=""
                        src={IMG_20210818_WA0021}
                        className="px-lg-2 px-md-1 customer-slider-img"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={6}
                        aria-label="Slide 7"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-sm-5 text-center d-md-none">
              <p className="title">What Our Customers Say</p>
              <div
                id="carouselExample"
                className="carousel slide"
                data-bs-interval={20000}
                data-bs-ride="carousel"
                data-bs-pause="none"
              >
                <div className="row mt-md-5">
                  <div className="col-12 col-md-12">
                    <div className="carousel-inner customer-innercarousel">
                      <div className=" carousel-item active">
                        <div className="row mt-4">
                          <div className="col-1 " />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={NetworkSolutionPic}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="text-center customer-para">
                          {" "}
                          I am happy with their service and they managed to make
                          a <br />
                          complicated process seamless and hassle-free.
                        </p>
                        <p className="customer-Name">Pawan Dubey</p>
                        <p className="customer-pos">
                          Proprietor, Regard Network Solution
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <div className="row mt-4">
                          <div className="col-1" />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={Clarion}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="text-center customer-para">
                          {" "}
                          Excellent customer support and applied for the loan
                          and it was granted on time.
                          <br /> Thank you for the support for getting the loan.{" "}
                        </p>
                        <p className="customer-Name">Sunnam Raju</p>
                        <p className="customer-pos">
                          {" "}
                          Proprietor, Clarion Security And Integrated Services
                          India Pvt Ltd
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <div className="row mt-4">
                          <div className="col-1" />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={MohdAfzalandSons}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="text-center customer-para ">
                          {" "}
                          I am very happy to have relied on Finplex and will use
                          them again for any future needs
                        </p>
                        <p className="customer-Name"> Mohd Aslam</p>
                        <p className="customer-pos">
                          {" "}
                          Managing Partner, Mohd Afzal &amp; Sons
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <div className="row mt-4">
                          <div className="col-1" />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={DolphinAircon}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="customer-para text-center">
                          I have looked to find a best financial solution for my
                          business. <br />
                          Finplex provides good service and deals us politely.
                        </p>
                        <p className="customer-Name">Sanjay Kumar Hirawat</p>
                        <p className="customer-pos">
                          {" "}
                          Managing Partner, Dolphin Aircon
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <div className="row mt-4">
                          <div className="col-1" />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={AdvanceOrthoCentrepiccopy}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="customer-para">
                          The online Application process at finplex is very easy
                          to use and we were able to upload <br />
                          all documents directly on the website this saved a lot
                          time and hassle.
                        </p>
                        <p className="customer-Name">Rajbahadur Prajapati</p>
                        <p className="customer-pos">Advance Ortho center.</p>
                      </div>
                      <div className="carousel-item  ">
                        <div className="row mt-4">
                          <div className="col-1" />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={Gayathri}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="customer-para text-center">
                          The service is good. During the process the
                          information <br />
                          was given to me in first hand and the process
                          completed smoothly.
                        </p>
                        <p className="customer-Name">Phani Kumar Toodi</p>
                        <p className="customer-pos">
                          Proprietor, Gayathri Enterprises
                        </p>
                      </div>
                      <div className="carousel-item  ">
                        <div className="row mt-4">
                          <div className="col-1" />
                          <div className="col-10">
                            <img
                              style={{ width: "130px", height: "130px" }}
                              alt=""
                              src={IMG_20210818_WA0021}
                            />
                            <div className="col-1" />
                          </div>
                        </div>
                        <img
                          style={{ marginTop: "60px" }}
                          alt=""
                          src={green_quotation}
                        />
                        <p className="customer-para text-center">
                          {" "}
                          The service is good. Finplex helped me get to a
                          business loan <br />
                          when we really needed it and when I was being turned
                          down by other lenders
                          <br /> due to erroneous data. The Finplex team took
                          time to understand
                          <br /> our business and helped us when we needed it.
                        </p>
                        <p className="customer-Name"> CH.V.P. Prasad</p>
                        <p className="customer-pos">
                          {" "}
                          Proprietor, Lalitha Traders
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span>
                      <img
                        alt=""
                        src={Group1852}
                        style={{
                          width: "34px",
                          height: "34px",
                          marginTop: "-400px",
                        }}
                      />
                    </span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span>
                      <img
                        alt=""
                        src={Group1851}
                        style={{
                          width: "34px",
                          height: "34px",
                          marginTop: "-400px",
                          marginRight: "5vw",
                        }}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            {/* <div w3-include-html="./footer.html" /> */}
          </section>
          {/* <br/><br/>
          <section>
            <div className="container text-center d-none d-md-block">
              <div className="container margin-top">
                <div className="col-12 col-md-12 col-xl-12 px-5 px-xl-5 px-md-3">
                  <p className="title text-center">Our Partners</p>
                  <div className="row  d-flex justify-content-center">
                    <div className="centre col-md-5 col-lg-3  mt-5">
                      <img src={RBL_Bank} />
                    </div>
                    <div className="centre col-md-5 col-lg-3 mt-5">
                      <img src={Vector} className="mt-1" />
                    </div>
                    <div className="centre col-md-5 col-lg-3 mt-5 text-lg-center">
                      {" "}
                      <img src={Group} />{" "}
                    </div>
                    <div className="centre col-md-5 col-lg-3 mt-5 text-lg-right">
                      <img src={Group_1} />
                    </div>
                  </div>
                  <div className="d-flex mt-5 justify-content-center">
                    <Link
                      className="d-flex align-items-center text-decoration-none  justify-content-center secondary-btn mt-5"
                      to="/partnerWithUs"
                    >
                      Join With Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>

        <Hidenavbar />
      </>
    );
  }
}
export default home;
