function Hidenavbar()
{
 var prevScrollpos = window.pageYOffset;
 window.onscroll = function () {
     var currentScrollPos = window.pageYOffset;
     if (prevScrollpos > currentScrollPos) {
         document.getElementById("navbar").classList.remove("top");
     } else {
       document.getElementById("navbar").classList.add("top");
     }
     prevScrollpos = currentScrollPos;
 } 
}
 export default Hidenavbar;