import { useState,useEffect } from "react";
import MarketNews from "./marketNews";
import axios from "axios";
import { baseCMSUrl } from "./constant";
import { Link, useLocation } from "react-router-dom";


export default function TabsComponent() {
    const [openTab, setOpenTab] = useState("All ");
    const [tabsData, setTabsData] = useState([]);
    const searchParams = new URLSearchParams(useLocation().search);
    const tags = searchParams.get('tags');
   
    useEffect(() => {
        const fetchPosts = async() => {       
            
            const response = await axios.get(`${baseCMSUrl}/api/banking-tags`);
            setTabsData(response.data.data);
            if(tags){
                setOpenTab(tags);
            }           
           };      
           
        fetchPosts();
    }, []);
    
   
    return (
        
        <div>
         
            <div className="container-xxl mx-auto mt-4">
                <div className="">
                    <div className="ml-lg-1">
                    <ul className=" mx-lg-5  mx-3 overflow-auto inner-content  d-flex d-flex-row gap-4  list-unstyled ">
                        {tabsData.map((tab) => (
                            <li key={tab.attributes.title} className="whitespace-nowrap">
                                {console.log(openTab, "open")}
                                <Link to="/banking"
                                    onClick={() =>{setOpenTab(tab.attributes.title)}}
                                    className={tab.attributes.title=== openTab ?" nav-text  text-decoration-none py-1":"" + "nav-text1 text-decoration-none py-1"}
                                >
                                    {tab.attributes.title}
                                </Link>
                            </li> 
                        ))}
                    </ul>
                    </div>
                    <div className=" mt-6 bg-white">
                        {tabsData.map((tab) => (
                            <div>
                            <div
                                key={tab.attributes.title}
                                className={
                                    tab.attributes.title === openTab ? "d-block" : "d-none"
                                }
                            >
                                {console.log(tab.attributes.title,openTab, "tags")}
                               <MarketNews filter={openTab} paginate="true"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}