import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from './components/header';
import Footer from './components/footer';
import home from "./components/home";
import avgloan from "./components/AverageLoan";
import profitmargin from "./components/ProfitMarginLoan";
import revenueloan from "./components/RevenueLoan";
import docrequired from "./components/Doc-Required";
import loaneligibility from "./components/LoanEligibility";
import namepurpose from "./components/you_name_your_purpose";
import differentfrom from "./components/Different_From_Others";
import Investors from "./components/Investors";
import Calc from "./components/Calc";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/main.scss";
import AboutUs from "./components/aboutUs";
import Contactus from "./components/Contactus";
import QuickApply from "./components/quickApply";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsandCondition from "./components/TermsandCondition";
import TermsandUse from "./components/TermsandUse";
import CRIF_TermsandUse from "./components/CRIF_TermsandUse";
import IncomeLoan from './components/IncomeLoan';
import FormCreation from "./components/formCreation";
import ThankYou from './components/thankYou';
import FreqAskedQuestions from "./components/frequentlyAskedQuestion";
import QuickApplyOtp from "./components/quickApplyOtp";
import ScrollToTop from "./components/scrollToTop";
import Error from "./components/error";
import Technology from "./components/technology";
import PartnerWithUs from "./components/partnerWithUs";
import otherRevenueloan from "./components/RevenueLoan_OtherProfessional";
import CodeOfConduct from "./components/codeOfConduct";
import GrievanceRedressal from "./components/grievanceRedressal";
import Calculator from "./components/calculator";
import CustomerSupport from "./components/customerSupport"
import BlogArticle from "./components/blogArticle";
import CaseStudyPage from "./components/caseStudyPage";
import EventPage from "./components/eventPage";
import EventStoryPage from "./components/eventsStoryPage";
import CaseStudyStoryPage from "./components/caseStudyStoryPage";
import NewsStoryPage from "./components/newsStoryPage";
import News from "./components/news";
import securedloan from "./components/securedLoans";
import unSecuredLoan from "./components/unSecuredLoans";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop/>
      <Switch>
        <Route path="/" exact component={home}></Route>
        <Route path="/securedLoans" exact component={securedloan}></Route>
        <Route path="/unSecuredLoan" exact component={unSecuredLoan}></Route>
        <Route path="/loanAgainstProperty" exact component={profitmargin}></Route>
        <Route path="/loansforDoctors" exact component={revenueloan}></Route>
        <Route path="/docreq" exact component={docrequired}></Route>
        <Route path="/loaneligibility" exact component={loaneligibility}></Route>
        <Route path="/namepurpose" exact component={namepurpose}></Route>
        <Route path="/differentfrom" exact component={differentfrom}></Route>
        <Route path="/freqaskedques" exact component={FreqAskedQuestions}></Route>
        <Route path="/privacypolicy" exact component={PrivacyPolicy}></Route>
        <Route path="/termsandcondition" exact component={TermsandCondition}></Route>
        <Route path="/termsofuse" exact component={TermsandUse}></Route>
        <Route path="/crif_termsanduse" exact component={CRIF_TermsandUse}></Route>
        <Route path="/investors" exact component={Investors}></Route>
        <Route path="/aboutus" exact component={AboutUs}></Route>
        <Route exact path="/contactus" component={Contactus}></Route>
        <Route exact path="/quick" component={QuickApply}></Route>
        <Route path="/blog" component={BlogArticle}></Route>
         <Route path="/calc" exact component={Calc}></Route> 
        <Route path="/loan-process" exact component={FormCreation}></Route>
        <Route path="/thankYou" exact component={ThankYou}></Route>
        <Route path="/error" exact component={Error}></Route>        
        <Route path="/technology" exact component={Technology}></Route>
        <Route path="/quickApplyOtp" exact component={QuickApplyOtp}></Route>
        <Route path="/partnerWithUs" exact component={PartnerWithUs}></Route>
        <Route path="/loansforProfessionals" exact component={otherRevenueloan}></Route>
        <Route path="/codeOfConduct" exact component={CodeOfConduct}></Route>
        <Route path="/grievanceRedressal" exact component={GrievanceRedressal}></Route>
        <Route path="/calculator" exact component={Calculator}></Route>
        <Route path="/customerSupport" exact component={CustomerSupport}></Route>
        {/* <Route path="/blogs" exact component={Blogs}></Route> */}
        <Route path="/trending" exact component={CaseStudyPage}></Route>
        <Route path="/TrendingStory" exact component={CaseStudyStoryPage}></Route>
        <Route path="/banking" exact component={News}></Route>
        <Route path="/BankingStory" exact component={NewsStoryPage}></Route>
        <Route path="/technologys" exact component={EventPage}></Route>
        <Route path="/TechnologyStory" exact component={EventStoryPage}></Route>   
         <Route path="/*" exact component={Error}></Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;