import React from 'react'
import errorImage  from '../assets/Group-1295.svg';
import Logo from "../assets/LOGO.svg";
import { Link } from 'react-router-dom';
export default function Error() {
    return (
        <div>
        <div className="container text-center about">
            <div className=" w-container">
            <div className=" text-center margin-top">
                            <img src={errorImage} className="img-fluid mt-lg-5 mt-xl-0" />
                          </div>  <div className="error-logo-div "><img
                            className="img-fluid brand-img ms-xl-2 mt-4"
                            alt="" src={Logo}
                          /></div>
            <div className="mt-5">
                <div className="error-text">Maybe this page moved? Got deleted?</div>
                <div className="mt-1">Is hiding out in quarantine? Never existed in the first place?.</div>
            </div>
            <div className="col-12 col-md-12 text-center">
               
               <Link to="/" className='text-decoration-none '>  <button className="font-colors mt-5 primary-button text-white" >Back To Home</button></Link> 
                </div>
            </div>
        </div>
        </div>
    )
} 