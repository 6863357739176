import { useState, useEffect, React } from "react";
import axios from "axios";
import { baseCMSUrl } from "./constant";
import { useLocation, Link } from 'react-router-dom';
// import eventDetails from '../assets/eventDetails.jpg';
// import Contact from "./contactUs";
import ReactPlayer from 'react-player';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Storypage from "./storyPage";


export default function EventStoryPage(props) {
    const [eventsData, setEventsData] = useState([]);
    const [openTab, setOpenTab] = useState([]);
    const searchParams = new URLSearchParams(useLocation().search);
    let relatedId = sessionStorage.getItem('newId');
    const id = searchParams.get('id');
    useEffect(() => {
        document.title = "finplex : Blog";

        const fetchPosts = async () => {
            await axios.get(`${baseCMSUrl}/api/technologies/${id}?populate=*`)
                .then(response => {
                    setEventsData(response?.data.data);
                })
        };
        fetchPosts();
    }, [id]);

    useEffect(() => {
        document.title = "finplex : Blog";
        const fetchPosts = async() => {
           await axios.get(`${baseCMSUrl}/api/technologies/${relatedId}?populate=*`)
           .then(response => {
            setEventsData(response?.data.data);   
              })
          
          };
        fetchPosts();
        
      }, [relatedId]);

    // const filteredTag = eventsData?.attributes?.events_tags?.data?.filter(item => item.attributes.title !== 'All Events');
    // const formattedTags = filteredTag?.map((tag, index) => {
    //     if (index < filteredTag.length - 1) {
    //         return `${tag?.attributes?.title}, `;
    //     }
    //     return tag?.attributes?.title;
    // }).join('');
    // const [tabsData, setTabsData] = useState([]);
    // useEffect(() => {
    //     const fetchPosts = async () => {

    //         const response = await axios.get(`${baseCMSUrl}/api/events-tags`);
    //         setTabsData(response.data.data);

    //     };

    //     fetchPosts();
    // }, [id]);



    return (


        <>

{/* <div className=" relative min-h-0">
        <img src="/images/EventsHeader.png" alt="" className="hidden md:block  w-screen   " style={{width:'1440' ,height: '350' }} />
            <div className="absolute top-0 right-0 bottom-0 left-0">
                <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12  hidden md:block">
                        <p className=" md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                    </div>
                </div>
            </div>
        </div>
       

        <div className=" relative min-h-0">
        <img src="/images/Events.png" alt="" className="block md:hidden  w-screen   " style={{width:'375' ,height: '350'}} />
            <div className="absolute top-0 right-0 bottom-0 left-0">
                <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6  block md:hidden">
                        <p className="text-3xl md:text-4xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                    </div>
                </div>
            </div> 
        </div> */}

        <div>
            <Storypage Link="technologys" tagsSearch='technology-tags' tags={eventsData?.attributes?.technology_tags.data} id={id} linkTo="TechnologyStory" tableName="technologies" response={openTab} videoUrl={eventsData?.attributes?.VideoId} backgroundImage={`${baseCMSUrl}` + eventsData?.attributes?.image?.data?.attributes.url} date={eventsData?.attributes?.date} view={eventsData?.attributes?.view} title={eventsData?.attributes?.title} subtitle1={eventsData?.attributes?.subtitle1} subtitle2={eventsData?.attributes?.subtitle2} desc={eventsData?.attributes?.description} description1={eventsData?.attributes?.description1} description2={eventsData?.attributes?.description2} relatedNews={eventsData?.attributes?.relatedNews}/>
        </div>
            {/* <div className="container-fluid lg:container mx-auto 2xl:px-16 my-3 ">
                <div className="mx-5 ">
                    <div className=" my-4 "><a className="text-decoration-none home-text" href="./blog">  Home </a><span className="event-text  text-[1rem] font-semibold"> / </span> <a>Events</a></div>
                    <div className="row w-full mt-3">

                        <div className=" w-full lg:mt-3">

                            <p className="text-light_gray-400 font-semibold text-center">Posts From Category: <span className="text-light_gray_950">{formattedTags}</span></p> 
                        </div>
                        <div className="mt-8 leading-[3.1rem] col-12 lg:pr-16">
                            <ReactMarkdown className="title-text" children={eventsData?.attributes?.title} />
                        
                        <div className=" w-100 ">
                            {eventsData?.attributes?.VideoId !== '' && props.videoUrl !== null && props.videoUrl !== undefined && props.videoUrl !== " " && eventsData?.attributes?.VideoId ? (
                                <ReactPlayer url={eventsData?.attributes?.VideoId} width="100%"
                                    height="100%" controls={true} className=" absolute top-0 left-0  bg-cover pr-16" />
                            ) : (
                                <img src={`${baseCMSUrl}` + eventsData?.attributes?.image?.data?.attributes?.url}   className=" min-w-full xl:h-[550px]  mt-9
                                " alt="" />

                            )}
                            <div className="prose mt-6 text-light_gray-300">
                                <ReactMarkdown className="desc-text" children={eventsData?.attributes?.description} />
                            </div>

                            <div className="prose mt-4 text-light_gray-300">
                                <ReactMarkdown className="desc-text" chilren={eventsData?.attributes?.description1} />
                            </div>
                            <div className="mt-5 text-light_gray-300">
                                <div className="prose">
                                    <ReactMarkdown className="subtitle-desc" children={eventsData?.attributes?.subtitle} />
                                </div>
                                <div className="prose">
                                    <ReactMarkdown className="desc-text mt-4" children={eventsData?.attributes?.description2} />
                                </div>
                            </div>

                            {eventsData?.attributes?.timeline?.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <div className="prose mt-8 text-light_gray-300">
                                            <ReactMarkdown className="text-2xl font-bold" children={item?.heading} />
                                        </div>
                                        <div>
                                            <div className="mt-4 text-light_gray-300">
                                                <div className="prose">
                                                    <ReactMarkdown className="text-[0.875rem] font-semibold" children={item?.date} />
                                                </div>
                                                <div className="prose">
                                                    <ReactMarkdown className="text-[0.875rem] font-normal leading-5" children={item?.description} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        </div>

                        <div className="w-full col-md-4  flex ">

                            <div className="d-flex d-flex-col">
                                <div className=" mb-5">
                                <p className="tag-text  ml-4">Tags</p>
                 
                                <ul className="custom-list gap-4   mt-5 d-flex  flex-wrap">
                    {tabsData.map((tab) => (
                      <li key={tab.attributes.title} className="whitespace-nowrap">
                        <Link to={`/Events?tags=${tab.attributes.title}`}
                          onClick={() => { setOpenTab(tab.attributes.title) }}
                          className={"text-decoration-none custom-list-li   inline-block text-base rounded-3xl bg-white py-1 border border-light_gray-400 p-3 hover:text-blue hover:border-blue"}
                        >
                          {tab.attributes.title}

                        </Link>
                      </li>
                    ))}
                  </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div> */}
            {/* <Contact /> */}
        </>
    );
}