import React , {useEffect, useState} from 'react';
import BusinessDetails from './businessDetails';
import { Stepper } from "react-form-stepper";
import LoanDetails from './loanDetails';
import PersonalDetails from './personalDetails';
import CoApplicant from './coApplicant';
import ReviewPage from './reviewPage';
import { postLoanApp, saveBusiness } from '../functions/function';
import { useHistory } from 'react-router';

function FormCreation() {

    const [businessInitial, SetbusinessInitial] = useState({});
    const [loanAmount, SetloanAmount] = useState({});
    const [personalDetails, setpersonalDetails] = useState({});
    const [coApplicantDetails, setcoApplicantDetails] = useState([]);
    const [state, setstate] = useState(0);
    const [loanId , setLoanId ] = useState();

    const [businessResponse, setbusinessResponse] = useState({});
    const [applicantResponse, setapplicantResponse] = useState({});
    const [coApplicantResponse, setcoApplicantResponse] = useState([]);

    const [loanResponse, setloanResponse] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('loanId');
        if(myParam) {
            setLoanId(myParam);
            setstate(4);
        } else {
            if(window.history.state && window.history.state.state.detail) {
                setpersonalDetails(window.history.state.state.detail);
                SetloanAmount(window.history.state.state.detail);
              } else {
                // history.push("/home");
                console.log("Error");
              }
              if(businessResponse.id !=="" && applicantResponse.id !=="") {
                saveResult();
            }
        }  
      },[businessResponse , applicantResponse , coApplicantResponse]);

   

    const handleSubmit =  async (value) => {
        if ( value.stepForm === 1 ) {
            setstate(value.stepForm);
            SetbusinessInitial(value);
        }
        if( value.stepForm === 2) {
            setstate(value.stepForm);
            SetloanAmount(value);
        }
        if(value.stepForm === 3) {
            setstate(value.stepForm);  
            setpersonalDetails(value);
        }
        if(value.stepForm === 4) {
            setcoApplicantDetails( coApplicantDetails => [...coApplicantDetails , value]);
        }
    }

    const sendBusinessPersonData = async () => {
        const businessData = {
            "id": null,
            "type": "Business",
            "borrowerPersonal": null,
            "borrowerBusiness": {
              "turnover": businessInitial.turnOver,
              "businessName": businessInitial.businessName,
              "businessVintage": businessInitial.businessVintage,
              "natureOfBuisness": businessInitial.businessNature,
              "businessType":businessInitial.businessType,
              "businessPan": businessInitial.businessPan,
              "businessAddresses": [{
                  "addressline1" : businessInitial.addressline1,
                  "cityTown" : businessInitial.cityTown,
                  "state" : businessInitial.state,
                  "pinCode" : businessInitial.pinCode,
                  "addressType" : businessInitial.businessAddressType,
                  "ownershipType": businessInitial.businessOwnershipType
              }]
            }
          };

            const businessResult = await saveBusiness(businessData);
            setbusinessResponse({
              "name" : businessResult.data.borrowerBusiness.businessName,
              "id" : businessResult.data.id,
              "mobileNumber" : businessResult.data.borrowerBusiness.mobileNumber,
            });

        
            const primaryApplicantdata = {
                "id": null,
                "type": "Personal",
                "borrowerPersonal": {
                  "firstName": personalDetails.firstname,
                  "lastName": personalDetails.lastname,
                  "dateOfBirth": personalDetails.dateOfBirth,
                  "fathersName": personalDetails.fathersName,
                  "spouseName": personalDetails.spouseName,
                  "addresses": [{
                      "addressline1" : personalDetails.address ? personalDetails.address : null,
                      "cityTown" : personalDetails.cityTown ? personalDetails.cityTown : null,
                      "state" : personalDetails.state ? personalDetails.state : null,
                      "pinCode" : personalDetails.pinCode ? personalDetails.pinCode : null,
                      "addressType" : personalDetails.addressType ? personalDetails.addressType : null,
                      "ownershipType": personalDetails.personalOwnershipType ? personalDetails.personalOwnershipType : null,
                  }],
                  "mobileNumber": personalDetails.mobileNumber,
                  "email": personalDetails.email,
                  "identities": [
                        {
                            "idType": null,
                            "idNumber": null
                        }
                    ]
                },
                "borrowerBusiness": null
              };
    
                const primaryApplicantResult = await saveBusiness(primaryApplicantdata);
                setapplicantResponse ({
                    "name" : primaryApplicantResult.data.borrowerPersonal.firstName,
                    "id" : primaryApplicantResult.data.id,
                    "mobileNumber" : primaryApplicantResult.data.borrowerPersonal.mobileNumber,
                    "primary" : true,
                });
        coApplicantDetails.map( async (item ) => {

            const coApplicantData = {
                "id": null,
                "type": "Personal",
                "borrowerPersonal": {
                    "firstName": item.firstname,
                    "lastName": item.lastname,
                    "dateOfBirth": item.dateOfBirth,
                    "fathersName": item.fathersName,
                    "spouseName": item.spouseName,
                    "addresses": [
                        {
                            "addressline1": item.address ? item.address : null,
                            "cityTown": item.cityTown ? item.cityTown : null,
                            "state": item.state ? item.state : null,
                            "pinCode": item.pinCode ? item.pinCode : null,
                            "addressType" : item.addressType ? item.addressType : null,
                            "ownershipType" : item.personalOwnershipType ? item.personalOwnershipType : null,
                        }
                    ],
                    "mobileNumber": item.mobileNumber,
                    "email": item.email,
                    "identities": [
                        {
                            "idType": null,
                            "idNumber": null
                        }
                    ]
                },
                "borrowerBusiness": null,
              };
              const coApplicantResult = await saveBusiness(coApplicantData);
              setcoApplicantResponse(coApplicantResponse => [ ...coApplicantResponse ,  {
                  "name" : coApplicantResult.data.borrowerPersonal.firstName,
                  "id" : coApplicantResult.data.id,
                  "mobileNumber" : coApplicantResult.data.borrowerPersonal.mobileNumber,
              }]);  
        });
    }
    const saveResult = async () => {
        if( businessResponse !== "" && applicantResponse !=="")  {  
            if(businessResponse.id && applicantResponse.id) {
                const newApplicationData = {
                    "loanAppId" : null,
                    "borrower": {
                       "name": businessResponse.name,
                       "id": businessResponse.id,
                       "primary": true,
                    },
                    "applicants":[
                        applicantResponse , ...coApplicantResponse
                    ],
                    "loanAmount": loanAmount.loanAmount,
                    "loanTerm": "",
                    "loanRate": "",
                    "productId": "1",
                    "processingFee": "",
                    "productCategoryId": "1",
                    "channelPartnerId": "",
                    "status": "New Opportunity",
                    "team": "",
                    "investor":"",
                    "referenceList":[],
                    };
        
                  const newApplicationResult = await postLoanApp(newApplicationData);
                  setloanResponse (newApplicationResult);
                  setstate(4);
            } 
        }
    }
    const prevButton = (value) => {
        setstate(value);
    }

    return (
        
        <div className="form-font-styles margin-top">
        <Stepper className='about'
            style={{fontFamily :'AvantGarde Md BT'}}
            steps={[
                { label: 'Business Details' },
                { label: 'Loan Amount' },
                { label: 'Personal Details' },
                { label: 'Upload Documents' }
            ]}
            activeStep={state === 3 ? 2 : state}
            />
            { state === 0 ? (
                <BusinessDetails
                stateValue = {businessInitial}
                steps={state}
                sub = {handleSubmit}/>
                ):('')}

            { state === 1 ? (
                <LoanDetails
                steps={state}
                stateValue = {loanAmount}
                preBtn = {prevButton}
                sub = {handleSubmit}/>
                ):('')}

            {state === 2 ? (
                <PersonalDetails
                steps={state}
                stateValue = {personalDetails}
                preBtn = {prevButton}
                sub = {handleSubmit}/>
            ): ('')}

            {state === 3 ?(
                <CoApplicant
                steps={setstate}
                stateValue = {coApplicantDetails}
                stateUpdate = {setcoApplicantDetails}
                preBtn = {prevButton}
                submitBtn = {sendBusinessPersonData}
                sub = {handleSubmit}/>
            ): ('')}


            {state === 4 ? (
                <ReviewPage
                businessState = {businessInitial}
                loanValue = {loanResponse}
                loanState = {loanAmount}
                loanId  = {loanId}
                personalState = {personalDetails}
                coApplicantState = {coApplicantDetails}
                preBtn = {prevButton}
                sub = {handleSubmit}
                />
            ):('')}
        </div>
    )
}

export default FormCreation;
