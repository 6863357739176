import React from "react";
import "../styles/main.scss";
import { Link } from "react-router-dom";
import Davendra from "../assets/Davendra (2).svg";
import Ashwin from "../assets/Ashwin.svg";
import RajaKishen from "../assets/RajaKishen.svg";
import Sanjay from "../assets/Sanjay_Singh.svg";
import Tarun from "../assets/Tarun_Joshi.svg";
import RBL_Bank from "../assets/RBL_Bank.svg";
import Vector from "../assets/Vector.svg";
import Group from "../assets/Group.svg";
import Maskgroup from "../assets/Maskgroup.svg";
import Group_1 from "../assets/Group_1.svg";
import Group1835 from "../assets/Group1835.svg";
import yes_bank_logo1 from "../assets/yes_bank_logo1.svg";
import About1 from "../assets/About-icon1.png";
import About2 from "../assets/About-icon2.svg";
import About3 from "../assets/About-icon3.svg";
import Aboutbanner from "../assets/About-banner.svg";
import WhoArewe from "../assets/Who_Are_we.svg";
import Taruns from "../assets/Tarun.svg";
import Sanjay_singh from "../assets/Sanjay.svg";
import Raj from "../assets/RajKish.svg";
import DavendraAhuja from "../assets/DavendraAhuja.svg";
import Ashwin1 from "../assets/Ashwin1.svg";
import Facebook from "../assets/Facebook.svg";
import Linked_in from "../assets/Linked_in.png";
import Twitter from "../assets/Twitt.svg";
import ScrollMagic from "scrollmagic";

import Scrolls from "../functions/technology";

import DigitisedLoan from "../assets/DigitisedLoan.svg";
import E_Validation from "../assets/E_Validation.svg";
import E_Verification_icon from "../assets/E_Verification_icon.svg";
import ExpandScale from "../assets/ExpandScale.svg";
import AI_ML_icon from "../assets/AI_ML_icon.svg";
import Algorithm from "../assets/Algorithm.svg";
import Mobile_img from "../assets/Mobile_img.svg";
import e_kyc_validation from "../assets/KYC_Validation_Image.svg";
import E_Verification from "../assets/E_Verification.svg";
import credit_decision_img_1 from "../assets/credit_decision_img_1.svg";
import Adv_Algorithm from "../assets/Adv_Algorithm.svg";
import SwiftAnalysis from "../assets/SwiftAnalysis.svg";
import DigitalFinalised from "../assets/DigitalFinalised.svg";
import credit_score from "../assets/credit_score.png";
import SecureEnv from "../assets/SecureEnv.png";
import SwiftAnalysis_icon from "../assets/SwiftAnalysis_icon.svg";
import E_sign from "../assets/E_sign.svg";
import DigitalOnBoard from "../assets/DigitalOnBoard.png";
import digital_loan_application from "../assets/digital_loan_application.png";
import credit_assesment_img from "../assets/credit_assesment_img.png";
import Safe_secure from "../assets/Safe_secure.png";
import AI_ML from "../assets/AI_ML.png";
import LoanDisbursed_3 from "../assets/LoanDisbursed_3.png";
import LoanDisbursal_mobile from "../assets/LoanDisbursal_mobile.svg";
import credit_decision_mobile from "../assets/credit_decision_mobile.svg";
import credit_assessment_mobile from "../assets/credit_assessment_mobile.svg";
import digitalOnboard_mobile from "../assets/digitalOnboard_mobilewithoutE.svg";
import CreditScoringLabel from "../assets/CreditScoringLabel.svg";

import HidenavBar from "../functions/hideNavBar";
export default class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }

  componentDidMount = async () => {
    /* if (flag === 1) {
      flag = flag + 1;
 */ document.title = "Finplex: About Us";
    await new ScrollMagic.Scene({
      duration: "2500vh",
      triggerElement: ".parallex-img",
      triggerHook: 0,
    })
      .setPin(".parallex-img")

      .addTo(this.controller);
    /*  console.log()
     */ //}
  };

  render() {
    return (
      <div className="about">
        <div className="container-fluid container-xxl">
          <div w3-include-html="./header.html" />
          <div className="container-fluid px-xl-5 margin-top mb-5">
            <div className="d-none d-lg-block">
              <div className="row">
                <div className="col-lg-8 px-xl-4 md-center">
                  <p className="heading md-center ms-lg-1 btn-center">
                    About Us
                  </p>
                  <p className="body-text ">
                    <br />
                    Our mission is to provide entrepreneurs swift and reliable
                    credit financing.
                  </p>
                  <p className="body-text md-center mb-5">
                    {" "}
                    At Finplex, we're determined in our aim to deliver credit
                    finance to entrepreneurs with utmost simplicity and speed.
                    Let's elevate your business aspirations and ensure your
                    success!
                  </p>
                </div>
                <div className="col-lg-4 text-center">
                  <img
                    src={Aboutbanner}
                    className="img-fluid mt-lg-5 mt-xl-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-none">
            <div className="row">
              <p className="heading text-center">About Us</p>
            </div>
            <div className="row flex-column-reverse">
              <div className="col-lg-8 px-xl-4 md-center mt-5">
                <p className="body-text ">
                  <br />
                  Our mission is to provide entrepreneurs swift and reliable
                  credit financing.
                </p>
                <p className="body-text md-center mb-5">
                  {" "}
                  At Finplex, we're determined in our aim to deliver credit
                  finance to entrepreneurs with utmost simplicity and speed.
                  Let's elevate your business aspirations and ensure your
                  success!
                </p>
              </div>
              <div className="col-lg-4 mt-4 text-center">
                <img src={Aboutbanner} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="loan-bg">
          <div className="container-fluid mb-5 container-xxl">
            <div className="px-3 d-none d-lg-block">
              <div className="row mt-5 mt-lg-3 px-xl-5">
                <p className="mt-5" />
                <p className="heading btn-center">Overview</p>
              </div>
              <div className="row mt-2 px-xl-5">
                <div className="col-lg-8 px-xl-3 md-center mt-4 mt-lg-0">
                  <p className="body-text">
                    <br /> At Finplex, we're devoted to turning your business
                    dreams into concrete success. Our impact resonates
                    nationwide, as we vitalize countless small and medium-scale
                    businesses, contributing to a thriving economy.
                  </p>
                  <p className="body-text mb-5">
                    Finplex pioneers digital lending, the premier platform for
                    businesses and financial partners. Our tech-driven process
                    ensures swift, secure loans, propelling both partners and
                    entrepreneurs towards seamless success. Embrace Finplex –
                    where dreams fuel triumph!
                  </p>
                </div>
                <div className="col-lg-4  text-center mb-5">
                  <img src={WhoArewe} className="img-fluid" />
                </div>
                <p className="mb-5" />
              </div> 
            </div>

            <div className="row mt-5 d-lg-none">
              <p className="mt-5" />
              <p className="heading text-center ms-lg-4 mt-5">Overview</p>
            </div>
            <div className="row d-lg-none">
              <div className="col-lg-4 mt-5 text-center">
                <img src={WhoArewe} className="img-fluid mt-lg-4" />
              </div>
              <div className="col-lg-8 px-xl-4 md-center mt-3 mt-lg-0">
                <p className="body-text ">
                  <br /> At Finplex, we're devoted to turning your business
                  dreams into concrete success. Our impact resonates nationwide,
                  as we vitalize countless small and medium-scale businesses,
                  contributing to a thriving economy.
                </p>
                <p className="body-text mb-5">
                  Finplex pioneers digital lending, the premier platform for
                  businesses and financial partners. Our tech-driven process
                  ensures swift, secure loans, propelling both partners and
                  entrepreneurs towards seamless success. Embrace Finplex –
                  where dreams fuel triumph!
                </p>
              </div>
              <p className="mb-5" />
            </div>
          </div>
        </div>    
        <section>
          
            <div className="container-xxl container-xl container-lg container-md">
              <div className="row d-flex justify-content-center">
                <div className="col-10 col-md-9 col-xl-7">
                                    <p className="title text-center">Our Leaders</p>
                </div>
              </div>
              <div className="row mt-3 mt-md-5 g-5 d-flex justify-content-center justify-content-md-start">
                <div className="col-sm-9 col-md-6 col-lg-4 mt-5 mt-lg-0">
                  <div
                    className="card mt-5 p-4 position-relative shadow-lg"
                    id="about-Card"
                  >
                    <p className="leaders-Name">Tarun Joshi</p>
                    <p className="leaders-pos">CEO / Co-founder</p>
                    <p className="cardText lh-sm text-center mt-3 text-height mb-0 pb-2">
                      <a
                        className="color"
                        href="http://linkedin.com/in/tarun-joshi-82481ba"
                        target="blank"
                      >
                        Tarun
                      </a>{" "}
                      is a serial entrepreneur and has over two decades of
                      experience in fund management, quantitative trading,
                      structuring, and investment banking.
                    </p>
                  </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4 mt-5 mt-lg-0">
                  <div
                    className="card mt-5 p-4 position-relative shadow-lg"
                    id="about-Card"
                  >
                    <p className="leaders-Name">Sanjay Singh </p>
                    <p className="leaders-pos">Director / Co-founder</p>
                    <p className="cardText lh-sm text-center mt-3 text-height mb-0 pb-2">
                      <a
                        className="color"
                        href="http://linkedin.com/in/sanjay-singh-88a96a14"
                        target="blank"
                      >
                        {" "}
                        Sanjay
                      </a>{" "}
                      has over two decades of experience in origination,
                      structuring, and sales of fixed income and equity products
                      to global investors.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4 mt-5 mt-lg-0">
                  <div
                    className="card mt-5 p-4 position-relative shadow-lg"
                    id="about-Card"
                  >
                    <p className="leaders-Name"> Raja Kishen</p>
                    <p className="leaders-pos">Head of Sales / Co-founder</p>
                    <p className="cardText lh-sm text-center mt-3 text-height mb-0 pb-2">
                      <a
                        className=" color"
                        href="http://linkedin.com/in/raja-kishen-rao-87788813"
                        target="blank"
                      >
                        Raja
                      </a>{" "}
                      has over 18 years of impressive track record in building
                      high-quality retail loan portfolios at leading NBFCs in
                      India.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4 mt-5 mt-lg-0">
                  <div
                    className="card mt-5 p-4 position-relative shadow-lg"
                    id="about-Card"
                  >
                    <p className="leaders-Name">Ashwin Gudidevuni</p>
                    <p className="leaders-pos">CTO</p>
                    <p className="cardText lh-sm text-center mt-3 text-height mb-0 pb-2">
                      <a
                        className=" color"
                        href="http://linkedin.com/in/ashwin-gudidevuni-1a11123"
                        target="blank"
                      >
                        {" "}
                        Ashwin
                      </a>{" "}
                      has about 20 years of experience in software design,
                      architecture, and engineering in several senior roles in
                      the US. He has an MS in Computer Science from Farleigh
                      Dickinson University, NJ, USA.{" "}
                    </p>{" "}
                  </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4 mt-5 mt-lg-0">
                  <div
                    className="card p-4 mt-5 position-relative shadow-lg"
                    id="about-Card"
                  >
                    <p className="leaders-Name"> Davender Ahuja</p>
                    <p className="leaders-pos">
                      {" "}
                      Head of Risk and Policy/Co-founder
                    </p>
                    <p className="cardText lh-sm text-center mt-3 text-height mb-0 pb-2">
                      <a
                        className=" color"
                        href="http://linkedin.com/in/davender-ahuja-b0237618"
                        target="blank"
                      >
                        Davender
                      </a>{" "}
                      has 17 years of experience in underwriting, credit risk
                      management, and policies at leading NBFCs in India. He has
                      a Master of Business Economics degree from the Faculty of
                      Management Studies.{" "}
                    </p>
                  </div>
                </div>
                <p className="mb-5"></p>
              </div>
            </div>
                  </section>
        {/* <div className="container-fluid " style={{ marginTop: '80px' }}>
            <div className="d-xl-none">
              <p className="heading px-md-3 text-center">Our Leaders</p>
              <div className="row mt-5">
                <div className="col-12 text-center">
                  <img src={Tarun} alt="Tarun" className="leader-img " />
                  <p className="leaders-Name name-box">Tarun Joshi <br /> <span className="leaders-pos">CEO /
                    Co-founder</span></p>
                </div>
                <div className="col-12">
                  <p className="leader-text px-2 px-md-5">Tarun is a serial entrepreneur and has over two decades of
                    experience in fund management, quantitative trading, structuring, and investment banking.
                    Prior
                    to Finplex, he was the Founder / CIO of Octave Investment Management LLP, a
                    hedge-fund manager in London that managed several funds investing in quantitative strategies
                    in
                    equity and FX markets (2009-2018). Before that, he was a Director at Deutsche Bank AG,
                    London
                    within quantitative products structuring
                    (2004-2009). Prior to Deutsche Bank, he ran quantitative research at Irevna (a Standard &amp;
                    Poor’s
                    company), and before that he worked at ICICI Bank in the Treasury. He also has an MBA from
                    IIM
                    Bangalore (2001).</p>
                </div>
              </div>
              <div className="row px-xl-4 mt-5  Leaders-box1  ">
                <div className="col-12 text-center"><img src={Sanjay} className="img-fluid leader-img" />
                </div>
                <div className="col-12 ">
                  <p className="leaders-Name name-box px-5">Sanjay Singh <br />
                    <span className="leaders-pos">
                      Director / Co-founder
                    </span>
                  </p>
                  <p className="leader-text px-2 px-md-5">Sanjay has over two decades of experience in origination,
                    structuring, and sales of fixed income and equity products to global investors. He is the
                    founder of Polestar Capital, a boutique investment bank, which has raised $800 m of
                    financing for its clients. Prior to that, he was Managing Director and Head of Originations
                    &amp;
                    Distribution (Fixed Income) at Credit Suisse in India. Before that, he has occupied many
                    senior
                    roles at Standard Chartered, GE Capital,
                    and ICICI Bank. He has a rank-holding C.A., C.W.A., and C.S.
                  </p>
                </div>
              </div>
              <div className="row px-xl-4 mt-5 Leaders-box2  ">
                <div className="col-12 text-center"><img src={RajaKishen} className="img-fluid leader-img" />
                </div>
                <div className="col-12 ">
                  <p className="leaders-Name name-box  ">Raja Kishen <br />
                    <span className="leaders-pos">
                      CFO
                    </span>
                  </p>
                  <p className="leader-text px-2 px-md-5">Raja has over 18 years of impressive track record in
                    building
                    high-quality retail loan portfolios at leading NBFCs in India. Prior to joining Finplex, he
                    was
                    the zonal head (south) at Neogrowth Credit Private Limited (2015-2018) where
                    he played a key role in growing monthly originations by 6x in two-and-a-half years. Before
                    Neogrowth, He held several senior positions at Fullerton India Private Limited in sales and
                    underwriting between 2005-2015. Most recently
                    he was the state head for AP and Telangana where he built one of the best performing retail
                    loan
                    books at Fullerton in India. Before Fullerton, He worked at MetLife Insurance in sales
                    (2003-2005).</p>
                </div>
              </div>
              <div className="row px-xl-4 mt-5 Leaders-box2  ">
                <div className="col-12 text-center"><img src={RajaKishen} className="img-fluid leader-img" />
                </div>
                <div className="col-12 ">
                  <p className="leaders-Name name-box  ">Raja Kishen <br />
                    <span className="leaders-pos">
                      Head of Sales / Co-founder
                    </span>
                  </p>
                  <p className="leader-text px-2 px-md-5">Raja has over 18 years of impressive track record in
                    building
                    high-quality retail loan portfolios at leading NBFCs in India. Prior to joining Finplex, he
                    was
                    the zonal head (south) at Neogrowth Credit Private Limited (2015-2018) where
                    he played a key role in growing monthly originations by 6x in two-and-a-half years. Before
                    Neogrowth, He held several senior positions at Fullerton India Private Limited in sales and
                    underwriting between 2005-2015. Most recently
                    he was the state head for AP and Telangana where he built one of the best performing retail
                    loan
                    books at Fullerton in India. Before Fullerton, He worked at MetLife Insurance in sales
                    (2003-2005).</p>
                </div>
              </div>
              <div className="row px-xl-4 mt-5 Leaders-box3  ">
                <div className="col-12 text-center"><img src={Ashwin} className="img-fluid  leader-img" alt='Ashwin' />
                </div>
                <div className="col-12 ">
                  <p className="leaders-Name name-box ">Ashwin Gudidevuni<br />
                    <span className="leaders-pos">CTO</span>
                  </p>
                  <p className="leader-text px-2 px-md-5">Ashwin has about 20 years of experience in software design,
                    architecture, and engineering in several senior roles in the US. Before Finplex, he was
                    Cloud
                    Native Application Architect at Cognizant Technology Solutions in the US (2018-2021)
                    where he was responsible for the design, architecture, and development of cloud technology
                    solutions for some of the largest and most complex companies in the world. Prior to that, He
                    was
                    a Solutions Architect at Sogeti USA LLC
                    (2005-2018) where he designed, implemented, and managed several solutions for Hewlett
                    Packard.
                    Before that, he worked as Software Engineer at Software Labs in the US (2002-2005). He has
                    an MS
                    in Computer Science from Farleigh Dickinson
                    University, NJ, USA.</p>
                </div>
              </div>
              <div className="row px-xl-4 mt-5 Leaders-box4  ">
                <div className="col-12 text-center">
                  <img src={Davendra} className="img-fluid    leader-img" alt='Davandra' />
                </div>
                <div className="col-12">
                  <p className="leaders-Name name-box ">Davender Ahuja<br />
                    <span className="leaders-pos"> Head of Risk and Policy / Co-founder</span>
                  </p>
                  <p className="leader-text px-2 px-md-5">Davender has 17 years of experience in underwriting, credit
                    risk
                    management, and policies at leading NBFCs in India. Prior to Finplex, he worked in several
                    senior roles at Capital Float (2015-2018) including zonal credit manager for
                    north, east, and west India as well as national head for supply chain finance product. As a
                    senior member of the credit risk team, Davender played an important role in growing the
                    business
                    at capital float by 7-8x over his three-year
                    stint. Before that, he has worked at Fullerton India, Tata Capital, Bajaj Finance,
                    Cholamandalam
                    DBS, and Busan Auto Finance in several credit risk and underwriting roles for both unsecured
                    and
                    secured small business and consumer
                    loans since 2004. He has a Master of Business Economics degree from the Faculty of
                    Management
                    Studies.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container px-lg-5 px-xl-1" style={{ marginTop: '80px' }}>
            <div className="d-none d-xl-block ">
              <p className="heading ">Our Leaders</p>
              <div className="row mt-5  Leaders-box justify-content-center p-4">
                <div className="col-2">
                  <img src={Tarun} className="leader-img " />
                  <p className="leaders-Name name-box ">Tarun Joshi <br /> <span className="leaders-pos">CEO /
                    Co-founder</span></p>
                </div>
                <div className="col-12 col-lg-9  d-flex align-items-center ms-lg-5">
                  <p className="leader-text ">Tarun is a serial entrepreneur and has over two decades of experience in
                    fund
                    management, quantitative trading, structuring, and investment banking. Prior to Finplex, he
                    was
                    the Founder / CIO of Octave Investment Management LLP, a
                    hedge-fund manager in London that managed several funds investing in quantitative strategies
                    in
                    equity and FX markets (2009-2018). Before that, he was a Director at Deutsche Bank AG,
                    London
                    within quantitative products structuring
                    (2004-2009). Prior to Deutsche Bank, he ran quantitative research at Irevna (a Standard &amp;
                    Poor’s
                    company), and before that he worked at ICICI Bank in the Treasury. He also has an MBA from
                    IIM
                    Bangalore (2001).</p>
                </div>
              </div>
              <div className="row mt-5  Leaders-box justify-content-center p-4">
                <div className="col-2 ">
                  <img src={Sanjay} className="leader-img " />
                  <p className="leaders-Name name-box  ">Sanjay Singh<br />
                    <span className="leaders-pos"> Director / Co-founder</span>
                  </p>
                </div>
                <div className="col-9 d-flex align-items-center  ms-lg-5">
                  <p className="leader-text ">Sanjay has over two decades of experience in origination, structuring,
                    and
                    sales of fixed income and equity products to global investors. He is the founder of Polestar
                    Capital, a boutique investment bank, which has raised $800 m of
                    financing for its clients. Prior to that, he was Managing Director and Head of Originations
                    &amp;
                    Distribution (Fixed Income) at Credit Suisse in India. Before that, he has occupied many
                    senior
                    roles at Standard Chartered, GE Capital,
                    and ICICI Bank. He has a rank-holding C.A., C.W.A., and C.S.
                  </p>
                </div>
              </div>
              <div className="row mt-5  Leaders-box justify-content-center p-4">
                <div className="col-2">
                  <img src={RajaKishen} className="leader-img " />
                  <p className="leaders-Name name-box ">Raja Kishen <br />
                    <span className="leaders-pos">
                      CFO
                    </span>
                  </p>
                </div>
                <div className="col-9  d-flex align-items-center  ms-lg-5">
                  <p className="leader-text">Raja has over 18 years of impressive track record in building
                    high-quality
                    retail loan portfolios at leading NBFCs in India. Prior to joining Finplex, he was the zonal
                    head (south) at Neogrowth Credit Private Limited (2015-2018) where
                    he played a key role in growing monthly originations by 6x in two-and-a-half years. Before
                    Neogrowth, He held several senior positions at Fullerton India Private Limited in sales and
                    underwriting between 2005-2015. Most recently
                    he was the state head for AP and Telangana where he built one of the best performing retail
                    loan
                    books at Fullerton in India. Before Fullerton, He worked at MetLife Insurance in sales
                    (2003-2005).</p>
                </div>
              </div>
              <div className="row mt-5  Leaders-box justify-content-center p-4">
                <div className="col-2">
                  <img src={RajaKishen} className="leader-img " />
                  <p className="leaders-Name name-box ">Raja Kishen <br />
                    <span className="leaders-pos">
                      Head of Sales / Co-founder
                    </span>
                  </p>
                </div>
                <div className="col-9  d-flex align-items-center  ms-lg-5">
                  <p className="leader-text">Raja has over 18 years of impressive track record in building
                    high-quality
                    retail loan portfolios at leading NBFCs in India. Prior to joining Finplex, he was the zonal
                    head (south) at Neogrowth Credit Private Limited (2015-2018) where
                    he played a key role in growing monthly originations by 6x in two-and-a-half years. Before
                    Neogrowth, He held several senior positions at Fullerton India Private Limited in sales and
                    underwriting between 2005-2015. Most recently
                    he was the state head for AP and Telangana where he built one of the best performing retail
                    loan
                    books at Fullerton in India. Before Fullerton, He worked at MetLife Insurance in sales
                    (2003-2005).</p>
                </div>
              </div>
              <div className="row mt-5  Leaders-box justify-content-center p-4">
                <div className="col-2">
                  <img src={Ashwin} className="leader-img " />
                  <p className="leaders-Name name-box ">Ashwin Gudidevuni<br />
                    <span className="leaders-pos">CTO</span>
                  </p>
                </div>
                <div className="col-9  d-flex align-items-center  ms-lg-5">
                  <p className="leader-text">Ashwin has about 20 years of experience in
                    software design, architecture, and engineering in several senior roles in the US. Before
                    Finplex, he was Cloud Native Application Architect at Cognizant Technology Solutions in the
                    US
                    (2018-2021)
                    where he was responsible for the design, architecture, and development of cloud technology
                    solutions for some of the largest and most complex companies in the world. Prior to that, He
                    was
                    a Solutions Architect at Sogeti USA LLC
                    (2005-2018) where he designed, implemented, and managed several solutions for Hewlett
                    Packard.
                    Before that, he worked as Software Engineer at Software Labs in the US (2002-2005). He has
                    an MS
                    in Computer Science from Farleigh Dickinson
                    University, NJ, USA.</p>
                </div>
              </div>
              <div className="row mt-5  Leaders-box justify-content-center p-4">
                <div className="col-2">
                  <img src={Davendra} alt='leader-img' className="leader-img " />
                  <p className="leaders-Name name-box  ">Davender Ahuja<br />
                    <span className="leaders-pos"> Head of Risk and Policy / Co-founder</span>
                  </p>
                </div>
                <div className="col-9  d-flex align-items-center  ms-lg-5">
                  <p className="leader-text">Davender has 17 years of experience in
                    underwriting, credit risk management, and policies at leading NBFCs in India. Prior to
                    Finplex,
                    he worked in several senior roles at Capital Float (2015-2018) including zonal credit
                    manager
                    for
                    north, east, and west India as well as national head for supply chain finance product. As a
                    senior member of the credit risk team, Davender played an important role in growing the
                    business
                    at capital float by 7-8x over his three-year
                    stint. Before that, he has worked at Fullerton India, Tata Capital, Bajaj Finance,
                    Cholamandalam
                    DBS, and Busan Auto Finance in several credit risk and underwriting roles for both unsecured
                    and
                    secured small business and consumer
                    loans since 2004. He has a Master of Business Economics degree from the Faculty of
                    Management
                    Studies.</p>
                </div>
              </div>
            </div>
          </div>
 */}{" "}
        <div className="container">
          <div className="d-none d-lg-block">
            <div className="section">
              <div className="row mt-4">
                <div
                  className="section-left col-lg-5"
                  style={{ marginBottom: "50px" }}
                >
                  <div id="scrollOne">
                    <div className="section_heading_img"></div>
                    <div className="section_heading">
                      <p>Digital Onboarding</p>
                    </div>
                    <div className="col">
                      <div className="col-section">
                        <img src={DigitisedLoan} className="mt-4" />
                        <p className="section_heading_1">
                          Digitised Loan Application
                        </p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            {" "}
                            A single-digitised loan application takes care of
                            onboarding to disbursal documentation which is
                            required for a smooth transition from an onlooker to
                            a client.
                          </p>
                        </div>
                        <img src={E_Validation} className="mt-4" />
                        <p className="section_heading_1">KYC Validation</p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            The uploading of Know Your Customer (KYC) documents
                            in Digital format validates the identity of the
                            client borrower in an incredibly simple and smart
                            manner.
                          </p>
                        </div>
                        <img src={E_Verification_icon} className="mt-4" />
                        <p className="section_heading_1">E-Verification</p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            KYC comprises of online photo and video check, bank
                            account number, aadhar and Pancard verification.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div id="scrollTwo">
                    <div className="section_heading_group">
                      <div className="section_heading">
                        <p>Credit Assessment With Credit Scoring</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col-section">
                        <img src={credit_score} className="mt-4" />
                        <p className="section_heading_1">Credit Scoring</p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            Credit scoring is achieved by multiple data analyses
                            from multiple financial sources and digital
                            footprints to give a complete 360° analysis report
                            on the profile of the borrower and the
                            creditworthiness of the business.{" "}
                          </p>
                        </div>
                        <img src={SecureEnv} className="mt-4" />
                        <p className="section_heading_1">Secure Environment</p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            Offers a compliant and secure environment for the
                            clients.
                          </p>
                        </div>
                        <img src={ExpandScale} className="mt-4" />
                        <p className="section_heading_1">Expands The Scale</p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            Expands the scale of lending with minimal risk to
                            Financial Institutions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div id="scrollThree">
                    <div className="section_heading_group">
                      <div className="section_heading">
                        <p>Credit Decisioning</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col-section">
                        <img src={AI_ML_icon} className="mt-4" />
                        <p className="section_heading_1">AI/ML Proficiency </p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            AI/ML proficiency enhances the credit quality of the
                            Financial institution and acceptance rates of the
                            entrepreneurs seeking loans.
                          </p>
                        </div>
                        <img src={Algorithm} className="mt-4" />
                        <p className="section_heading_1">Advanced Algorithm</p>
                        <div className="content-group">
                          <p className="content-text text-color">
                            It brings loans to customers almost instantly while
                            Financial institutions are saved high credit risks
                            because of accuracy in customer profiling brought by
                            the advanced algorithm-based credit decisioning.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="section_heading_group">
                    <div className="section_heading">
                      <p>Loan Disbursal</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-section">
                      <img src={SwiftAnalysis_icon} className="mt-4" />
                      <p className="section_heading_1">Swift Analysis </p>
                      <div className="content-group">
                        <p className="content-text text-color">
                          The efficiency of loan disbursal is marked by
                          efficiency in swift analysis and deployments of
                          credit.
                        </p>
                      </div>
                      <img src={E_sign} className="mt-4" />
                      <p className="section_heading_1">E-Sign </p>
                      <div className="content-group">
                        <p className="content-text text-color">
                          Loan agreements can be signed by finalised with
                          E-signatures
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-xl-2" />
                <div
                  className="section-right col-lg-5"
                  style={{ justifyContent: "center", marginTop: "50px" }}
                >
                  <div className>
                    <div className="col parallex-img paddingTop">
                      <div className="section-image">
                        <img
                          src={Mobile_img}
                          className="img-fluid "
                          style={{
                            width: "280px",
                            height: "540px",
                            position: "relative",
                          }}
                        />
                      </div>
                      <div id="displayTwo">
                        <img
                          src={DigitalOnBoard}
                          className="digital-img animate__animated animate__fadeInRight"
                        />
                        <img
                          src={digital_loan_application}
                          className="left-ani-1 animate__animated animate__fadeInUp animate__delay-1s"
                        />
                        <img
                          src={e_kyc_validation}
                          className="right-ani-1 animate__animated animate__fadeInUp animate__delay-2s"
                        />
                        <img
                          src={E_Verification}
                          className="right-ani-2 animate__animated animate__fadeInUp animate__delay-3s"
                        />
                      </div>
                      <div id="displayOne" style={{ display: "none" }}>
                        <img
                          src={credit_assesment_img}
                          className="digital-img animate__animated animate__fadeInRight"
                        />
                        <img
                          src={CreditScoringLabel}
                          className="ass-right-ani-1 animate__animated animate__fadeInUp animate__delay-1s"
                        />
                        <img
                          src={Safe_secure}
                          className="ass-left-ani-2 animate__animated animate__fadeInUp animate__delay-2s"
                        />
                      </div>
                      <div id="displayThree" style={{ display: "none" }}>
                        <img
                          src={credit_decision_img_1}
                          className="digital-img animate__animated animate__fadeInRight"
                        />
                        <img
                          src={AI_ML}
                          className="dec-left-ani animate__animated animate__fadeInUp animate__delay-1s"
                        />
                        <img
                          src={Adv_Algorithm}
                          className="dec-right-ani animate__animated animate__fadeInUp animate__delay-2s"
                        />
                      </div>
                      <div id="displayFour" style={{ display: "none" }}>
                        <img
                          src={LoanDisbursed_3}
                          className="Loan-img digital-img animate__animated animate__fadeInRight"
                        />
                        <img
                          src={SwiftAnalysis}
                          className="loan-left-ani2 animate__animated animate__fadeInUp animate__delay-1s"
                        />
                        <img
                          src={DigitalFinalised}
                          className="loan-right-ani animate__animated animate__fadeInUp animate__delay-2s"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  <splitScroll/>
           */}{" "}
          <div className="d-lg-none mt-5">
            <div className="section px-3 ">
              <div className="row">
                <div className="section_heading mt-1">
                  <p>Digital Onboarding</p>
                </div>
                <div
                  className="section-right text-center"
                  style={{ justifyContent: "center" }}
                >
                  <img src={digitalOnboard_mobile} className="mt-4 img-fluid" />
                </div>
                <div className="col">
                  <div className="col-section">
                    <img src={DigitisedLoan} className="mt-5" />
                    <p className="section_heading_1">
                      Digitised Loan Application
                    </p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        {" "}
                        A single-digitised loan application takes care of
                        onboarding to disbursal documentation which is required
                        for a smooth transition from an onlooker to a client.
                      </p>
                    </div>
                    <img src={E_Validation} className="mt-4" />
                    <p className="section_heading_1">KYC Validation</p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        The uploading of Know Your Customer (KYC) documents in
                        Digital format validates the identity of the client
                        borrower in an incredibly simple and smart manner.
                      </p>
                    </div>
                    {/*   <img src="./assets/credits 1.svg" class="mt-4" />
                            <p class="section_heading_1">Neo-Credits</p>

                            <div class="content-group">
                                <p class="content-text text-color">Opens up opportunities for neo-credits for the
                                    conventionally unbanked sector of the economy too.

                                </p>
                            </div>
                           */}{" "}
                    <img src={E_Verification_icon} className="mt-4" />
                    <p className="section_heading_1">E-Verification</p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        KYC comprises of online photo and video check, bank
                        account number, aadhar and Pancard verification.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="section_heading">
                  <p>Credit Assessment With Credit Scoring</p>
                </div>
                <div
                  className="section-right  text-center"
                  style={{ justifyContent: "center" }}
                >
                  <img
                    src={credit_assessment_mobile}
                    className="mt-4 img-fluid"
                  />
                </div>
                <div className="col mt-4">
                  <div className="col-section">
                    <img src={credit_score} className="mt-4" />
                    <p className="section_heading_1">Credit Scoring</p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        Credit scoring is achieved by multiple data analyses
                        from multiple financial sources and digital footprints
                        to give a complete 360° analysis report on the profile
                        of the borrower and the creditworthiness of the
                        business.{" "}
                      </p>
                    </div>
                    <img src={SecureEnv} className="mt-4" />
                    <p className="section_heading_1">Secure Environment</p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        Offers a compliant and secure environment for the
                        clients.
                      </p>
                    </div>
                    <img src={ExpandScale} className="mt-4" />
                    <p className="section_heading_1">Expands The Scale</p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        Expands the scale of lending with minimal risk to
                        Financial Institutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="section_heading">
                  <p>Credit Decisioning</p>
                </div>
                <div
                  className="section-right text-center"
                  style={{ justifyContent: "center" }}
                >
                  <img
                    src={credit_decision_mobile}
                    className="mt-4 img-fluid"
                  />
                </div>
                <div className="col mt-4">
                  <div className="col-section">
                    <img src={AI_ML_icon} className="mt-4" />
                    <p className="section_heading_1">AI/ML Proficiency </p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        AI/ML proficiency enhances the credit quality of the
                        Financial institution and acceptance rates of the
                        entrepreneurs seeking loans.
                      </p>
                    </div>
                    <img src={Algorithm} className="mt-4" />
                    <p className="section_heading_1">Advanced Algorithm</p>
                    <div className="content-group">
                      <p className="content-text  text-color">
                        It brings loans to customers almost instantly while
                        Financial institutions are saved high credit risks
                        because of accuracy in customer profiling brought by the
                        advanced algorithm-based credit decisioning.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="section_heading">
                  <p>Loan Disbursal</p>
                </div>
                <div
                  className="section-right text-center"
                  style={{ justifyContent: "center" }}
                >
                  <img src={LoanDisbursal_mobile} className="mt-4 img-fluid" />
                </div>
                <div className="col mt-4">
                  <div className="col-section">
                    {/*   <img src="./assets/AI_ML (1).svg" class="mt-4" />
                            <p class="section_heading_1">Quick Process</p>

                            <div class="content-group">
                                <p class="content-text text-color">The Turn around time (TAT) to a loan query is
                                    within
                                    24
                                    hours
                                </p>
                            </div>
                           */}{" "}
                    <img src={SwiftAnalysis_icon} className="mt-4" />
                    <p className="section_heading_1">Swift Analysis </p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        The efficiency of loan disbursal is marked by efficiency
                        in swift analysis and deployments of credit.
                      </p>
                    </div>
                    <img src={E_sign} className="mt-4" />
                    <p className="section_heading_1">E-Sign</p>
                    <div className="content-group">
                      <p className="content-text text-color">
                        Loan agreements can be signed by finalised with
                        E-signatures
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Scrolls />
        <HidenavBar />
      </div>
    );
  }
}
