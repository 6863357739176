import { React, useState, useEffect } from "react";
import axios from "axios";
import Card from "./card";
import { baseCMSUrl } from "./constant";
import { Link } from "react-router-dom";
import qs from "qs";
import ReactPaginate from 'react-paginate';



export default function MarketNews(props) {
  
    const [marketNewsData, setMarketNewsData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState([]);

    const item = 9; 
    const pageCount = Math.ceil(total / item);
  
    useEffect(() => {
        console.log(props.filter, "filter")

        const fetchPosts = async (pageNumber, pageSize) => {
            const query = qs.stringify(
                {
                    pagination: {
                        page: currentPage,
                        pageSize: 9
                    },
                    filters: {
                        banking_tags: {
                            title: {
                                $contains: props.filter,
                            },
                        },
                    },

                    populate: '*',
                },
                {
                    encodeValuesOnly: true
                }
            );
            setLoading(true);
            const response = await axios.get(`${baseCMSUrl}/api/bankings?${query}`);
            setMarketNewsData(response.data.data.sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt)));
            setLoading(false);
            setTotal(response.data.meta.pagination.total);
        };
        fetchPosts();
    }, [props]);
    const handlePageChange = (page, e) => {
        //  e.preventDefault();
        setCurrentPage(page.selected + 1);
        // handlePageChange.current.scrollIntoView();
        window.scrollTo(0, 500);
        // window.scrollTo({top: 0}, [handlePageChange]);
    };
     return (
        <>
            <div className="container-xxl m-auto">
                <div className="row px-md-4 mx-md-0 ">

                    {marketNewsData?.slice(0, props.noOfTiles).map((blog, index) => {
                        return (
                            <div className="col-xl-4 col-md-6 p-3 ">
                                <Link className="text-decoration-none" key={blog.id} to={`/BankingStory?id=${blog.id}`}>

                                    <Card date={blog?.attributes?.date} view={blog?.attributes?.view} title={blog?.attributes?.title1} videoUrl={blog?.attributes?.VideoId} desc={blog?.attributes?.description1} image={`${baseCMSUrl}` + blog?.attributes?.image?.data?.attributes?.url} />

                                </Link>
                            </div>
                        )
                    }
                    )}
                </div>
                {total > 9 ? <ReactPaginate

                    previousLabel={currentPage === 1 ? "" : "<"}
                    nextLabel={currentPage === pageCount ? "" : ">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={" custom-container mr-5 pr-4"}
                    pageClassName={"custom-button mr-1"}
                    pageLinkClassName={"page-link"}
                    previousClassName={currentPage === 1 ? "disabled" : "page-item custom-element"}
                    previousLinkClassName={"page-link"}
                    nextClassName={currentPage === pageCount ? "disabled" : "page-item custom-element"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active-element"}
                    /> : ""}   
            </div>
           

        </>
    )
}