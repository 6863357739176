import React from "react";
import businessloaneligibility from "../assets/business_loan_eligibility.svg";

export default class loaneligibility extends React.Component {
  render() {
    return (
      <>
        <div className="container-fluid container-xxl">
          <div className="row px-3 px-xl-5">
            <p className="sub-title center-sm  mt-5 mt-lg-5">
              Loan Eligibility
            </p>
            <div className="col-sm-12 col-lg-4 text-center   ">
              <img
                src={businessloaneligibility}
                alt=""
                className="img-fluid mt-5 "
              />
            </div>
            <div className="col-sm-12 col-lg-8 mt-4">
                        <ul  className='custom-list'>
                            <li>                               
                               <p className="content-text"> KYC documents - address proof and identity proof of all
                                applicants</p></li>
                               <li><p className="content-text">  The business should have existed for more than 2 years
                            </p></li>
                            <li><p className="content-text">The main applicant, Director / Promoter / Partner / Proprietor should be above 25 years of age</p></li>
                            <li><p className="content-text">Business turnover must be at least Rs. 40 lakhs per year</p></li>
                            <li><p className="content-text">The applicants must own at least one property asset, either residential or business</p></li>                           
                        </ul>
                    </div>
                 <p className="mb-5" />
          </div>
        </div>
      </>
    );
  }
}
