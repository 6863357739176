import React from "react";
import Hidenavbar from "../functions/hideNavBar";
import {Link} from "react-router-dom";

import Logo from "../assets/LOGO.svg";

export default class CodeOfConduct extends React.Component
{
  componentDidMount(){
    document.title = "Finplex: Code Of Conduct"
  }

    render()
{
    return(

        <div className="content-text  about">
        <div className="container px-3 px-lg-5 mt-5 ">
        
        <div className="container margin-top">
        <div className="row justify-content-center">
          <p className="heading text-center"> Code Of Conduct</p>
          <div className="col-12">
            <h5 className="sub-heading">Introduction And Application</h5>
            <ol>
              <li className="mt-5"><strong>Purpose</strong><br />
                <img className="p-5" src={Logo} alt="Finplex Association for Consumer Empowerment" />India is fast moving towards becoming a digital economy, with technology being its main accelerating factor helping in speeding up and growth of industries and market. However, this speed can also result in growth of certain unwanted dodgy practices. With recent strong growth in the digital lending industry in India, there is a need for industry participants to maintain an ethical code of conduct in order to prevent the rise of unwanted practices that could cause harm to the industry by reducing the confidence of customers, regulators and other market participants.Finplex being an organization striving for betterment of Fintech Industry has decided along with its members to adopt and implement this Ethical Code of Conduct. This code will create common safeguards regarding customer interest and provide a proper solution to the risk created hence bringing in a transparent and fair business practices.</li>
              <li className="mt-5"><strong>Applicatbility</strong>
                <ol className="">
                  <li>The Code of Conduct is a set of principles, and guidelines that are binding on every member&nbsp;<strong>(“Member”)</strong>&nbsp;in order to make sure there is a uniform, ethical behavior displayed by the members.</li>
                  <li>This Code of Conduct is applicable to Members in all of their dealings, relations, and transactions with any individual, person or business&nbsp;<strong>(“customer”)</strong>&nbsp;to whom any financial product or service is offered or provided through the use of any online technology.</li>
                  <li>This Code of Conduct is in addition to all laws and regulations applicable for lending businesses, including all current regulations and directions issued by the Reserve Bank of India, and by no means aims to supersede any applicable laws or regulatory guidance. In case of any conflict or discrepancy between this Code of Conduct and any law or regulation, that law or regulation will supersede.</li>
                  <li>The Code of Conduct is subject to change by Finplex from time to time.</li>
                  <li>Members will have to comply with this Code of Conduct and any non-compliance may result in Sanctions as detailed below or as decided by the governing council of Finplex.</li>
                </ol>
              </li>
              <li className="mt-5"><strong>Adherence &amp; Supervision</strong>
                <ol>
                  <li>Members agree, in writing, at the time of membership and renewal of membership, or during the term of membership to adhere to this Code of Conduct.</li>
                  <li>Members will have to provide, in writing, their acceptance of this Code of Conduct for continuation of their membership.</li>
                  <li>Each Member, as part of its acceptance of the Code of Conduct, accepts and consents to Finplex right to apply and give effect to the Sanctions as set out below.</li>
                  <li>Each Member shall provide to Finplex the name of a person in its organization who will be Single Point of Contact (SPOC) regarding this Code of Conduct&nbsp;<strong>(“Designated Officer”)</strong>. The Designated Officer will also be responsible for dissemination of this Code of Conduct (as updated from time to time) within the Member’s organisation at frequent intervals. The name of the Designated Officer will be kept on record of Finplex and will be the contact point for all communications with the Member in relation to the Code of Conduct.</li>
                  <li>Each Member must submit an annual confirmation to the Management Committee, in writing, on its compliance with the Code of Conduct, in such form as the Finplex may require from time to time&nbsp;<strong>(“Yearly Submission”)</strong>. The Yearly Submission will include a certification by a director, company secretary or other key managerial personnel that it is in compliance with this Code of Conduct. The Yearly Submission will be required from each Member in order to continue its membership and participation in Finplex activities.</li>
                  <li>Members can conduct regular training sessions for staff, agents and representatives on this Code of Conduct and update them regarding the effects of non-compliance.</li>
                </ol>
              </li>
              <li className="mt-5"><strong>Promotion Of The Code Of Conduct</strong>
                <ol>
                  <li>Members should spread awareness about this Code of Conduct.</li>
                  <li>Members should display the Code of Conduct as part of their fair practices code (similar to Banks and NBFCs) and should make it available to customers on the Members Website or App.</li>
                </ol>
              </li>
            </ol>
            <div className="gap-5" />
            <h5 className="sub-heading">TENETS OF THE CODE OF CONDUCT</h5>
            <ol>
              <li><strong>Obligation To Adhere To All Applicable Laws And Regulations</strong>
                <ol>
                  <li>Each Member undertakes to comply with all provisions of all applicable laws and regulations, including, but not limited to:
                    <ol>
                      <li>Applicable laws and regulations concerning financial services, consumer protection and any general laws including without limitation all directions, guidelines, circulars and notifications issued by the Reserve Bank of India and other relevant statutory, regulatory or government bodies;</li>
                      <li>Applicable laws and regulations in the field of communication and informatics related to the protection of personal data in electronic systems;</li>
                      <li>Any other applicable law and regulations relating to business, operations and practices of members.</li>
                    </ol>
                  </li>
                  <li>All provisions in this Code of Conduct complement and are in addition to the obligations of each Member under laws and regulations applicable to the Member. Each Member is individually and solely responsible for its compliance with applicable laws, regulations and this Code of Conduct.</li>
                </ol>
              </li>
              <li><strong>Basic Principles</strong>
                <ol>
                  <li><strong><u>Transparency with regards to Product, Pricing and Services</u></strong>
                    <ol>
                      <li>All the members must at all times offer products and services that are clear and unambiguous.</li>
                      <li>Members should ensure that all their marketing and advertising material and outreach to customers is not false, ambiguous or deceptive.</li>
                      <li>All the members must make every effort to provide relevant information in a format and language easily understandable to customers.</li>
                    </ol>
                  </li>
                  <li><strong><u>Fair treatment of Customers in Servicing and Collection</u></strong><br /><br/>
                    Each Member must:<p />
                    <ol>
                      <li>have board approved fair practices code and ensure that the code is complied with;</li>
                      <li>adopt ethical practices across its product and operations that treat their customers with dignity and respect and not resort to any harassment or intimidation;</li>
                      <li>ensure that employees, staff, representatives and agents are adequately and regularly trained to deal with the customers in an appropriate manner to give effect to the above.</li>
                      <li>ensure that employees, staff, representatives and agents treat all the customer fairly and according to guidelines published by the regulators.</li>
                    </ol>
                  </li>
                  <li><strong><u>Lending within appropriate affordability limits</u></strong><br /><br/>
                    It would be members responsibility to make fair income and affordability assessments of customer and ensure that financial product and services, including the loan and all charges and fees, are not in excess of a customer’s capacity to pay.</li>
                </ol>
              </li>
              <li className="mt-5"><strong>Specific Guidance</strong>
                <ol>
                  <li ><strong><u>Product Transparency</u></strong>
                    <ol>
                      <li><strong>Disclosure Of Terms, Conditions And Obligations To Customer</strong>
                        <ol>
                          <li>The Member should strive to make sure that detailed terms and conditions of the financial product and services offered are made available to the customer.</li>
                          <li>A sanction letter is issued to the customer by Lender before execution of the Loan Agreement.</li>
                          <li>A loan agreement containing detailed outlines of the obligations and commitments of the customer and commitments of the lender in the relationship should be provided to the customer.</li>
                          <li>The Customer must be clearly communicated that a loan is being granted which is to be repaid in a certain time frame, failing to do so will have consequences in terms of Credit Bureau reporting and potential legal action in the event of default.</li>
                          <li>Efforts should be taken to make customers understand the ultimate lender and who would be the Technology Partner. In the event the Member is not the lender, the customer should understand the role and responsibility of the Member in the process and transaction relating to the financial product and services.</li>
                          <li>The customer must be clearly communicated with the Grievance Redressal Mechanism including forums he can approach.</li>
                        </ol>
                      </li>
                      <li><strong>Disclosure Of All Costs To Customers</strong>
                        <ol>
                          <li>The Member must unambiguously list all costs and fees related to the product or service offered, including all upfront fees, processing fees, interest costs, insurance costs, registration fees, provisions, re-arrangement fees, late fees, prepayment fees or penalties and any other costs charged to the customer.</li>
                          <li>The description of all costs, including any conditional or default costs and expenses, must be explicitly provided in a manner that any customer can easily understand.</li>
                          <li>The Member must provide examples of the costs to the customer, including any contingent or default costs, in INR format specific to the financial service or product offered, so that the customer understands exactly all such costs.</li>
                          <li>The Member must provide a repayment schedule with detailed repayment information and due dates in a clear manner.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-5"><strong><u>Pricing And Cost Of Credit</u></strong>
                    <ol>
                      <li><strong>Adverse Pricing</strong><br /><br/>
                        Members will not design pricing models that could ever be considered “adverse” or including but not limited to:<p />
                        <ol>
                          <li>Any misleading element in the presentation of costs or fees;</li>
                          <li>Confusing or complex Cost or fee structures that are designed to extract maximum revenue without consideration of customer understanding.</li>
                        </ol>
                      </li>
                      <li><strong>Interest Rates</strong><br /><br/>
                        Members must disclose upfront effective annual interest rate for the loan in the loan documentation alongside loan amount and tenure so that customers can reasonably calculate the cost of credit.</li>
                      <li><strong>Late Fees &amp; Fines</strong>
                        <ol>
                          <li>The Member must provide clear information related to the amount and mechanism of imposing fines in the event of a delay. Such information should be disclosed upfront to the customer on or before the conclusion of the contract for financial product or service.</li>
                          <li>Late payment penalties should be reasonable and should not compound and shall be charged only in accordance with the disclosed policies of the Member.</li>
                        </ol>
                      </li>
                      <li><strong>Affordability</strong>
                        <ol>
                          <li>The Member must have a system and process of verification and assessment of the financial condition of the customer to assess the eligibility and suitability for the loan or other financial product offered.</li>
                          <li>The Member must have a system to ensure the accuracy of the data and information provided by a customer on the best effort basis.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-5"><strong><u>Collections And Servicing</u></strong>
                    <ol>
                      <li><strong>Information About An Ongoing Loan</strong><br /><br/>
                        The Member must provide timely information about loan payments due and outstanding loan amounts in a format that the customer clearly understands.</li>
                      <li><strong>Collection Process</strong>
                        <ol>
                          <li>The Member will ensure that during the collection process there is no harassment or intimidation.</li>
                          <li>The Member will ensure that its staff, agents and representatives are adequately trained to deal with the customers in an appropriate manner and are not rude or humiliating in their dealings with the customer.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-5"><strong><u>Data Usage And Sharing</u></strong>
                    <ol>
                      <li>The Member must follow a consent-based architecture for data capture with informed consent provided by the customer following a detailed explanation of the data being captured and used (including sharing of such data with third parties). The Member shall preserve such digital records of customer consent(s) as proof of informed consent.</li>
                      <li>The Member is required to practice good faith in the collection, storage, use and sharing of personal data of customers.</li>
                      <li>Without limiting the generality of the above, Members shall not:
                        <ol>
                          <li>intentionally request personal data from a customer even though there is no service that can be provided to a customer;</li>
                          <li>intentionally collect personal data irrelevant to the services that will be provided to the customer by the Member;</li>
                          <li>collect personal data outside the data that has been agreed to be given by the customer;</li>
                          <li>use personal data for purposes that have not been notified or purposes that are different from what was previously notified to the customer;</li>
                          <li>collect and store customer’s personal data even though the Member or any person authorized by the Member to collect, process or store such personal data does not yet have a reliable system or processes to carry out such activities or protect such data;</li>
                          <li>sell personal data of customers to third parties without explicit consent from the customer;</li>
                          <li>share such personal data with third parties other than for purposes consented to by the customer;</li>
                          <li className="mb-3">use the personal data in any manner which is likely to cause physical harm or injury to any customer, their family member or any person associated with the customer.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="mt-5"><strong>Customer Grievance Management</strong>
                <ol>
                  <li>Every Member must have a board approved policy for addressing customer complaints in a fair, and prompt manner and shall put into place an efficient mechanism for implementation of such customer complaint policy and for resolution of customer complaints in compliance with rights of the customer under law and contract.</li>
                  <li>Each Member shall provide to customers, including by publishing on its website, details of how the customer can contact customer service / concerned compliance officer at the Member or seek redressal of customer complaints.</li>
                  <li>The Member should also provide details of how customers can contact the regulated entities, such as banks and NBFCs, which are involved in providing the relevant financial product or services to the customer.</li>
                  <li>The Member should also provide details of the right of the customers to raise complaints in consumer forums, RBI, Financial Ombudsman etc. and guidance on how the customer can approach such authorities.</li>
                </ol>
              </li>
              <li className="mt-5"><strong>Sanctions</strong>
                <ol className="">
                  <li>Finplex shall monitor compliance with the Code of Conduct by Members and the Board of Directors may constitute a Compliance Committee for this purpose, and in such event, all references to the Committee in this section will mean such Compliance Committee.</li>
                  <li>The Committee will notify procedure for the admission of complaints against any Member for the violation of the Code of Conduct, investigation and determination of violation, and the decision on a Sanctions against the non-compliant Member after giving them a reasonable opportunity to represent themselves in this process.</li>
                  <li>The decision taken by the Committee on any non-compliance with the Code of Conduct will be binding on the relevant Member.</li>
                  <li>Any appeal regarding such a decision shall be with the Board of Directors of Finplex and any aggrieved Member can appeal to the Board of Directors within 30 days of such decision.</li>
                  <li>Any member found in non-compliance with the Code of Conduct shall be given 30 days to take corrective action, failing which the Committee shall be entitled to take actions as mentioned below.</li>
                  <li>The Committee will be entitled to any or all of the action against a non-compliant Member as detailed below
                    <ol className="">
                      <li>Cancellation of its Finplex Membership;</li>
                      <li>Bar the Member from future membership and/or participating in its event and services of Finplex for such a period as the Committee may deem fit;</li>
                      <li>Notify all other Members of the cancellation of the membership, and to also publish the fact of such cancellation on the Finplex website;</li>
                      <li>Report any serious violation of the Code of Conduct to the appropriate authorities, including but not limited to the Reserve Bank of India;</li>
                      <li>Issue such other appropriate directions for ensuring compliance with this Code of Conduct, including obtaining a binding commitment from the Member to take necessary remedial steps for compliance with the Code of Conduct.</li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>	
      </div>
   
      
      <Hidenavbar/>  
      </div>
      </div>
    );
  }
}
