import React from "react";
import Hidenavbar from "../functions/hideNavBar";
import {Link} from "react-router-dom";

export default class TermsandUse extends React.Component
{
  componentDidMount(){
    document.title = "Finplex:Terms Of Use"
  }

    render()
{
    return(
        <div className="about">
        <div className="container px-sm-5 px-lg-5 mt-5 ">
          <div className="px-xl-5 mt-5  content-text">
            <h1 className="text-center heading margin-top"> Terms Of Use</h1>
            <p className="mt-5 ">
              The following Terms of Use are applicable to your use of this
              website or any mobile application of Finplex Technologies Private
              Limited ("Finplex") with its registered office at Ground Floor,
              Plot No 7 , Institutional Area, Vasant Kunj, New Delhi - 110070.
            </p>
            <p className="my-4">
              By accessing or using this website or our mobile application you
              agree to be bound by these Terms of Use and our Privacy Policy.
            </p>
            <p className="my-3">
              We reserve the right to make changes to these Terms of Use from
              time to time and will post any updated Terms of Use to the
              website. You should check our website from time-to-time for any
              such changes. The revised terms shall be applicable from the time
              they are posted on the website.
            </p>
            <p className=" sub-heading">Eligibility</p>
            <p className="my-3">
              You confirm that you are over the age of 18 years and you must not
              access this website or our mobile application if you are unable to
              form a binding legal agreement. Further, this website and our
              mobile application are only intended for use by residents of
              India.
            </p>
            <p className=" sub-heading">Intellectual Property Rights</p>
            <p className="my-3">
              Finplex is the owner or authorised licensee of all intellectual
              property rights of all content, material or data on this website
              and our mobile application.You will retain ownership of copyrights
              in the data that you share with us. You agree to grant us a
              worldwide, royalty-free, irrevocable license to use, copy,
              distribute, publish and transmit this data in any manner, subject
              to any applicable laws and regulations and our Privacy Policy.
            </p>
            <p className=" sub-heading">
              Access To The Website And Mobile Application
            </p>
            <p className="my-3">
              While we aim to provide uninterrupted access to this website and
              our mobile application, we do not provide any warranty to that
              effect. From time-to-time access may be suspended, restricted or
              interrupted due to various planned and unplanned reasons. We shall
              not be liable to you for any loss or damage which you may suffer
              due to loss of access to our website or mobile application.
              Further, we reserve the right to suspend, disable or restrict your
              access to our website or mobile application.
            </p>
            <p className=" sub-heading">Your Responsibilities</p>
            <p className="my-2">You agree to:</p>
            <ul>
              <li className="my-2 mx-4 ">
                <p>
                  provide true, complete, accurate and current information and
                  update us about any changes
                </p>
              </li>
              <li className="my-2 mx-4">
                <p>
                  use this website and our mobile application only for its
                  intended use and in accordance with all applicable terms and
                  conditions
                </p>
              </li>
              <li className="my-2 mx-4">
                <p>
                  comply with all applicable laws and regulations in relation to
                  your access to this website and our mobile application
                </p>
              </li>
            </ul>
            <p className="my-2">You agree NOT to:</p>
            <ul>
              <li className="my-2">
                <p>
                  copy, reproduce, distribute, modify, republish, upload,
                  transmit, post or distribute in any form or create derivative
                  works from the contents of the website or mobile application
                  without our explicit written consent
                </p>
              </li>
              <li className="my-2">
                <p>
                  Decompile or reverse engineer any technology or code from our
                  website or mobile application
                </p>
              </li>
              <li className="my-2">
                <p>
                  upload, post or otherwise make available, on our website or
                  mobile application, any material
                </p>
              </li>
              <ul>
                <li className="my-2">
                  <p>
                    that is harmful, unlawful, abusive, hateful or objectionable
                    in our view
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    that restricts or inconveniences any other person from
                    enjoying our website or mobile application
                  </p>
                </li>
                <li className="my-2">
                  <p>that includes unauthorised information of any kind</p>
                </li>
                <li className="my-2">
                  <p>
                    {" "}
                    that contains viruses or any malicious code or files
                    designed to disrupt or limit the functionality in any way or
                    causes it to fail
                  </p>
                </li>
              </ul>
              <li className="my-2">
                <p>
                  make any unauthorised alternations or hack or introduce any
                  material
                </p>
              </li>
              <li className="my-2">
                <p>
                  reverse engineer or decompile any part of the website or our
                  mobile application
                </p>
              </li>
              <li className="my-2">
                <p>impersonate any person or misrepresent information</p>
              </li>
              <li className="my-2">
                <p>
                  take any steps which prevent us from correctly identifying the
                  actual IP address of the device you are using to access our
                  website or mobile application
                </p>
              </li>
              <li className="my-2">
                <p>
                  link to or make any unauthorised use of our uniform resource
                  locator, www.finplex.in, without our explicit written
                  permission.
                </p>
              </li>
            </ul>
            <p className=" sub-heading">Third Party Content</p>
            <p className="my-3">
              Our website and our mobile application may contain references or
              links to third-party websites or applications. We may also post
              content that is supplied by third parties. This information is
              provided for your information and convenience only and we take no
              responsibility for it in any way. Further, use of any third-party
              websites or services may be governed by their own terms and
              conditions and we have no control over them. Publishing of
              third-party links or content is not an endorsement, in any way, of
              the content, products or services of such third parties. All
              third-party trademarks, service marks, trade names, logos or other
              designations of source are the property of their respective
              owners.
            </p>
            <p className=" sub-heading">Third Party Accounts</p>
            <p className="my-3">
              {" "}
              In order to provide our services to you, we and/or our service
              providers may need to register accounts on your behalf with third
              parties, as requested by you. Further, we and/or our service
              providers may need to access and retrieve information requested by
              you from third-party websites or databases, on your behalf. For
              this purpose, you hereby grant us and our service providers a
              limited power of attorney with full power of substitution and
              re-substitution, for you and in your name, place and stead, in any
              and all capacities, to access third party websites, databases,
              servers, applications, documents and retrieve information as well
              as provide information, with full authority and power to perform
              and do all such acts and things necessary to be done in connection
              with such acts, as fully, for all intents and purposes, as you
              might or could do in person. You agree and acknowledge that when
              Finplex or its service providers provide or access and retrieve
              information from third-party sites, we are acting as your agent.
              You further agree that the third parties account providers are
              entitled to rely on this authorisation and the limited power of
              attorney granted by you.
            </p>
            <p className=" sub-heading">
              Privacy Policy and Terms and Conditions
            </p>
            <p className="my-3">
              By using this website or our mobile application, you confirm that
              you have reviewed the Terms of Use, Privacy Policy and Terms and
              Conditions and agree to be bound by these. If you do not agree to
              any of these, you must stop using this website and our mobile
              application immediately.
            </p>
            <p className=" sub-heading">Indemnity</p>
            <p className="my-3">
              You agree to indemnify, defend and hold Finplex, our officers,
              directors, agents, subsidiaries, joint ventures and employees
              harmless from and against any loss, claim, damages, liability or
              expense arising out of or relating to a) any use by you of the
              content and services provided on the website or our mobile
              application and b) breach of these Terms of Use and c) violation
              of any law, rules or regulations by you.
            </p>
            <p className=" sub-heading">Limitation of Liability</p>
            <p className="my-3">
              You agree that Finplex, its officers, directors, agents,
              subsidiaries, joint venture companies, consultants and employees
              shall not be liable to you for any loss or damage arising out of
              your use of this website or our mobile application or your
              availing of any services offered by Finplex and its affiliates.
              You agree that your sole and exclusive remedy shall be to
              discontinue your use of our services, our website and mobile
              application. This limitation of liability shall apply to the
              fullest extent permitted by law.
            </p>
            <p className=" sub-heading">Disclaimer</p>
            <p className="my-3">
              While we make all reasonable efforts to ensure that the data and
              content on this website and our mobile application is accurate,
              access to Finplex website or any mobile application is provided
              without any warranty or any kind, either express or implied. We
              make no representation that the website content or any information
              on this website is complete, accurate or up to date and disclaim
              all liability for any errors or omissions. Further, we provide no
              guarantee that our website, mobile application or any form of
              electronic communication by us is free of viruses, malware or
              other malicious code and you are responsible for your own virus or
              malware protection.
            </p>
            <p className="sub-heading">Severability</p>
            <p className="my-3">
              To the extent that any provision of any of these Terms of Use is
              found by an arbitrator or a court of law or competent authority to
              be invalid, unlawful or unenforceable in any jurisdiction, that
              provision shall: (a) be deemed not to be a part of these Terms of
              Use; (b) not affect the enforceability of the remainder of these
              Terms of Use; (c) not affect the validity, lawfulness or
              enforceability of that provision in any other jurisdiction; and
              shall be replaced by a valid provision, which comes as close as
              reasonably possible to the intended purpose of the original
              provision.
            </p>
            <p className=" sub-heading">Assignment</p>
            <p className="my-3">
              You may not assign or transfer your rights or obligations under
              these Terms of Use to any person. Finplex may assign its rights
              and obligations under these Terms of Use to any person without
              notifying you.
            </p>
            <p className=" sub-heading">No Waiver</p>
            <p className="my-3">
              Any failure or delay by us in exercising any right under these
              Terms of Use is not a waiver of that right nor will any single or
              partial exercise by us of any right preclude any further exercise
              of any right.
            </p>
            <p className=" sub-heading">Governing Law</p>
            <p className="my-3">
              These Terms of Use shall be governed by and interpreted according
              to the laws of Republic of India. The courts in New Delhi shall
              have exclusive jurisdiction in connection with any disputes
              arising out of these Terms of Use and your use of our website or
              mobile application.
            </p>
            <p className="my-4 mb-5">
              If you have any questions, comments or feedback, please write to
              us at{" "}
              <a
                className="link-color text-decoration-none"
                href="mailto:legal@finplex.in"
              >
                legal@finplex.in
              </a>
            </p>
          </div>
        </div>
      
      <Hidenavbar/>  
      </div>
    );
  }
}
