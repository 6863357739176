import React, { Component} from "react";
import Calc from '../functions/calculate';


class Calculator extends Component {
  

  componentDidMount = () => {
    document.title = "Finplex:Calculator"
  };

  render() {
    return (
      <div  className="margin-top">
        <>
        <div className="container-fluid container-xxl mt-5">
              <div className="col-12 mt-sm-4">
                <h1 className="text-center heading">Loan Calculator</h1>
                <Calc/>
              </div>
            </div>
 </>
      </div>

    );
  }
}

export default Calculator;
