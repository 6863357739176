import React from "react";
import { Link } from "react-router-dom";

import Loaneligibility from "./LoanEligibility";
import Docrequired from "./documentsRequired";
import Namepurpose from "./youNameYourPurpose";
import Differentfrom from "./differentFromOthers";
import FreqAskedQuestions from "./frequentlyAskedQuestion";
import Calc from "../functions/calculate";

import Featuresicon2 from "../assets/Features-icon2.svg";
import Featuresicon3 from "../assets/Features-icon3.svg";

import Featuresicon1 from "../assets/Features-icon1.svg";
import Featuresicon4 from "../assets/Features-icon4.svg";

import ProductHighlights from "../assets/Product_Highlights.svg";
import producthighlightmobile from "../assets/producthighlight-mobile.svg";
import Doctor_loan_Animation from "../assets/Professional_Ani.gif";
import Hidenavbar from "../functions/hideNavBar";

export default class otherRevenueloan extends React.Component {
  componentDidMount() {
    document.title = "Finplex: Loans For Professionals";
  }
  render() {
    return (
      <>
        <div className="fontStyle about">
          <section>
            <div className="container-fluid container-xxl">
              <div className="margin-top d-none d-xl-block">
                <div className="heading-min row px-5">
                  <p className>Loans for Professionals</p>
                </div>
                <div className="heading row px-5">
                  <p>Dreaming of innovation? </p>
                </div>
                <div className="row px-5">
                  <p className="heading-min">
                    Finplex offers a deal as satisfying as a perfectly balanced
                    accountant's ledger! Engineers and architects, your big
                    ideas find a treasure trove of professional loans here.
                    Let's blueprint your dreams for success and build a firm
                    financial foundation together!
                  </p>
                </div>
                <div className="row px-5 mt-4">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-3">
                        <img src={Featuresicon2} alt="" className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img src={Featuresicon3} alt="" className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img src={Featuresicon1} alt="" className="img-fluid" />
                      </div>
                      <div className="col-3">
                        <img src={Featuresicon4} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3 line">
                        Loan given without any mortgage
                      </div>
                      <div className="col-3 line">
                        For building new infrastructure, installing new
                        software, marketing campaign, increasing their manpower
                        and to help train them
                      </div>
                      <div className="col-3 line">
                        Simple documents submission
                      </div>
                      <div className="col-3">
                        Tenure and interest rate flexible and customisable{" "}
                      </div>
                    </div>
                    <div className="mt-5">
                      <Link className="text-white nav-btn mt-5" to="/quick">
                        QUICK APPLY
                      </Link>
                    </div>
                  </div>
                  <div className="col-5 d-flex align-items-end">
                    <img
                      className="OPL-image RBL-image"
                      src={Doctor_loan_Animation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/*   <div class="container-fluid container-xxl">
            <div class="margin-top d-none d-xl-block">
                <div class="heading px-5">
                    <p>Revenue based loans for Doctors</p>
                </div>
                <div class="row">
                    <p class="content-text px-5">
                        Doctors are the visible Gods of healing. While their services are required by the poorest of poor and the richest of the rich, what defines their success is the treatment offered to patients. They need trained specialists in different medical fields and multi-speciality hospitals fully equipped with the latest diagnostic machines and surgical apparatus with cutting edge technology. To reach out to the vast expanse of population is the goal of every doctor with a futuristic vision. We make it viable for you to realise your vision by offering a special doctor's loan based on your revenues. 

                    </p>
                </div>
                <div class="row px-5">
                    <div class="col-7">
                        <div class="row">
                            <div class="col-3"><img src={Featuresicon2} alt = class="img-fluid" /></div>
                            <div class="col-3"><img src=“{Features-icon3} alt = class="img-fluid" /></div>
                            <div class="col-3"><img src={Featuresicon1} alt = class="img-fluid" /></div>
                            <div class="col-3"><img src={Featuresicon4} alt=class="img-fluid" /></div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-3 line">Loan given based on average bank balance without any mortgage.</div>
                            <div class="col-3 line">Loans can be used for any purpose - Business growth, Purchasing New Equipment, Working Capital Finance, Expansion of business locales, Inventory purchase and also to Hire more staff</div>
                            <div class="col-3 line">Simple documents submission of a few months to show average bank balance in bank.
                            </div>
                            <div class="col-3">Tenure and interest rate flexible and customizable </div>
                        </div>
                        <div class="row">
                            <a class=" text-white nav-btn px-lg-5 mt-5 mb-3" href="./quickApply.html">QUICK APPLY</a>
                        </div>
                    </div>
                    <div class="col-5">
                        <img class="" style="max-width: 650px;max-height:2000px;margin-left:-6vw;margin-top:-6vh;" src={Doctor_loan_Animation} alt = />
                    </div>
                </div>
            </div>
            </p>
       */}
              <div className="d-xl-none">
                <p className="heading-min margin-top  text-center">
                  Loans for Professionals
                </p>
                <p className="heading text-center">Dreaming of innovation?</p>
                <div className="row px-3 flex-column-reverse">
                  <div className="col-12 ">
                    <p className="heading-min mt-5">
                      Finplex offers a deal as satisfying as a perfectly
                      balanced accountant's ledger! Engineers and architects,
                      your big ideas find a treasure trove of professional loans
                      here. Let's blueprint your dreams for success and build a
                      firm financial foundation together!
                    </p>
                    <div className=" d-none d-md-block">
                      <div className="row mt-5 ">
                        <div className="  col-3">
                          <img
                            src={Featuresicon2}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="  col-3">
                          <img
                            src={Featuresicon3}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className=" col-3 ">
                          <img
                            src={Featuresicon1}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className=" col-3">
                          <img
                            src={Featuresicon4}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 content-text lh-sm">
                      <div className="d-md-none mt-5">
                        <div className="row">
                          <div className=" col-6 ">
                            <img
                              src={Featuresicon2}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className=" col-6 ">
                            <img
                              src={Featuresicon3}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-3 col-6 col-md-3 mt-3 mt-md-0 line">
                        Loan given without any mortgage.
                      </div>
                      <div className="px-2 col-6 col-md-3 mt-3 mt-md-0 line-md">
                        For building new infrastructure, installing new
                        software, marketing campaign, increasing their manpower
                        and to help train them
                      </div>
                      <div className="d-md-none mt-5">
                        <div className="row">
                          <div className=" col-6 ">
                            <img
                              src={Featuresicon1}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className=" col-6">
                            <img
                              src={Featuresicon4}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 line mt-3 mt-md-0">
                        Simple documents submission
                      </div>
                      <div className="col-6 col-md-3 mt-3 mt-md-0">
                        Tenure and interest rate flexible and customisable
                      </div>
                      <div className="mt-3 d-flex justify-content-center">
                        <Link className="text-white  nav-btn mt-5" to="/quick">
                          QUICK APPLY
                        </Link>
                      </div>
                      <p className="mb-5" />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <img
                      className="img-fluid"
                      src={Doctor_loan_Animation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="loan-bg d-none d-md-block mt-5">
            <div className="container-xxl container-xl container-lg container-md">
              <div className="text-center">
                <img
                  src={ProductHighlights}
                  alt=""
                  className="img-fluid my-5"
                />
              </div>
            </div>
          </div>
          <div className="loan-bg d-md-none">
            <div className="container-xxl container-xl container-lg container-md">
              <div className="text-center">
                <img
                  src={producthighlightmobile}
                  alt=""
                  className="img-fluid my-5"
                />
              </div>
            </div>
          </div>
          <Loaneligibility />
          <Docrequired />
          <Namepurpose />
          <Differentfrom />
          <section>
            <div className="loan-bg">
              <div className="container-fluid container-xxl mt-5">
                <div className="row mt-5">
                  <div className="col-sm-12 mt-sm-4 px-xl-5 ">
                    <h1 className="text-center sub-title">
                      Loans For Professional Calculator
                    </h1>
                    <Calc />
                    <p className="mb-5" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid container-xxl">
            <FreqAskedQuestions />
          </div>

          <Hidenavbar />
        </div>
      </>
    );
  }
}
