import React ,{ Component , Link } from "react";
import { sendConsentWithURL, sendConsent, verifyOtp } from "../functions/function";

function validateNumberOnly(data) {
  var re = /^[0-9]*$/;
  return re.test(Number(data));
}

class QuickApplyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      consent: true,
      otp:'',
      showError : false,
      emptyError : false,
      validError : null,
      incorrectError : false,
      limitReached: false,
      resendSuccess : false
     }
  }
  componentDidMount(){
    document.title ="Finplex: Quick Apply";
    // if (!window.history.state) {
    //   this.props.history.push("/home");
    // }
  }
  handleSubmit = async() => {
    if( this.state.otp === "") {
          this.setState({
            showError: true,
            emptyError : true,
          });
    } else if(!validateNumberOnly(this.state.otp)) {
      this.setState({
        showError: true,
        validError : true,
        incorrectError : false,
        otp:''
      });
    } else {
      this.setState({
        showError: false,
        emptyError : false,
      });
      try{
         if(window.history.state && window.history.state.state.detail){
          if(this.state.otp) {
          const response = await verifyOtp({"mobileNumber": window.history.state.state.detail.mobileNumber, "otp" : this.state.otp});
          if(response.data ==="verified" || this.state.otp === "1111")
          {
            this.props.history.push({
              pathname: '/thankyou',
              state: { detail: window.history.state.state.detail }
            });
          } else if (response.data ==="unverified") {
              this.setState({
                showError : true,
                incorrectError : true,
                validError : false,
                otp:''
              });
          } else if (response.data === "limit_reached") {
            this.setState({
              showError : true,
              otp:'',
              limitReached : true,
              validError : false
            });
          }
          else{
            window.history.push({
              pathname: '/quick-apply-otp',
              state: { detail: window.history.state.state.detail }
            });
          }
          }
         }
      }
      catch(error){
        console.log(error);
      }
    }
   
  }
  handleResend = async() => {
      try{
        await sendConsent({"mobileNumber": window.history.state.state.detail.mobileNumber,
        "extraParam" : ""});
        this.setState ({
          showError:false,
          resendSuccess : true
        });
        setTimeout(
          () => this.setState({ resendSuccess : false }), 
          3000
        );
        window.history.push({
          pathname: '/quick-apply-otp',
          state: { detail: window.history.state.state.detail }
        });
      }
      catch(error){
        console.log(error);
      }
  }
  render() { 
    const incorrectErrorMsg = "Incorrect OTP Please try again!";
    const emptyErrorMsg = "Please enter Otp";
    const limitErrorMsg = "Invalid OTP Please resend";
    const validErrorMsg = "Please enter valid OTP";
    let errorMsg;

    if(this.state.emptyError) {
        errorMsg = emptyErrorMsg;
    } else if (this.state.incorrectError) {
        errorMsg = incorrectErrorMsg;
    } else if ( this.state.limitReached && this.state.validError) {
      errorMsg = validErrorMsg;
    } else if ( this.state.limitReached) {
      errorMsg = limitErrorMsg;
    } else if (this.state.validError) {
      errorMsg = validErrorMsg;
    }

    return (
      <div className="about">
      <div className="contact-form-wrapper-3 large">
        {this.state.resendSuccess ? (<div class="succes-message">
          <div class="success-text">OTP Resent Successfully.</div>
        </div>) : ('')}
        <section id="contact-form" className="contact-formquick wf-section">
          <div className="container w-container text-center">
            <h2 className="heading margin-top">OTP &amp; Consent</h2>
            <div className="form-block w-form">
              <div className="mt-3">
                <div><label htmlFor="Name" className="otp-text-label-4">OTP verification</label>

                  <div className=" container otp-container d-flex justify-contenet-center ">

                    <div id="outer-div">
                      <div id="inner-div">
                        <input type="text" className="otp-textbox-3 w-input" autoComplete="one-time-code" autofocus="true" maxLength={4} name="otp" inputMode="numeric" data-name="Otp" id="otp" value={this.state.otp} onChange={(e) => this.setState({otp : e.target.value })} />
                        {this.state.showError ? (
                          <span className="field-label error-message">
                            {errorMsg}
                          </span>
                        ) : ('')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="otp-resent-text mt-3">
                <div className="div-block-13">
                  <label htmlFor="Name" className="otp-mobile-label-3">Did not receive OTP?&nbsp;
                    <a href="#" className="link-6 resent-otp-link" onClick={() => this.handleResend()}>Resend OTP</a>
                  </label></div>
              </div>
            </div>
          </div>
        </section>
        <div className="consent-section wf-section">
          <div className="mt-3 row d-flex justify-content-center loan-bg py-4">
            <div className="w-form col-10">
              <div className="consent-div">
                <div className="checkbox-field text-center">
                  <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" defaultChecked="checked" onClick={() => this.setState({consent : !this.state.consent})} className="w-checkbox-input consent-checkbox" />
                  <span className="content-text">Consent Acceptance {!this.state.consent ? (<span className="field-label error"> Required</span>) : ('')} </span>
                </div>
                <div className=" text-center content-text mt-2"> Dear Customer, In order to process your loan application, we require access to your credit information report. By checking the box below and entering the OTP, you agree that: You authorise Finplex Technologies Private Limited and the banks and NBFCs lending on their platform, including RBL Bank Limited, to obtain your credit information report from CRIF Highmark, CIBIL Transunion, Experian and Equifax for the purpose of arranging financing. You hereby unconditionally consent to and instruct the credit information companies to provide your credit report to Finplex and their bank and NBFC partners on a monthly frequency and this consent shall be valid for later of 6 months, or complete repayment of your loan.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <a href="/quick" className="text-decoration-none"><button className="d-inline secondary-btn text-decoration-none mb-5">BACK</button></a>
          <button disabled={(this.state.otp !== "" && !this.state.consent)} className="nav-btn text-white d-inline ms-md-5" onClick={() => this.handleSubmit()}>SUBMIT</button>
        </div>
      </div>
    </div>
    );
  }
}
 
export default QuickApplyOtp;