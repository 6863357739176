import React  , {useState , useEffect} from "react";
import Hidenavbar from "./Hidenavbar";
import Validation from "./Validation";
import investors1 from '../assets/investors1.png';
import Registration1 from '../assets/Registration1.png';
import { Form, Field } from 'react-final-form';
import Button from "../functions/button";
import { savePartnerWithUs } from "../functions/function";

export default function Investors() {
  const [requestSuccess, setRequestSuccess] = useState(false);

  useEffect(() => {
    document.title = "Finplex: Investors";
    }, []);

    const onSubmit = async (values) => {
      try {
        const result = await savePartnerWithUs(values);
        if( result.data.status === "Mail Sent") {
          setRequestSuccess (true)
          setTimeout(() => {
            setRequestSuccess(false);
            window.location.reload();
          },4000);
        }
      }
      catch (error) {
        console.log(error);
      }
    }

  return (
    <div className="about">
      <div className="container px-3 px-lg-5 margin-top">
          <div className="row ">
          { requestSuccess ? (<div class="succes-message">
            <div class="success-text">Request sent Successfully.</div>
              </div>) :('')}
            <p className="heading text-center">Investors</p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-7 px-4">
              <div className="row">
                <p className="sub-heading mt-1 center-sm">Why should Investors choose Finplex?
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <img src={investors1} className="img-fluid" />
                </div>
                <div className="mt-3 center-sm">
                  <p className="content-text  center-sm"> Financial inclusion of hitherto unbanked or underserved businesses is centre stage in the digital lending environment in recent years because it is a sure-fire revenue opportunity. </p>

                  <p className="content-text  center-sm">With the mushrooming growth of finance companies that offer business loans, an impetus to any investor backing a company is to be sure that the company thrives on futuristic technology, exploration of emerging trends and best practices and align these with profitability and business goals that are customer-centric and can boast of credibility in the market.</p>
                  <p className="content-text  center-sm">At Finplex, we are powered by AI and ML technologies that analyse an array of data and the results in objective insights and analysis consisting of a  360° view of the creditworthiness of the clients seeking loans.  Risks of fraud in lending are minimized. The system also has the wherewithal to follow up on loan repayments.  Finplex has the perfect blend of technology, expertise and strategy to choose to lend to businesses that are poised for exponential growth.  Customer satisfaction is guaranteed with secure, swift and simple transactions. The ease of automation with access to the world's leading technologies and service providers, smart scalability and the broad scope of expansion of business with an innovative bouquet of loans that are easily adaptable and customizable to clients, maximises returns on investment. Any investment backing Finplex is worth its weight in gold.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-lg-5 px-3  px-lg-5 mt-5 mt-lg-0 leftBorder">
              <p className="sub-heading mt-1  center-sm">Get In Touch With Us</p>
              <p className="content-text lh-sm  center-sm">Reach out to us &amp; we will respond as soon as we can.</p> 
              <Form
                onSubmit={onSubmit}
                initialValues={{  name : '', email : '', mobilenumber : '' , organization : '' , message : ''}}
                validate={values => {
                  const errors = {};

                  function validateEmail(email) {
                    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(email).toLowerCase());
                  }

                  function validateTextOnly(data) {
                    var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                    return re.test(String(data));
                  }

                  function validateNumberOnly(data) {
                    var re = /^[0-9]*$/;
                    return re.test(Number(data));
                  }

                  if (!
values.name
) {
                    
errors.name
 = "Required";
                  } else if (!validateTextOnly(
values.name
)) {
                    
errors.name
 = "Please enter letters only";
                  } else if (values.name.length < 3) {
                    
errors.name
 = 'Please enter minimum 3 characters';
                  }

                  if (!
values.email
) {
                    
errors.email
 = "Required";
                  } else if (!validateEmail(
values.email
)) {
                    
errors.email
 = "Please enter valid email";
                  }

                  if (!values.mobilenumber) {
                    errors.mobilenumber = "Required";
                  } else if (!validateNumberOnly(values.mobilenumber)) {
                    errors.mobilenumber = "Please enter numbers  only";
                  } else if (values.mobilenumber.length < 10) {
                    errors.mobilenumber = "Phone Number should contain 10 numbers";
                  }

                  if (values.organization) {
                    if (!validateTextOnly(values.organization)) {
                      errors.organization = "Please enter letters only";
                    } else if (values.organization.length < 3) {
                      errors.organization = 'Please enter minimum 3 characters';
                    }
                  }

                  return errors;
                }}
                render={({ handleSubmit, values }) => (
                  <form className="row g-3 field-text" onSubmit={handleSubmit}>
                    <div className="col-12 mt-5">
                      <Field
                        name="name"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="email"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="mobilenumber"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="mobilenumber" className="form-label">
                              Phone Number
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="organization"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="organization" className="form-label">
                              Organisation  <span className="fontSize">(Optional)</span>
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12 marginTop">
                      <Field
                        name="message"
                        render={({ input, meta }) => (
                          <div>
                            <label htmlFor="message" className="form-label">
                              Message  <span className="fontSize">(Optional)</span>
                            </label>
                            <input
                              {...input}
                              className={
                                meta.touched && meta.error
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {meta.touched && meta.error && (
                              <span className="invalid-feedback">{meta.error}</span>
                            )}
                          </div>


                        )}
                      />
                    </div>
                    <div className="col-12 marginTop flex">
                      <Button
                        type="submit"
                        label="SUBMIT"
                        className="nav-btn float-end content-text mt-3 text-white text-center"
                        handleClick={onSubmit}
                      />
                    </div>


                  </form>
                )}
              ></Form>
  
            </div>
          </div>
        </div>
          <Hidenavbar />
        <Validation />
      </div>
  )
}