import React, { Component } from 'react';
import {getLoanDocs, getexampleDocs, getLoanByID , BANKS , BASE_URL, bankAccountsList } from '../functions/function';
import { accessToken } from '../functions/awaAuth';
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Row , Col } from 'reactstrap';
import {toastSuccess } from "./toast";
import _ from "lodash";

class ReviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docGroups : [],
            files: [],
            loanId :props.loanId || props.loanValue.data.loanAppId,
            modalState: false,
            applicantModalState : false,
            applicantDocName : '',
            fileShowState : false,
            fileShowName : '',
            uploadFile : false,
            uploadFileName :  '',
            bankName : '',
            bankPassword : '',
            bankAccountType: '',
            bankAccountNumber : '',
            applicantName : '',
            formErrors : {},
            selectedFile : null,
            statusdata : [],
            nullValue :  false,
            loanDetails : [],
            groups : [],
            examples : [],
            showUser : [],
            docs : [],
            docsUpload: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    async componentDidMount () {
        const documents = await getexampleDocs();
        this.setState({docGroups:documents.data});

        const loanDetails = await getLoanByID(this.state.loanId);
        this.setState({loanDetails : loanDetails.data.applicants})


        this.getDocs();
        let array = [];
        documents.data.map((data) => {
            if(data.showUser === true) {
                array.push(data);
            }
        })

        var output = _.map(_.groupBy(array, 'category'),function(obj, key){
            var temp = {};
            temp[key] = _.groupBy(obj, 'type')
            return temp;
          });
          console.log("SSS" , output);
        this.setState({groups : output});
    }

    gotoHome = (e)  => {
        this.props.history.push({
            pathname: '/home'
          })
    }

    async getDocs () {
        const docs = await getLoanDocs(this.state.loanId);
        let docsdata = [];
        docs.data.map((item) => {
            return (
                <>
                {item.docLink ? docsdata.push(item) : ''}
                </>
            )
        })
        var DATA = _.map(_.groupBy(docsdata, 'category'),function(obj, key){
            console.log("OBJ" , obj)
            var temp = {};
            temp[key] = _.groupBy(obj, 'type')
            return temp;
          }); 
        this.setState({docs : DATA , docsUpload : docsdata});
    }

    handleChange(event) {
        this.setState({
         [event.target.name] : event.target.value
        } , () => {this.validateField(event.target.name, event.target.value)});
      }


      validateField(fieldName, value) {

        function validateTextOnly(data) {
            var re = /^[a-zA-Z-,_]+(\s{0,1}[a-zA-Z-, ])*$/;// /^[a-zA-Z]*$/;
            return re.test(String(data));
          }
        
          function validateNumberOnly(data) {
            var re = /^[0-9]*$/;
            return re.test(Number(data));
        }
  
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        switch(fieldName) {
            case 'bankName':
                if(value === "") {
                    fieldValidationErrors.name = 'Required';
                } else if (!validateTextOnly(value) ){
                    fieldValidationErrors.name = 'Please enter letters only';
                } else {
                    fieldValidationErrors.name = "";
                }
                break;
            case 'bankAccountNumber':
                if(value) {
                    if (!validateNumberOnly(value) ){
                        fieldValidationErrors.accountNumber = 'Please enter numbers only';
                    } else {
                        fieldValidationErrors.accountNumber = "";
                    }
                }
                break;
            case 'bankAccountType':
                if(value === "--Choose--") {
                    fieldValidationErrors.accountType = 'Required';
                } else {
                    fieldValidationErrors.accountType = "";
                }
                break;
            case 'applicantName':
                if(value === "--Choose--") {
                    fieldValidationErrors.applicantName = 'Required';
                } else {
                    fieldValidationErrors.applicantName = "";
                }
                break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
          nameValid: nameValid,
          formValid: this.state.nameValid
        });
      }

     async onChange(e , type , name) {
         this.setState({nullValue : false})
        let files = e.target.files;
        // this.setState({ files: files[0]});        
        const data = new FormData();

        await data.append('loanid',this.state.loanId); 
        await data.append('type', type);
        await data.append('name', name);
        await data.append('acctype', name ==='Bank Statements'? this.state.bankAccountType :'' );
        await data.append('bankname', name ==='Bank Statements'? this.state.bankName :'');
        await data.append('password', name ==='Bank Statements'? this.state.bankPassword :'');
    
        for (let i = 0; i <= files.length -1; i++) {
             await data.append('files',files[i]);
        }

        if(this.state.files&&this.state.files[type]&&this.state.files[type][name])
            this.setState((prev)=>({          
            files: {
                ...this.state.files,
                [type]: {
                ...this.state.files[type],
                [name]: [...Object.values(this.state.files[type][name]),...files]
                }
            }
            }));
        else 
            this.setState(() => ({
                files: {
                    ...this.state.files,
                    [type]: {
                        ...this.state.files[type],
                        [name]: [...files]
                    }
                    }
            }));
      }

      handleShow() {
        // const { history } = this.props;
        this.setState({ modalState: !this.state.modalState,
         });
      }

      applicantModalShow(name) {
        this.setState({ 
            applicantModalState: !this.state.applicantModalState,
            applicantDocName : name,
         });
      }


      ShowFiles(name) {
        this.setState({ fileShowState: !this.state.fileShowState,
            fileShowName : name,
         });
      }

      uploadFilesShow(category , type) {
        this.setState({ uploadFile: !this.state.uploadFile,
            uploadFileName : type,
         });
      }

      fileRemove = async (type , name ,index) => {
        let array = {...this.state.files[type][name]};
        delete array[index];
        console.log(array);
        this.setState((prev)=>({          
            files: {
              ...this.state.files,
              [type]: {
                ...this.state.files[type],
                [name]: array
              }
            }
          }));
        if(Object.keys(this.state.files[type][name]).length === 1) {
            this.setState({ fileShowState : false , nullValue : true});
        }
        }
      
      async onUplaod (type , item) {
          const data = new FormData();

            await data.append('loanid',this.state.loanId); 
            await data.append('category', type);
            await data.append('type', item);
            await data.append('name', item);
            await data.append('createdBy' , 'webuser@finplex.in');
            await data.append('comments' , '');
            if(type === "Applicant Documents")
                await data.append('borrowerId', this.state.applicantName);
            if(item === 'Bank Statements')
                await data.append('acctype', item ==='Bank Statements'? this.state.bankAccountType ? this.state.bankAccountType : undefined :null );
                await data.append('accNumber',item ==='Bank Statements'? this.state.bankAccountNumber ? this.state.bankAccountNumber : undefined : null);
                await data.append('bankname', item ==='Bank Statements'? this.state.bankName ? this.state.bankName : undefined :null);
                await data.append('password', item ==='Bank Statements'? this.state.bankPassword ? this.state.bankPassword: undefined :null);
                await data.append('scanned' , false);
            ;

            if(this.state.files && this.state.files[type] && this.state.files[type][item]) {
                for(let i=0;i<Object.entries(this.state.files[type][item]).length;i++)
                {
                  data.append('files',this.state.files[type][item][i]);
                }
            }
            const headers = {
                'Content-Type': 'application/json',
                'x-api-key': '7r4tQlGQEs20IzvUsPKJS4Wui8fAPYKc6uTbKVoZ',
                'Authorization': 'Bearer ' + accessToken()
            }
            var encodedURI = window.encodeURI(`${BASE_URL}/intmgmtsvc/uploadDoc`);
            axios.post(encodedURI, data,{headers:headers})
            .then(response => {
                this.setState({files: [] , nullValue : true})
                console.log("response ", response);
                if(response.status === 200 ) {
                    this.setState({ 
                        statusdata : {
                            "name" : item,
                            "status" : true,
                        },
                        applicantModalState : false,
                        modalState : false
                    })
                }
                this.getDocs();
            }).finally(()=> {
                toastSuccess(`${item} document upload success!`);
            })
      }
      
      validateForm () {
        const { bankName , bankPassword , bankAccountType} = this.state;
        function validateTextOnly(data) {
            var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;// /^[a-zA-Z]*$/;
            return re.test(String(data));
          }
        let fieldValidationErrors = this.state.formErrors;
        if(bankName == "") {
            fieldValidationErrors.name = 'Required';
        } else if (!validateTextOnly(bankName) ){
            fieldValidationErrors.name = 'Please enter letters only';
        } else {
            fieldValidationErrors.name = "";
        }
        
        if(bankAccountType == "") {
            fieldValidationErrors.accountType = 'Required';
        } else {
            fieldValidationErrors.accountType = "";
        }

        this.setState({formErrors: fieldValidationErrors});
        return;
      }

      validateApplicantForm () {
        const { applicantName} = this.state;
        let fieldValidationErrors = this.state.formErrors;
        
        if(applicantName === "") {
            fieldValidationErrors.applicantName = 'Required';
        } else {
            fieldValidationErrors.applicantName = "";
        }

        this.setState({formErrors: fieldValidationErrors});
        return;
      }
     onFilesChange (files) {
        this.setState({files : files});
        const data = new FormData();

         data.append('loanid',this.state.loanId); 
        //  data.append('files',...files);
        for (let i = 0; i <= this.state.files.length -1; i++) {
            data.append('files',this.state.files[i]);
        }
        // await data.append('type', item.type);
        // await data.append('name', item.name);
        // await data.append('acctype', item.name ==='Bank Statements'? this.state.bankAccountType :'' );
        // await data.append('bankname', item.name ==='Bank Statements'? this.state.bankName :'');
        // await data.append('password', item.name ==='Bank Statements'? this.state.bankPassword :'');

        // console.log("data", ...data);
    }
    render() { 
        return ( 
            <div className="container">
                <main className="form-container w-container row about">
                    <div className="col-xl-9 content-text">
                        {this.state.groups.map((a) => {
                            return (
                                Object.entries(a).map((b,i)=> {
                                    return (
                                        <div className="docs-container w-container">
                                            <h3 className="docs-title">{b[0]}</h3>
                                            <div className="docs-section wf-section">
                                                <div className="docs-self-container">
                                                    <div className="docs-name">
                                                    {b[1] && Object.entries(b[1]).map((c) => {
                                                        return (
                                                            <div className="row doc-name mt-5 mt-md-4">
                                                                <div className="col-md-8">
                                                                    <div className="docs-name-text">{c[0]}</div>
                                                                    {c[1] && c[1].map((d)=>{
                                                                        return (
                                                                            <div className="docs-name-subtext">{`(${d.examples})`}</div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className='col-md-1 col-lg-2'></div>
                                                                <div className='col-md-3 col-lg-2 mt-3 mt-md-0'>
                                                                {b[0] === "Applicant Documents" ? (
                                                                    <div>
                                                                        <button type="button" className="bank-choose-btn" data-toggle="modal" data-target="#applicantModal" onClick={() => this.applicantModalShow(c[0])}>
                                                                            Choose File
                                                                        </button>
                                                                        {this.state.docs.map((data) => {
                                                                            return (
                                                                                <>
                                                                                {Object.entries(data).map((item) => {
                                                                                    if(item[0] === 'Applicant Documents') {
                                                                                        return (
                                                                                            <>
                                                                                            {item[1] && Object.entries(item[1]).map((val) => {
                                                                                                console.log("VaL" , val)
                                                                                                return (
                                                                                                    <>
                                                                                                    {val[0] === c[0] ?
                                                                                                    <a href='#'  className='previous-btn-link data' onClick={() => this.uploadFilesShow(b[0],c[0])}>Show Files</a> 
                                                                                                    : 
                                                                                                        "" 
                                                                                                    }
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    
                                                                                })}
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <div className={"modal fade" + ( this.state.applicantDocName === c[0] && this.state.applicantModalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                                                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-body">
                                                                                        <form>
                                                                                            <div className="docs-self-container">
                                                                                                <div className="docs-name">
                                                                                                    <div className="docs-name-text">Applicants</div>
                                                                                                </div>
                                                                                                <div className="docs-btn">
                                                                                                    <select name="applicantName" className="text-field w-input mb-2 form-select" onChange={(e)=>this.handleChange(e)}>
                                                                                                        <option disabled selected>--Choose--</option>
                                                                                                        {this.state.loanDetails.map((applicant)=> {
                                                                                                            return (
                                                                                                                <option value={applicant.name}>{applicant.name}</option>
                                                                                                            )
                                                                                                        })}
                                                                                                    </select>
                                                                                                    <div className="field-label error error-message">{this.state.formErrors.applicantName}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="docs-self-container">
                                                                                                <div className="docs-name">
                                                                                                    <div className="docs-name-text">{c[0]}</div>
                                                                                                </div>
                                                                                                <div className="docs-btn">
                                                                                                    { this.state.formErrors.applicantName !== "" ?  <button type="button" className="bank-choose-btn" onClick={()=> this.validateApplicantForm() }> Choose File</button> 
                                                                                                    : 
                                                                                                        <div className='applicantUploadBtn'>
                                                                                                            {this.state.nullValue ? 
                                                                                                                <input type="file" htmlFor="imageUpload" multiple={true} value = '' id="img" name="file"  onChange={e => this.onChange(e , b[0] , c[0])} />
                                                                                                                : 
                                                                                                                <input type="file" htmlFor="imageUpload" multiple={true} id="img" name="file"  onChange={e => this.onChange(e , b[0] , c[0])} />
                                                                                                                } 
                                                                                                            {Object.entries(this.state.files).length !== 0 && Object.entries(this.state.files).map(([stateKey, value]) => {
                                                                                                                return Object.entries(value).map(([valueKey, fileList]) => {
                                                                                                                    if(valueKey.toString() === c[0].toString()){
                                                                                                                        if(Object.keys(fileList).length!==0)
                                                                                                                            return(
                                                                                                                                <div style={{marginTop:'10px'}}>
                                                                                                                                    <span className="previous-btn-link" onClick={() => this.ShowFiles(c[0])}>
                                                                                                                                        {Object.keys(fileList).length} Files
                                                                                                                                    </span>
                                                                                                                                    <button type="button" className="k-upload-selected" onClick={()=> this.onUplaod(b[0],c[0])}> Upload </button>
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    })
                                                                                                                })
                                                                                                            }
                                                                                                            
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                    <a href='#' className="previous-btn-link text-center link-color text-decoration-underline" onClick={() => this.applicantModalShow('')}>Close</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    ) : ('')
                                                                }
                                                                {c[0] === "Bank Statements" ? (
                                                                    <div>
                                                                    <button type="button" className="bank-choose-btn" data-toggle="modal" data-target="#myModal" onClick={() => this.handleShow()}>
                                                                        Choose File
                                                                    </button>
                                                                    {this.state.docs.map((data) => {
                                                                            return (
                                                                                <>
                                                                                {Object.entries(data).map((item) => {
                                                                                    if(item[0] === b[0]) {
                                                                                        return (
                                                                                            <>
                                                                                            {item[1] && Object.entries(item[1]).map((val) => {
                                                                                                console.log("VaL" , val)
                                                                                                return (
                                                                                                    <>
                                                                                                    {val[0] === c[0] ?
                                                                                                    <a href='#'  className='previous-btn-link data' onClick={() => this.uploadFilesShow(b[0],c[0])}>Show Files</a> 
                                                                                                    : 
                                                                                                        "" 
                                                                                                    }
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    
                                                                                })}
                                                                                </>
                                                                            )
                                                                        })}
                                                                <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                                    <div className="modal-content">
                                                                    <div className="modal-body">
                                                                        <form>
                                                                                <div className="docs-self-container">
                                                                                    <div className="docs-name">
                                                                                        <div className="docs-name-text">Account Type</div>
                                                                                    </div>
                                                                                    <div className="docs-btn">
                                                                                        <select name="bankAccountType" className="text-field w-input mb-2 form-select" onChange={(e)=>this.handleChange(e)}>
                                                                                            <option disabled selected>--Choose--</option>
                                                                                            {bankAccountsList.map((data) => {
                                                                                                return (
                                                                                                    <>
                                                                                                    <option value={data}>{data}</option>
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                        </select>
                                                                                        <div className="field-label error error-message">{this.state.formErrors.accountType}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="docs-self-container">
                                                                                <div className="docs-name">
                                                                                    <div className="docs-name-text">Account Number</div>
                                                                                </div>
                                                                                <div className="docs-btn">
                                                                                    <input name="bankAccountNumber" type="text" className="text-field w-input mb-2" onChange={(e)=>this.handleChange(e)}></input>
                                                                                    <div className="field-label error error-message">{this.state.formErrors.accountNumber}</div>
                                                                                </div>
                                                                            </div>
                                                                                <div className="docs-self-container">
                                                                                <div className="docs-name">
                                                                                    <div className="docs-name-text">Bank Name</div>
                                                                                </div>
                                                                                <div className="docs-btn">
                                                                                    {/* <input name="bankName" type="text" className="text-field w-input" onChange={(e)=>this.handleChange(e)}></input> */}
                                                                                    <select name="bankName" className="text-field w-input mb-2 form-select" onChange={(e)=>this.handleChange(e)}>
                                                                                            <option disabled selected>--Choose--</option>
                                                                                            {BANKS.map((data) => {
                                                                                                return (
                                                                                                    <>
                                                                                                    <option value={data}>{data}</option>
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                            
                                                                                        </select>
                                                                                    <div className="field-label error error-message">{this.state.formErrors.name}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="docs-self-container">
                                                                                <div className="docs-name">
                                                                                    <div className="docs-name-text">Bank Password</div>
                                                                                </div>
                                                                                <div className="docs-btn">
                                                                                    <input name="bankPassword" type="text" className="text-field w-input mb-2" onChange={(e)=>this.handleChange(e)}></input>
                                                                                    <div className="field-label error error-message">{this.state.formErrors.pass}</div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            <div className="docs-self-container">
                                                                                <div className="docs-name">
                                                                                    <div className="docs-name-text">Bank Statement</div>
                                                                                </div>
                                                                                <div className="docs-btn">
                                                                                { this.state.formErrors.name !== "" || this.state.formErrors.accountType !== "" ?  <button type="button" className="bank-choose-btn" onClick={()=> this.validateForm() }> Choose File</button> 
                                                                                : 
                                                                                
                                                                                <div className='applicantUploadBtn'>
                                                                                    {this.state.nullValue ? 
                                                                                        <input type="file" htmlFor="imageUpload" multiple={true} value = '' id="img" name="file"  onChange={e => this.onChange(e , b[0] , c[0])} />
                                                                                        : 
                                                                                        <input type="file" htmlFor="imageUpload" multiple={true} id="img" name="file"  onChange={e => this.onChange(e , b[0] , c[0])} />
                                                                                        } 
                                                                                    {Object.entries(this.state.files).length !== 0 && Object.entries(this.state.files).map(([stateKey, value]) => {
                                                                                        return Object.entries(value).map(([valueKey, fileList]) => {
                                                                                            if(valueKey.toString() === c[0].toString()){
                                                                                                if(Object.keys(fileList).length!==0)
                                                                                                    return(
                                                                                                        <div style={{marginTop:'10px'}}>
                                                                                                            <span className="previous-btn-link" onClick={() => this.ShowFiles(c[0])}>
                                                                                                                {Object.keys(fileList).length} Files
                                                                                                            </span>
                                                                                                            <button type="button" className="k-upload-selected" onClick={()=> this.onUplaod(b[0] , c[0])}> Upload </button>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            })
                                                                                        })
                                                                                    }
                                                                                
                                                                                    </div>
                                                                                
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                    <a href="#" className="previous-btn-link text-center link-color text-decoration-underline" onClick={() => this.handleShow()}>Close</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :(
                                                        <div className='inputLink'>
                                                        {b[0] !== 'Applicant Documents' ? (
                                                            <>
                                                        <div className='showfiles'>
                                                        {this.state.nullValue ? 
                                                        <input type="file" htmlFor="imageUpload" multiple={true} value = '' id="img" name="file"  onChange={e => this.onChange(e , b[0] , c[0])} />
                                                        : 
                                                        <input type="file" htmlFor="imageUpload" multiple={true} id="img" name="file"  onChange={e => this.onChange(e , b[0] , c[0])} />
                                                        }
                                                        
                                                        {Object.entries(this.state.files).length !== 0 && Object.entries(this.state.files).map(([stateKey, value]) => {
                                                            console.log("VALUE KEY" , value)
                                                            return Object.entries(value).map(([valueKey, fileList]) => {
                                                                if(valueKey.toString() === c[0].toString()){
                                                                    if(Object.keys(fileList).length!==0)
                                                                        return(
                                                                            <div style={{marginTop:'10px'}}>
                                                                                <span className="previous-btn-link" onClick={() => this.ShowFiles(c[0])}>
                                                                                    {Object.keys(fileList).length} Files
                                                                                </span>
                                                                                <button type="button" className="k-upload-selected" onClick={()=> this.onUplaod(b[0] , c[0])}> Upload </button>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })
                                                            })
                                                        }
                                                        
                                                        </div>
                                                        <div className='showfileName'>
                                                        {this.state.docs.map((data) => {
                                                            return (
                                                                <>
                                                                {Object.entries(data).map((item) => {
                                                                    if(item[0] === b[0]) {
                                                                        return (
                                                                            <>
                                                                            {item[1] && Object.entries(item[1]).map((val) => {
                                                                                return (
                                                                                    <>
                                                                                    {val[0] === c[0] ?
                                                                                      <a href='#' type='button' className='previous-btn-link shows' onClick={() => this.uploadFilesShow(b[0],c[0])}>Show Files</a> 
                                                                                      : 
                                                                                          "" 
                                                                                    }
                                                                                    </>
                                                                                )
                                                                            })}
                                                                            </>
                                                                        )
                                                                    }
                                                                    
                                                                })}
                                                                </>
                                                            )
                                                        })}
                                                        </div>
                                                        </>
                                                        ) : ("")}
                                                        </div>
                                                        )}
                                                         </div>
                                        {this.state && this.state.files && this.state.files[b[0]] && this.state.files[b[0]][c[0]] ? 
                                            <div className={"modal fade" + ( c[0] === this.state.fileShowName ? (this.state.fileShowState  ? " show d-block" : " d-none") : '')} tabIndex="-1" role="dialog">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                    <div className="modal-body">
                                                        <form>
                                                            {this.state && this.state.files && this.state.files[b[0]] && this.state.files[b[0]][c[0]] && Object.entries(this.state.files[b[0]][c[0]]).map((key,item1) => {
                                                                    return(
                                                                    (key)?
                                                                        <div>
                                                                            <Row>
                                                                                <Col md="8"> {key[1].name}</Col>
                                                                                <Col md="4"> <span className="col-md-auto" onClick={()=> this.fileRemove(b[0],c[0],key[0])}><i className="fas fa-trash"></i></span></Col>
                                                                            </Row>
                                                                        </div>
                                                                        :
                                                                         ""
                                                                    );
                                                                })
                                                            }
                                                        </form>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <a href="#" className="previous-btn-link text-center link-color text-decoration-underline" onClick={() => this.ShowFiles()}>Close</a>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                            }
                                            <div className={"modal fade" + ( c[0] === this.state.uploadFileName ? (this.state.uploadFile  ? " show d-block" : " d-none") : '')} tabIndex="-1" role="dialog">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                    <div className="modal-body">
                                                        <form>
                                                        {this.state.docsUpload.map((item)=> {
                                                            return (
                                                                <>
                                                                {item.type === c[0] && item.category.toLowerCase() === b[0].toLowerCase() ? 
                                                                <div className='docs-name-x'>
                                                                 <div className='upload-doc-name'>{item.name}</div> 
                                                                 <a href="#" className='previous-btn-link' onClick={() => window.open(item.docLink)}>Download</a>
                                                                 </div>
                                                                  : ""}
                                                                    
                                                                </>
                                                            )
                                                        })}
                                                        </form>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <a href="#" className="previous-btn-link text-center link-color text-decoration-underline" onClick={() => this.uploadFilesShow()}>Close</a>
                                                    </div>
                                                </div>
                                            </div>

                                                                </div>
                                                           
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                            
                        })}
                        <div className="formgroup justify-content-end d-flex">
                            <div className="fieldgroup nav-btn">
                                <a href='./home' className="text-decoration-none text-white">SUBMIT</a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
         );
    }
}
 
export default withRouter(ReviewPage);
// class ReviewPage extends Component {
//         constructor(props) {
//             super(props);
//         }
//         render()
//         {
//             return (
//                 <div>
//                     <h1>hai</h1>
//                 </div>
//             )
//         }
//     }
//     export default ReviewPage;