
import React, { Component } from 'react';
import '../styles/main.scss';
import "bootstrap4/dist/css/bootstrap.min.css";
import Hidenavbar from './Hidenavbar';
import Button from '../functions/button';
import Calc from './Calc';
import { Link } from "react-router-dom";
import ScrollMagic from 'scrollmagic';
/* import splitScroll from '../functions/parallex'; 
 */
import Scrolls from '../functions/technology';
import Laptop from "../assets/Laptop.svg";
import Quick_Process from "../assets/Quick_Process.svg";
import GrowthRate from "../assets/GrowthRate.svg";
import CreditScore from "../assets/CreditScore.svg";
import AdvanceTech from "../assets/AdvanceTech.svg";
import Laptop_mobile from "../assets/Laptop_mobile.png";
import API_Interface from "../assets/API_Interface.svg"
import image1 from "../assets/image1.svg";
import image2 from "../assets/image2.svg";
import image3 from "../assets/image3.svg";
import image4 from "../assets/image4.svg";
import image5 from "../assets/image5.svg";
import image6 from "../assets/image6.svg";
import AIRobot from "../assets/AIRobot.svg";
import ribbonSection from "../assets/ribbonSection.svg";
import ribbon1 from "../assets/ribbon1.svg";
import ribbon2 from "../assets/ribbon2.svg";
import ribbon3 from "../assets/ribbon3.svg";
import DigitisedLoan from "../assets/DigitisedLoan.svg";
import E_Validation from "../assets/E_Validation.svg";
import E_Verification_icon from "../assets/E_Verification_icon.svg";
import ExpandScale from "../assets/ExpandScale.svg";
import AI_ML_icon from "../assets/AI_ML_icon.svg";
import Algorithm from "../assets/Algorithm.svg";
import Mobile_img from "../assets/Mobile_img.svg";
import e_kyc_validation from "../assets/e_kyc_validation.svg";
import E_Verification from "../assets/E_Verification.svg";
import credit_decision_img_1 from "../assets/credit_decision_img_1.svg";
import Adv_Algorithm from "../assets/Adv_Algorithm.svg";
import SwiftAnalysis from "../assets/SwiftAnalysis.svg";
import DigitalFinalised from "../assets/DigitalFinalised.svg"
import credit_score from "../assets/credit_score.png";
import SecureEnv from "../assets/SecureEnv.png";
import SwiftAnalysis_icon from "../assets/SwiftAnalysis_icon.svg";
import E_sign from "../assets/E_sign.svg";
import DigitalOnBoard from "../assets/DigitalOnBoard.png";
import digital_loan_application from "../assets/digital_loan_application.png";
import credit_assesment_img from "../assets/credit_assesment_img.png";
import Safe_secure from "../assets/Safe_secure.png";
import AI_ML from "../assets/AI_ML.png";
import LoanDisbursed_3 from "../assets/LoanDisbursed_3.png";
import LoanDisbursal_mobile from "../assets/LoanDisbursal_mobile.svg";
import credit_decision_mobile from "../assets/credit_decision_mobile.svg";
import credit_assessment_mobile from "../assets/credit_assessment_mobile.svg";
import digitalOnboard_mobile from "../assets/digitalOnboard_mobile.svg";
import CreditScoringLabel from "../assets/CreditScoringLabel.svg"
import App_img1 from "../assets/App_img1.png";
import App_img2 from "../assets/App_img2.svg";
import App_img3 from "../assets/App_img3.svg";
import App_img4 from "../assets/App_img4.svg";
import Union from "../assets/Union.svg";
import { Form, Field } from 'react-final-form';

let flag = 1;
class Technology extends Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }

  componentDidMount = async () => {
    /* if (flag === 1) {
      flag = flag + 1;
 */ document.title = "Finplex: About Us"
      await new ScrollMagic.Scene({
        duration: '2500vh',
        triggerElement: '.parallex-img',
        triggerHook: 0

      })
        .setPin('.parallex-img')

        .addTo(this.controller);
     /*  console.log()
  *///}

  }

  render() {
    const onSubmit = async (values) => {
      try {
        if((values.name !== undefined) && (values.email !== undefined) && (values.mobilenumber !== undefined))
           {
             alert("Form submitted successfully")
           window.location.reload();
           }
           }
      catch (error) {
        console.log(error);
      }
    }

    return (
      <>
        <div className='about'>
          {/* Hello world */}<div className="container ">
            <div className="row">
              <div className="col-12 maincolumns d-none d-sm-block">
                <div className="mainDiv margin-top">
                  <span className="heading text-center px-5">Simplified Digital Lending Process Combining Speed, Safety
                    And Convenience</span>
                  <div className>
                    <img src={Laptop} className="showcase mt-5" style={{ alignSelf: 'center' }} alt="Laptop" />
                  </div>
                </div>
                <div className="absImageWrapper">
                  <img src={Quick_Process} id="leftToRight1" className="image1 animate__animated animate__fadeInLeft" alt="Laptop" />
                </div>
                <div className="absImageWrapper">
                  <img src={GrowthRate} id="leftToRight2" className="image2 animate__animated animate__fadeInLeft" alt="Laptop" />
                </div>
                <div className="absImageWrapper">
                  <img src={CreditScore} id="rightToLeft1" className="image3 animate__animated animate__fadeInRight" alt="Laptop" />
                </div>
                <div className="absImageWrapper">
                  <img src={AdvanceTech} id="rightToLeft2" className="image4 animate__animated animate__fadeInRight" alt="Laptop" />
                </div>
              </div>
              <div className="d-sm-none text-center">
                <div className="row margin-top">
                  <span className="heading text-center">Simplified Digital Lending Process Combining Speed, Safety And
                    Convenience</span>
                  <img src={Laptop_mobile} className="img-fluid mt-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="text-center ">
                <button className="secondary-btn mb-5 ms-md-5 d-inline" style={{ fontWeight: 600, display: 'inline' }} data-bs-toggle="modal" data-bs-target="#Demo">REQUEST A
                  DEMO</button>
                <button className="nav-btn text-white mb-5 ms-md-5 d-inline" style={{ fontWeight: 600, display: 'inline' }} data-bs-toggle="modal" data-bs-target="#myModal"> GET API ACCESS</button>
              </div>
              <div className=" text-center">
              </div>
            </div>
          </div>
          <div className="modal fade " id="Demo">
            <div className="modal-dialog modal-lg">
              <div className="modal-content p-3">
                {/* Modal Header */}
                <div className="modal-header border-0">
                  <p className="sub-heading mt-0">Ready For Your LMS Demo?</p>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" />
                </div>
                {/* Modal body */}
                <div className="modal-body">
                  <Form
                    onSubmit={onSubmit}
                    initialValues={{ name: '', email: '', mobilenumber: '', jobTitle: "", organization: '', message: '' }}
                    validate={values => {
                      const errors = {};

                      function validateEmail(email) {
                        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(String(email).toLowerCase());
                      }

                      function validateTextOnly(data) {
                        var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                        return re.test(String(data));
                      }

                      function validateNumberOnly(data) {
                        var re = /^[0-9]*$/;
                        return re.test(Number(data));
                      }

                      if (!values.firstname) {
                        errors.firstname = "Required";
                      } else if (!validateTextOnly(values.firstname)) {
                        errors.firstname = "Please enter letters only";
                      } else if (values.firstname.length < 3) {
                        errors.firstname = 'Please enter minimum 3 characters';
                      }


                      if (!values.lastname) {
                        errors.lastname = "Required";
                      } else if (!validateTextOnly(values.lastname)) {
                        errors.lastname = "Please enter letters only";
                      } else if (values.lastname.length < 3) {
                        errors.lastname = 'Please enter minimum 3 characters';
                      }

                      if (!values.email) {
                        errors.email = "Required";
                      } else if (!validateEmail(values.email)) {
                        errors.email = "Please enter valid email";
                      }

                      if (!values.mobilenumber) {
                        errors.mobilenumber = "Required";
                      } else if (!validateNumberOnly(values.mobilenumber)) {
                        errors.mobilenumber = "Please enter numbers  only";
                      } else if (values.mobilenumber.length < 10) {
                        errors.mobilenumber = "Phone Number should contain 10 numbers";
                      }

                      if (values.organization) {
                        if (!validateTextOnly(values.organization)) {
                          errors.organization = "Please enter letters only";
                        } else if (values.organization.length < 3) {
                          errors.organization = 'Please enter minimum 3 characters';
                        }
                      }
                      if (values.jobTitle) {
                        if (!validateTextOnly(values.jobTitle)) {
                          errors.jobTitle = "Please enter letters only";
                        } else if (values.jobTitle.length < 3) {
                          errors.jobTitle = 'Please enter minimum 3 characters';
                        }
                      }


                      return errors;
                    }}
                    render={({ handleSubmit, values }) => (
                      <form className="row g-3 field-text" onSubmit={handleSubmit}>
                        <div className="col-md-6 mt-5">
                          <Field
                            name="firstname"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="firstname" className="form-label">
                                  First Name
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-md-6 mt-5">
                          <Field
                            name="lastname"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="lastname" className="form-label">
                                  Last Name
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-md-6 marginTop">
                          <Field
                            name="jobTitle"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="jobTitle" className="form-label">
                                  Job Title  <span className="fontSize">(Optional)</span>
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>

                        <div className="col-md-6 marginTop">
                          <Field
                            name="organization"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="organization" className="form-label">
                                  Organisation  <span className="fontSize">(Optional)</span>
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>


                        <div className="col-md-6 marginTop">
                          <Field
                            name="email"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-md-6 marginTop">
                          <Field
                            name="mobilenumber"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="mobilenumber" className="form-label">
                                  Phone Number
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-12 marginTop">
                          <Field
                            name="message"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="message" className="form-label">
                                  Message  <span className="fontSize">(Optional)</span>
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>


                            )}
                          />
                        </div>
                        <div className="col-12 marginTop flex">
                          <Button
                            type="submit"
                            label="SUBMIT"
                            className="nav-btn float-end content-text mt-3 text-white text-center"
                            handleClick={this.onSubmit}
                          />
                        </div>


                      </form>
                    )}
                  ></Form>
                </div>
              </div>
            </div>
          </div>
          {/* The Modal */}
          <div className="modal fade " id="myModal">
            <div className="modal-dialog modal-lg">
              <div className="modal-content p-3">
                {/* Modal Header */}
                <div className="modal-header border-0">
                  <h4 className="mt-0 sub-heading">Get In Touch</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" />
                </div>
                {/* Modal body */}
                <div className="modal-body">
                  <Form
                    onSubmit={onSubmit}
                    initialValues={{ name: '', email: '', mobilenumber: '', organization: '', message: '' }}
                    validate={values => {
                      const errors = {};

                      function validateEmail(email) {
                        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(String(email).toLowerCase());
                      }

                      function validateTextOnly(data) {
                        var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                        return re.test(String(data));
                      }

                      function validateNumberOnly(data) {
                        var re = /^[0-9]*$/;
                        return re.test(Number(data));
                      }

                      if (!
                        values.name
                      ) {

                        errors.name
                          = "Required";
                      } else if (!validateTextOnly(
                        values.name
                      )) {

                        errors.name
                          = "Please enter letters only";
                      } else if (values.name.length < 3) {

                        errors.name
                          = 'Please enter minimum 3 characters';
                      }

                      if (!
                        values.email
                      ) {

                        errors.email
                          = "Required";
                      } else if (!validateEmail(
                        values.email
                      )) {

                        errors.email
                          = "Please enter valid email";
                      }

                      if (!values.mobilenumber) {
                        errors.mobilenumber = "Required";
                      } else if (!validateNumberOnly(values.mobilenumber)) {
                        errors.mobilenumber = "Please enter numbers  only";
                      } else if (values.mobilenumber.length < 10) {
                        errors.mobilenumber = "Phone Number should contain 10 numbers";
                      }

                      if (values.organization) {
                        if (!validateTextOnly(values.organization)) {
                          errors.organization = "Please enter letters only";
                        } else if (values.organization.length < 3) {
                          errors.organization = 'Please enter minimum 3 characters';
                        }
                      }

                      return errors;
                    }}
                    render={({ handleSubmit, values }) => (
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6 mt-5">
                          <Field
                            name="name"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="name" className="form-label">
                                  Name
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-md-6 marginTop">
                          <Field
                            name="email"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-md-6 marginTop">
                          <Field
                            name="mobilenumber"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="mobilenumber" className="form-label">
                                  Phone Number
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-md-6 marginTop">
                          <Field
                            name="organization"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="organization" className="form-label">
                                  Organisation  <span className="fontSize">(Optional)</span>
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>

                            )}
                          />
                        </div>
                        <div className="col-12 marginTop">
                          <Field
                            name="message"
                            render={({ input, meta }) => (
                              <div>
                                <label htmlFor="message" className="form-label">
                                  Message  <span className="fontSize">(Optional)</span>
                                </label>
                                <input
                                  {...input}
                                  className={
                                    meta.touched && meta.error
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {meta.touched && meta.error && (
                                  <span className="invalid-feedback">{meta.error}</span>
                                )}
                              </div>


                            )}
                          />
                        </div>
                        <div className="col-12 marginTop flex">
                          <Button
                            type="submit"
                            label="SUBMIT"
                            className="nav-btn float-end content-text mt-3 text-white text-center"
                            handleClick={this.onSubmit}
                          />
                        </div>


                      </form>
                    )}
                  ></Form>

                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="tech-bg mt-5">
              <div className="container">
                <div className="row justify-content-center text-center">
                  <p className="heading mt-5 text-white">API And SDK</p>
                  <div className="col-12">
                    <p className="content-text text-center text-white">Efficient and advanced APIs and SDKs that are adaptable, scalable and future-ready for users across the web and mobile.</p>
                    <img src={API_Interface} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container  mt-5">
              <div className="row mt-5">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 firstAutoWrapper">
                      <div className="heading text-center">
                        AI/ML Automation
                      </div>
                      <div className="autoTitleText">
                        We use AI/ML-enabled end-to-end lending process. Leveraging on proven and time tested
                        technology powered by Robotic Process Automation (RPA), Automated Document Recognition,
                        the AI/ML enabled digital environment has enhanced customer satisfaction and helped
                        business loans reach the hitherto subserviced or underserved clientele.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12" style={{ marginBottom: '27px' }}>
                      <div className="row px-3 px-md-0">
                        <div className="secondAutoTitle col-12 center-sm">The AI Automated Workflow Process
                        </div>
                        <div className="col-lg-8 col-12">
                          <div className="row mt-4">
                            <div className="col-2 col-md-1">
                              <img src={image1} alt="" />
                            </div>
                            <div className="col-10 col-md-11">
                              <p className="autoBodyText">Digital onboarding</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2 col-md-1">
                              <img src={image2} alt="" />
                            </div>
                            <div className="col-10 col-md-11">
                              <p className="autoBodyText">High-speed analysis of the client's financial
                                profile</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2 col-md-1">
                              <img src={image3} alt="" />
                            </div>
                            <div className="col-10 col-md-11">
                              <p className="autoBodyText">Offers flexible options of business and professional
                                loans</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2 col-md-1">
                              <img src={image4} alt="" />
                            </div>
                            <div className="col-10 col-md-11">
                              <p className="autoBodyText">Choices of repayment plans</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2 col-md-1">
                              <img src={image5} alt="" />
                            </div>
                            <div className="col-10 col-md-11">
                              <p className="autoBodyText">Optimum interest rates</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2 col-md-1">
                              <img src={image6} alt="" />
                            </div>
                            <div className="col-10 col-md-11">
                              <p className="autoBodyText">Compliant and mandatory features are presented in a
                                safe
                                environment</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12 text-center">
                          <img src={AIRobot} className="autoresizeImg img-fluid" alt="AI Robot" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="changeBodyBackground">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="text-center underWritingText">
                    Underwriting comprises evaluating the creditworthiness of the borrower. The income or the
                    profits of the businessman seeking a loan, the credit score of the borrower, the current
                    debts
                    and liabilities of the borrower, and the value of the collateral are all factors included in
                    the
                    analysis
                  </div>
                </div>
              </div>
            </div>
            <div className="endDivPadding d-none d-sm-block">
              <img src={ribbonSection} alt="ribbonFullSection explaining  Underwriting process" className="resizeRibbonImage" />
            </div>
            <div className="endDivPadding d-sm-none">
              <img src={ribbon3} alt="ribbonFullSection explaining  Underwriting process" className="resizeRibbonImage mb-0" />
              <img src={ribbon1} alt="ribbonFullSection explaining  Underwriting process" className="resizeRibbonImage mb-0" />
              <img src={ribbon2} alt="ribbonFullSection explaining  Underwriting process" className="resizeRibbonImage" />
            </div>
          </div>
          <div className="container">
            <div className="d-none d-lg-block">
              <div className="section">
                <div className="row">
                  <div className="section-left col-lg-5" style={{ marginBottom: '50px' }}>
                    <div id="scrollOne">
                      <div className="section_heading_img">
                      </div>
                      <div className="section_heading mt-1">
                        <p>Digital Onboarding</p>
                      </div>
                      <div className="col">
                        <div className="col-section">
                          <img src={DigitisedLoan} className="mt-4" />
                          <p className="section_heading_1">Digitised Loan Application</p>
                          <div className="content-group">
                            <p className="content-text text-color"> A single-digitised loan application
                              takes
                              care of
                              onboarding to disbursal documentation which is required for a smooth
                              transition
                              from an onlooker to a client.
                            </p>
                          </div>
                          <img src={E_Validation} className="mt-4" />
                          <p className="section_heading_1">E- KYC Validation</p>
                          <div className="content-group">
                            <p className="content-text text-color">The uploading of E - Know Your Customer
                              (E- KYC) documents in Digital format validates the identity of the
                              client
                              borrower in an incredibly simple and smart manner.
                            </p>
                          </div>
                          <img src={E_Verification_icon} className="mt-4" />
                          <p className="section_heading_1">E-Verification</p>
                          <div className="content-group">
                            <p className="content-text text-color">E- KYC comprises of online photo and
                              video
                              check, bank
                              account number, aadhar and Pancard verification.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div id="scrollTwo">
                      <div className="section_heading_group">
                        <div className="section_heading">
                          <p>Credit Assessment
                            With Credit Scoring</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-section">
                          <img src={credit_score} className="mt-4" />
                          <p className="section_heading_1">Credit Scoring</p>
                          <div className="content-group">
                            <p className="content-text text-color">Credit scoring is achieved by multiple
                              data
                              analyses
                              from
                              multiple financial sources and digital footprints to give a complete
                              360°
                              analysis report on the profile of the borrower and the creditworthiness
                              of
                              the
                              business. </p>
                          </div>
                          <img src={SecureEnv} className="mt-4" />
                          <p className="section_heading_1">Secure Environment</p>
                          <div className="content-group">
                            <p className="content-text text-color">Offers a compliant and secure environment
                              for
                              the
                              clients.
                            </p>
                          </div>
                          <img src={ExpandScale} className="mt-4" />
                          <p className="section_heading_1">Expands The Scale</p>
                          <div className="content-group">
                            <p className="content-text text-color">Expands the scale of lending with minimal
                              risk to
                              Financial
                              Institutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div id="scrollThree">
                      <div className="section_heading_group">
                        <div className="section_heading">
                          <p>Credit Decisioning</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-section">
                          <img src={AI_ML_icon} className="mt-4" />
                          <p className="section_heading_1">AI/ML Proficiency </p>
                          <div className="content-group">
                            <p className="content-text text-color">AI/ML proficiency enhances the credit
                              quality
                              of the
                              Financial institution and acceptance rates of the entrepreneurs seeking
                              loans.
                            </p>
                          </div>
                          <img src={Algorithm} className="mt-4" />
                          <p className="section_heading_1">Advanced Algorithm</p>
                          <div className="content-group">
                            <p className="content-text text-color">It brings loans to customers almost instantly
                              while
                              Financial institutions are saved high credit risks because of accuracy
                              in
                              customer profiling brought  by the advanced algorithm-based credit
                              decisioning.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="section_heading_group">
                      <div className="section_heading">
                        <p>Loan Disbursal</p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="col-section">
                        <img src={SwiftAnalysis_icon} className="mt-4" />
                        <p className="section_heading_1">Swift Analysis </p>
                        <div className="content-group">
                          <p className="content-text text-color">The efficiency of loan disbursal is marked by
                            efficiency in
                            swift analysis and deployments of credit.</p>
                        </div>
                        <img src={E_sign} className="mt-4" />
                        <p className="section_heading_1">E-Sign </p>
                        <div className="content-group">
                          <p className="content-text text-color">Loan agreements can be signed by finalised
                            with
                            E-signatures
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 col-xl-2" />
                  <div className="section-right col-lg-5" style={{ justifyContent: 'center', marginTop: '50px' }}>
                    <div className>
                      <div className="col parallex-img paddingTop">
                        <div className="section-image">
                          <img src={Mobile_img} className="img-fluid " style={{ width: '280px', height: '540px', position: 'relative' }} />
                        </div>
                        <div id="displayTwo">
                          <img src={DigitalOnBoard} className="digital-img animate__animated animate__fadeInRight" />
                          <img src={digital_loan_application} className="left-ani-1 animate__animated animate__fadeInUp animate__delay-1s" />
                          <img src={e_kyc_validation} className="right-ani-1 animate__animated animate__fadeInUp animate__delay-2s" />
                          <img src={E_Verification} className="right-ani-2 animate__animated animate__fadeInUp animate__delay-3s" />
                        </div>
                        <div id="displayOne" style={{ display: 'none' }}>
                          <img src={credit_assesment_img} className="digital-img animate__animated animate__fadeInRight" />
                          <img src={CreditScoringLabel} className="ass-right-ani-1 animate__animated animate__fadeInUp animate__delay-1s" />
                          <img src={Safe_secure} className="ass-left-ani-2 animate__animated animate__fadeInUp animate__delay-2s" />
                        </div>
                        <div id="displayThree" style={{ display: 'none' }}>
                          <img src={credit_decision_img_1} className="digital-img animate__animated animate__fadeInRight" />
                          <img src={AI_ML} className="dec-left-ani animate__animated animate__fadeInUp animate__delay-1s" />
                          <img src={Adv_Algorithm} className="dec-right-ani animate__animated animate__fadeInUp animate__delay-2s" />
                        </div>
                        <div id="displayFour" style={{ display: 'none' }}>
                          <img src={LoanDisbursed_3} className="Loan-img digital-img animate__animated animate__fadeInRight" />
                          <img src={SwiftAnalysis} className="loan-left-ani2 animate__animated animate__fadeInUp animate__delay-1s" />
                          <img src={DigitalFinalised} className="loan-right-ani animate__animated animate__fadeInUp animate__delay-2s" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  <splitScroll/>
           */}  <div className="d-lg-none mt-5">
              <div className="section px-3 ">
                <div className="row">
                  <div className="section_heading mt-1">
                    <p>Digital Onboarding</p>
                  </div>
                  <div className="section-right text-center" style={{ justifyContent: 'center' }}>
                    <img src={digitalOnboard_mobile} className="mt-4 img-fluid" />
                  </div>
                  <div className="col">
                    <div className="col-section">
                      <img src={DigitisedLoan} className="mt-5" />
                      <p className="section_heading_1">Digitised Loan Application</p>
                      <div className="content-group">
                        <p className="content-text text-color"> A single-digitised loan application takes care
                          of
                          onboarding to disbursal documentation which is required for a smooth
                          transition
                          from an onlooker to a client.
                        </p>
                      </div>
                      <img src={E_Validation} className="mt-4" />
                      <p className="section_heading_1">E- KYC Validation</p>
                      <div className="content-group">
                        <p className="content-text text-color">The uploading of E - Know Your Customer
                          (E- KYC) documents in Digital format validates the identity of the client
                          borrower in an incredibly simple and smart manner.
                        </p>
                      </div>
                      {/*   <img src="./assets/credits 1.svg" class="mt-4" />
                            <p class="section_heading_1">Neo-Credits</p>

                            <div class="content-group">
                                <p class="content-text text-color">Opens up opportunities for neo-credits for the
                                    conventionally unbanked sector of the economy too.

                                </p>
                            </div>
                           */} <img src={E_Verification_icon} className="mt-4" />
                      <p className="section_heading_1">E-Verification</p>
                      <div className="content-group">
                        <p className="content-text text-color">E- KYC comprises of online photo and video check,
                          bank
                          account number, aadhar and Pancard verification.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="section_heading">
                    <p>Credit Assessment
                      With Credit Scoring</p>
                  </div>
                  <div className="section-right  text-center" style={{ justifyContent: 'center' }}>
                    <img src={credit_assessment_mobile} className="mt-4 img-fluid" />
                  </div>
                  <div className="col mt-4">
                    <div className="col-section">
                      <img src={credit_score} className="mt-4" />
                      <p className="section_heading_1">Credit Scoring</p>
                      <div className="content-group">
                        <p className="content-text text-color">Credit scoring is achieved by multiple data
                          analyses
                          from
                          multiple financial sources and digital footprints to give a complete 360°
                          analysis report on the profile of the borrower and the creditworthiness of
                          the
                          business. </p>
                      </div>
                      <img src={SecureEnv} className="mt-4" />
                      <p className="section_heading_1">Secure Environment</p>
                      <div className="content-group">
                        <p className="content-text text-color">Offers a compliant and secure environment for the
                          clients.
                        </p>
                      </div>
                      <img src={ExpandScale} className="mt-4" />
                      <p className="section_heading_1">Expands The Scale</p>
                      <div className="content-group">
                        <p className="content-text text-color">Expands the scale of lending with minimal risk to
                          Financial
                          Institutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="section_heading">
                    <p>Credit Decisioning</p>
                  </div>
                  <div className="section-right text-center" style={{ justifyContent: 'center' }}>
                    <img src={credit_decision_mobile} className="mt-4 img-fluid" />
                  </div>
                  <div className="col mt-4">
                    <div className="col-section">
                      <img src={AI_ML_icon} className="mt-4" />
                      <p className="section_heading_1">AI/ML Proficiency </p>
                      <div className="content-group">
                        <p className="content-text text-color">AI/ML proficiency enhances the credit quality of
                          the
                          Financial institution and acceptance rates of the entrepreneurs seeking
                          loans.
                        </p>
                      </div>
                      <img src={Algorithm} className="mt-4" />
                      <p className="section_heading_1">Advanced Algorithm</p>
                      <div className="content-group">
                        <p className="content-text  text-color">It brings loans to customers almost instantly
                          while
                          Financial institutions are saved high credit risks because of accuracy in
                          customer profiling brought  by the advanced algorithm-based credit
                          decisioning.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="section_heading">
                    <p>Loan Disbursal</p>
                  </div>
                  <div className="section-right text-center" style={{ justifyContent: 'center' }}>
                    <img src={LoanDisbursal_mobile} className="mt-4 img-fluid" />
                  </div>
                  <div className="col mt-4">
                    <div className="col-section">
                      {/*   <img src="./assets/AI_ML (1).svg" class="mt-4" />
                            <p class="section_heading_1">Quick Process</p>

                            <div class="content-group">
                                <p class="content-text text-color">The Turn around time (TAT) to a loan query is
                                    within
                                    24
                                    hours
                                </p>
                            </div>
                           */} <img src={SwiftAnalysis_icon} className="mt-4" />
                      <p className="section_heading_1">Swift Analysis </p>
                      <div className="content-group">
                        <p className="content-text text-color">The efficiency of loan disbursal is marked by
                          efficiency in
                          swift analysis and deployments of credit.</p>
                      </div>
                      <img src={E_sign} className="mt-4" />
                      <p className="section_heading_1">E-Sign</p>
                      <div className="content-group">
                        <p className="content-text text-color">Loan agreements can be signed by finalised with
                          E-signatures
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid px-md-5 px-lg-0 ">
            <div className="col-12 px-3">
              <div className="row justify-content-center mt-5 px-xxl">
                <div className="col-lg-8 text-center px-md-5">
                  <p className="heading text-center">Know App Better</p>
                  <p className="content-text text-color text-md-center mt-3">
                    A Loan Management System (LMS) is web-based technology used to plan, implement and assess a
                    specific loan process
                  </p>
                </div>
              </div>
              <div className="row justify-content-lg-center mt-3 px-xxl">
                <div className="col-md-4  col-lg-2 px-xl-4">
                  <div className="row mt-4 mt-md-0">
                    <div className="col-1 mt-md-2">
                      <img src={App_img1} />
                    </div>
                  </div>
                  <div className="row">
                    <p className="section_heading_1 mt-3">Quick Process</p>
                  </div>
                  <div className="row">
                    <p className="content-text text-color">The Turn Around Time (TAT) to a loan query is within 24
                      hours. We
                      have the state-of-the-art Application designed by
                      configuration
                      experts to seamlessly integrate loan processing from origination to disbursal. </p>
                  </div>
                </div>
                <div className="col-md-4  col-lg-2 px-xl-4">
                  <div className="row mt-4 mt-md-0">
                    <div className="col-1">
                      <img src={App_img2} />
                    </div>
                  </div>
                  <p className="section_heading_1 mt-3">Accreditation</p>
                  <div className="row">
                    <p className="content-text text-color">The architecture and the swift neural algorithm
                      processes
                      data from
                      multiple sources from accredited bureaus and offer a Credit Assessment Memo (CAM) report
                    </p>
                  </div>
                </div>
                <div className="col-md-4  col-lg-2 px-xl-4">
                  <div className="row mt-4 mt-md-0">
                    <div className="col-1 mt-2">
                      <img src={App_img3} />
                    </div>
                  </div>
                  <div className="row">
                    <p className="section_heading_1 mt-3">Compliant </p>
                    <p className="content-text text-color">GDPR-compliant platform,
                      certified for ISO
                      27001 and ISO 9001. It leverages architecture that conforms to guidelines issued by NIST and
                      OWASP. </p>
                  </div>
                </div>
                <div className="col-md-4  col-lg-2 px-xl-4">
                  <div className="row mt-4 mt-md-0">
                    <div className="col-1 mt-1">
                      <img src={App_img4} />
                    </div>
                  </div>
                  <div className="row">
                    <p className="section_heading_1 mt-3">Quick Deposit</p>
                    <p className="content-text text-color">Easy transition from loan
                      origination
                      to credit
                      decisions and online loan disbursal to the client's account. The Application can be
                      accessed by
                      clients from mobiles too.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2 px-xl-4">
                  <div className="row mt-4 mt-md-0">
                    <div className="col-1 mt-3">
                      <img src={Union} />
                    </div>
                  </div>
                  <div className="row">
                    <p className="section_heading_1 mt-3">E-NACH</p>
                    <p className="content-text text-color">
                      Digital repayment mandate with E - National Automated Clearing House (E - NACH) introduced
                      by
                      the National Payments Corporation of India (NPCI) is part of the process.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrolls />
          <Hidenavbar />
        </div>
      </>

    )
  }
};
export default Technology;
