import { CognitoRefreshToken, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from "aws-amplify";

const userName = 'webuser';
const password = 'Finplex1!';
export const COGNITO = {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_7UjDu4tzo",
    APP_CLIENT_ID: "53b99fk0mun5265u0ehb6r4q51",
    DOMAIN: "https://finplex-sso.auth.ap-south-1.amazoncognito.com",
    TOKEN_URL: '/oauth2/token',
    GETUSER_URL: "/oauth2/userInfo"
};

let accessTok;
const getCognitoUser = function(req) {
    const poolData = {
        UserPoolId: COGNITO.USER_POOL_ID,
        ClientId: COGNITO.APP_CLIENT_ID
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
        Username: req,
        Pool: userPool
    };
    return new CognitoUser(userData);
};

export const accessToken = () => {
    setTimeout(function() {
        let cognitoUser = getCognitoUser(userName);
        var token = new CognitoRefreshToken({ RefreshToken: window.sessionStorage.getItem("refreshToken") })
        cognitoUser.refreshSession(token, function(err, session) {
            accessTok = session.accessToken.jwtToken;
            window.sessionStorage.setItem("accessToken", accessTok);
        })
    }, 2500);
    return window.sessionStorage.getItem("accessToken");
}

Amplify.configure({ userPoolId: COGNITO.USER_POOL_ID, userPoolWebClientId: COGNITO.APP_CLIENT_ID });
Auth.configure({ userPoolId: COGNITO.USER_POOL_ID, userPoolWebClientId: COGNITO.APP_CLIENT_ID });
Auth.signIn(userName, password).then(
function(response) {
    setTimeout(function() {
        window.sessionStorage.setItem("refreshToken", response.signInUserSession.refreshToken.token);
    }, 1000);
});