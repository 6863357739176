import React from "react";
import Group4 from "../assets/Group (4).svg";
import Vector2 from "../assets/Vector (2).svg";
import FinplexLogo from "../assets/Finplex-Logo.svg";
import linkedin from "../assets/linkedin.svg";
import fb from "../assets/Fb.svg";
import instagram from "../assets/instagram-Green 1.svg";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div className="fontStyle">
        <section>
          <div className="bg">
            <div className="container">
              <div className="col-12">
                <div className="row text-white px-3 px-md-0">
                  <div className="col-sm-6 col-md-3 col-lg-3 px-2">
                    <div className="mt-5">
                      <p className="footer-heading mb-2">Have Any Questions?</p>
                      <div>
                        <img className="d-inline mt-3" alt="" src={Group4} />
                        <p className="footer-text d-inline mx-1">Call Us</p>
                        <div className="footer-margin">
                          {" "}
                          <a
                            className="footer-text2 text-decoration-none text-white footer-underline"
                            href="tel:18002701233"
                          >
                            1800-2701-233
                          </a>
                        </div>
                      </div>
                      <div className="mt-2">
                        <img
                          className="d-inline mx-2 mx-md-1"
                          alt=""
                          src={Vector2}
                        />
                        <a
                          href="mailto:help@finplex.in"
                          className="footer-text footer-underline text-decoration-none text-white d-inline md-mx-3"
                        >
                          Help@Finplex.in
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 px-3">
                    <div className="mt-5">
                      <p className="footer-heading"> Loans</p>
                      <p>
                        <Link
                          to="/securedLoans"
                          className="text-decoration-none text-white footer-underline"
                        >
                          Secured Loans
                        </Link>
                      </p>
                      <p>
                        <Link
                          to="/unSecuredLoan"
                          className="text-decoration-none text-white footer-underline"
                        >
                          Unsecured Loans
                        </Link>
                      </p>
                      {/*  */}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-3 px-3 px-lg-3">
                    <div className="mt-5">
                      <p className="footer-heading">Company</p>
                      <p className="footer-text1">
                        <Link
                          to="/aboutus"
                          className="text-decoration-none text-white footer-underline"
                        >
                          About Us
                        </Link>
                      </p>
                      <p>
                        <a
                          href="https://finplexcareersdev.azurewebsites.net/"
                          className="text-decoration-none text-white footer-underline"
                          rel="noopener noreferrer"
                        >
                          Career
                        </a>
                      </p>
                      <p>
                        <Link
                          to="/customerSupport"
                          className="text-decoration-none text-white footer-underline"
                        >
                          Customer Support
                        </Link>
                      </p>
                      <p>
                        <Link
                          to="/freqaskedques"
                          className="text-decoration-none text-white footer-underline"
                        >
                          FAQ
                        </Link>
                      </p>
                      <p>
                        <Link
                          to="/blog"
                          className="text-decoration-none text-white  footer-underline"
                        >
                          Blogs
                        </Link>
                      </p>
                      <p>
                        <Link
                          to="/calculator"
                          className="text-decoration-none text-white footer-underline"
                        >
                          Calculator
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3 col-lg-3 px-3 px-md-3 px-lg-3 policy-top">
                    <div className="mt-5" /* float-md-end me-lg-3 */>
                      <p>
                        <Link
                          className="text-decoration-none text-white footer-text1 footer-underline"
                          to="/privacypolicy"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                      <p className="footer-text1">
                        <Link
                          to="/termsandcondition"
                          className="text-decoration-none text-white footer-underline"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </p>
                      <p className="footer-text1">
                        <Link
                          className="text-decoration-none text-white footer-underline"
                          to="/termsofuse"
                        >
                          Terms Of Use
                        </Link>
                      </p>
                      <p className="footer-text1">
                        <Link
                          className="text-decoration-none text-white footer-underline"
                          to="/grievanceRedressal"
                        >
                          Grievance Redressal
                        </Link>
                      </p>
                      <p className="footer-text1">
                        <Link
                          className="text-decoration-none text-white footer-underline"
                          to="/codeOfConduct"
                        >
                          Code of Conduct
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="d-none d-md-block mt-5">
                    <div className="row ">
                      <div className="col-5 col-xl-6">
                        <Link to="/">
                          <img
                            className="img-fluid brand-img ms-xl-2 mt-3"
                            alt=""
                            src={FinplexLogo}
                          />
                        </Link>
                      </div>
                      <div className="col-md-3 " />
                      <div className="col-md-4 col-xl-3 mt-2 mt-xl-3 px-lg-3 d-flex justify-content-md-end justify-content-lg-start">
                        <p className="footer-txt d-inline  text-white mt-3">
                          Follow Us
                        </p>
                        <a
                          href="https://www.linkedin.com/company/finplex-technologies/posts/?feedView=all"
                          target="_blank"
                        >
                          <img
                            className="d-inline ms-2 mt-3"
                            alt=""
                            src={linkedin}
                          />
                        </a>
                        <a
                          href="https://www.facebook.com/profile.php?id=100087087904323&sk=photos"
                          target="_blank"
                        >
                          {" "}
                          <img className="d-inline ms-2 mt-3" alt="" src={fb} />
                        </a>
                        {/* <a
                          href="https://www.instagram.com/finplex.technologies/"
                          target="_blank"
                        >
                          <img
                            className="d-inline ms-2 pe-lg-3 pe-xl-0  mt-3"
                            alt=""
                            src={instagram}
                          />
                        </a> */}
                      </div>
                    </div>
                    <div className="row mt-5">
                      <hr className="hr" />
                      <p className="copyright ms-xl-1">
                        {" "}
                        © Copyright <span>{currentYear}</span> | Finplex
                      </p>
                    </div>
                  </div>
                  <div className="row  d-md-none mt-5">
                    <div className=" col-12 col-md-3   d-flex justify-content-center text-center">
                      <p className="footer-txt d-inline  text-white mt-2 lh-1 ms-4">
                        Follow Us
                      </p>
                      <a
                        href="https://www.linkedin.com/company/finplex-technologies/posts/?feedView=all"
                        target="_blank"
                      >
                        <img
                          className="d-inline px-1 ms-2 mt-2"
                          alt=""
                          src={linkedin}
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100087087904323&sk=photos"
                        target="_blank"
                      >
                        <img className="d-inline px-1 mt-2" alt="" src={fb} />
                      </a>
                      {/* <a
                        href="https://www.instagram.com/finplex.technologies/"
                        target="_blank"
                      >
                        <img className="d-inline mt-2" alt="" src={instagram} />
                      </a> */}
                    </div>
                    <div className="col-12 mt-4 text-center ms-2">
                      <Link to="/">
                        {" "}
                        <img
                          className="img-fluid mt-5"
                          alt=""
                          src={FinplexLogo}
                        />
                      </Link>
                      <hr className="text-center hr ms-1" />
                      <p className="copyright ms-2 text-center">
                        {" "}
                        © Copyright <span>{currentYear}</span> | Finplex
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
