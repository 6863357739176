 
import * as $ from 'jquery';
import * as jquery from 'jquery';

// export for others scripts to use
window.$ = $;
window.jquery =jquery;


function Scrolls(){
$(window).on('scroll', function() {
    const element = document.querySelector("#scrollOne");
    const elementTwo = document.getElementById("scrollTwo");
    const elementThree = document.getElementById("scrollThree");
    // const elementFour = document.getElementById("scrollFour");
    let y = element.getBoundingClientRect().top;
    let ytwo = elementTwo.getBoundingClientRect().top;
    let ythree = elementThree.getBoundingClientRect().top;
    let yfour = elementThree.getBoundingClientRect().bottom;
    let z = element.getBoundingClientRect().bottom;
    if (y > 0) {
        $("#displayTwo").css({
            "display": "block"
        });
    }
    else if (y < 0) {
        $("#displayTwo").css({
            "display": "block"
        });
    }
    else {
        $("#displayTwo").css({
            "display": "none"
        });
    }
    if (ytwo < 0) {
        $("#displayOne").css({
            "display": "block"
        });
        $("#displayTwo").css({
            "display": "none"
        });
    } else {
        $("#displayOne").css({
            "display": "none"
        });
    }
    if (ythree < 0) {
        $("#displayThree").css({
            "display": "block"
        });
        $("#displayOne").css({
            "display": "none"
        });
    } else {
        $("#displayThree").css({
            "display": "none"
        });
    }
    if (yfour < 0) {
        $("#displayFour").css({
            "display": "block"
        });
        $("#displayThree").css({
            "display": "none"
        });
    } else {
        $("#displayFour").css({
            "display": "none"
        });
    }
    console.log(y.toFixed(1), ytwo.toFixed(1), ythree.toFixed(1), yfour.toFixed(1));
});

}
export default Scrolls;