import React, { Component } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";


class Calc extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      amountValue: 1000000,
      rateOfInterestValue: 12.75,
      tenureValue: 36,
      EMI: "",
      totalAmount: "",
      interestValue: "",
    };
  }

  componentDidMount = () => {
    this.calculateEMI(
      this.state.amountValue,
      this.state.rateOfInterestValue,
      this.state.tenureValue
    );
  };

  handleAmountChange = async (value) => {
    await this.setState({ amountValue: value });
    await this.calculateEMI(
      this.state.amountValue,
      this.state.rateOfInterestValue,
      this.state.tenureValue
    );
  };

  handleROIChange = async (value) => {
    await this.setState({ rateOfInterestValue: value.toFixed(2) });
    await this.calculateEMI(
      this.state.amountValue,
      this.state.rateOfInterestValue,
      this.state.tenureValue
    );
  };

  handleTenureChange = async (value) => {
    await this.setState({ tenureValue: value });
    await this.calculateEMI(
      this.state.amountValue,
      this.state.rateOfInterestValue,
      this.state.tenureValue
    );
  };

  calculateEMI = async (principalAmount, rateOfInterest, loanTenure) => {
    let rateOfInterestVal = rateOfInterest / 12 / 100;
    let EMI = await ((principalAmount *
      rateOfInterestVal *
      Math.pow(1 + rateOfInterestVal, loanTenure)) /
      (Math.pow(1 + rateOfInterestVal, loanTenure) - 1));
    let totalAmount = EMI * loanTenure;
    let interestValue = totalAmount - principalAmount;
    await this.setState({
      EMI: Math.round(EMI),
      totalAmount: Math.round(totalAmount),
      interestValue: Math.round(interestValue),
    });
  };

  covertIndianRupeeFormat = (value) => {
    var x = value;
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    // alert(res);
    return res.toString();
  };

  render() {
    return (
      <div style={{ marginTop: 50 }}>
        <>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-7 col-md-5 pe-md-5 col-sm-11 col-12">
              <div className="slider">
                <div className="subHeading">Loan Amount</div>
                <div className="subHeadingValues">
                  &#8377;{this.covertIndianRupeeFormat(this.state.amountValue)}
                  .00
                </div>
                <Slider
                  min={300000}
                  max={8000000}
                  value={this.state.amountValue}
                  tooltip={false}
                  step={10000}
                  onChange={this.handleAmountChange}
                />
                <div className="d-flex flex-row justify-content-between minmaxValues">
                  <div className="align-items-start">3 Lakhs</div>
                  <div className="align-items-end">80 Lakhs</div>
                </div>
              </div>
              <div className="slider pt-3">
                <div className="subHeading">
                  Rate of Interest (In Percentage)
                </div>
                <div className="subHeadingValues">
                  {this.state.rateOfInterestValue}
                </div>
                <Slider
                  min={10.75}
                  max={19.75}
                  value={this.state.rateOfInterestValue}
                  tooltip={false}
                  step={0.05}
                  onChange={this.handleROIChange}
                />
                <div className="d-flex flex-row justify-content-between minmaxValues">
                  <div className="align-items-start">10.75 %</div>
                  <div className="align-items-end">19.75 %</div>
                </div>
              </div>
              <div className="slider pt-3">
                <div className="subHeading">Tenure (Months)</div>
                <div className="subHeadingValues">{this.state.tenureValue}</div>
                <Slider
                  min={12}
                  max={84}
                  value={this.state.tenureValue}
                  step={1}
                  tooltip={false}
                  onChange={this.handleTenureChange}
                />
                <div className="d-flex flex-row justify-content-between minmaxValues">
                  <div className="align-items-start">12 M</div>
                  <div className="align-items-end">84 M</div>
                </div>
              </div>
            </div>
            {/* <div className='col-lg-1 col-md-1 col-sm-1 col-1'></div> */}

            <div className="col-lg-4 col-md-5 col-sm-12 col-12 cardOutline mt-4 mb-5 mt-md-0 mt-lg-0">
              <div className="row mt-5">
                <div className="col-1"></div>
                <div className="col-10 mt-md-4 text-center">
                  <span className="cardSmallText ">Total Amount</span>
                  <br />
                  <span className="cardAmount">
                    &#8377;
                    {this.covertIndianRupeeFormat(this.state.totalAmount)}.00
                  </span>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row" style={{ marginTop: 50 }}>
                <div className="col-1"></div>
                <div className="col-md-5 col-lg-5 col-12 col-sm-12 text-center text-sm-center text-md-left text-md-left">
                  <span className="cardSmallText">EMI Amount</span>
                  <br />
                  <span className="cardAmount">
                    &#8377;{this.covertIndianRupeeFormat(this.state.EMI)}.00
                  </span>
                </div>
                <div className="col-md-5 mb-5 mb-md-0 col-lg-5 col-12 col-sm-12 text-center text-sm-center text-md-left text-md-left mt-5 mt-md-0 mt-lg-0">
                  <span className="cardSmallText ">Interest Payable</span>
                  <br />
                  <span className="cardAmount">
                    &#8377;
                    {this.covertIndianRupeeFormat(this.state.interestValue)}.00
                  </span>
                </div>
                <div className="col-1"></div>
              </div>

              <div
                className="row text-center"
                style={{ marginTop: 25, marginBottom: 25 }}
              >
                {/* <a href="#" target="_blank" className='applyNowLink'>APPLY NOW</a> */}
              </div>
            </div>
          </div>
        </>
      </div>
      
    );
  }
}

export default Calc;