import React from 'react';
import QuickEasy24hrsicon1 from '../assets/QuickEasy24hrs_icon 1.svg';
import CompetitiveRatesicon1 from '../assets/CompetitiveRates_icon 1.svg';
import Repaymenticon1 from '../assets/Repayment_icon 1.svg';
import DedicatedAdvisorsicon1 from '../assets/DedicatedAdvisors_icon 1.svg';

export default class differentfrom extends React.Component {
    render(){
    return (
        <>
            <div>
                <div className="container-xxl container-xl container-lg container-md d-md-none">
                    <p className="sub-title text-center px-5">How Are We Differ From Others?</p>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className=" col-sm-9 col-xl-10 mt-5 flex">
                            <div className="  pill my-3  position-relative">
                                <p className="pill-text "> Quick &amp; Easy Approval </p>
                                <p className="pill-innertext  ">Approval is done as quickly as 24 hours with our online process.</p>
                                <img className="position-absolute top-0 start-50  translate-middle pill-icon" 
                                src={QuickEasy24hrsicon1} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center ">
                        <div className=" col-sm-9 col-xl-10 mt-5 flex">
                            <div className="  pill my-3 position-relative">
                                <p className="pill-text  "> Competitive Interest Rates </p>
                                <p className="pill-innertext  ">
                                    We match the loan to your needs with competitive interest rates.</p>
                                <img className="position-absolute top-0 start-50  translate-middle pill-icon" 
                                src={CompetitiveRatesicon1} alt ='' />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center  ">
                        <div className=" col-sm-9 col-xl-10 mt-5 flex">
                            <div className="  pill my-3  position-relative">
                                <p className="pill-text  ">Flexible Repayment Options </p>
                                <p className="pill-innertext  ">
                                    Options to choose your convenient and flexible repayment option. This allows you to change plans of repayment at any time.</p>
                                <img className="position-absolute top-0 start-50  translate-middle pill-icon "
                                 src={Repaymenticon1} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center  ">
                        <div className=" col-sm-9 col-xl-10 mt-5 flex">
                            <div className=" pill my-3 position-relative">
                                <p className="pill-text  "> Personal Account Advisor </p>
                                <p className="pill-innertext ">You will have a committed Account Advisor who will work with zeal and focus to guide you through the process and clarify any queries you may have along the way.
                                </p>
                                <img className="position-absolute top-0 start-50  translate-middle pill-icon" src={DedicatedAdvisorsicon1} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container-xxl container-xl container-lg container-md  d-none d-md-block">
                        <p className="mt-5" />
                        <p className="sub-title  text-center">How Are We Differ From Others?</p>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-10 mt-5">
                                <div className=" rounded-pill pill my-3 p-lg-4 position-relative">
                                    <p className="pill-text px-5"> Quick &amp; Easy Approval </p>
                                    <p className="pill-innertext px-5"> Approval is done as quickly as 24 hours with our online process.
                                    </p>
                                    <img className="position-absolute top-50 start-20  translate-middle " src={QuickEasy24hrsicon1} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-10 mt-5">
                                <div className=" rounded-pill pill my-3 p-lg-4 position-relative">
                                    <p className="pill-text px-5 "> Competitive Interest Rates </p>
                                    <p className="pill-innertext px-5 ">
                                        We match the loan to your needs with competitive interest rates.</p>
                                    <img className="position-absolute top-50 start-20  translate-middle " 
                                    src={CompetitiveRatesicon1} alt ='' />
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-10 mt-5">
                                <div className=" rounded-pill pill my-3 p-lg-4 position-relative">
                                    <p className="pill-text px-5 ">Flexible Repayment Options </p>
                                    <p className="pill-innertext px-5 ">
                                        Options to choose your convenient and flexible repayment option. This allows you to change plans of repayment at any time.</p>
                                    <img className="position-absolute top-50 start-20  translate-middle " 
                                    src={Repaymenticon1} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-10 mt-5">
                                <div className=" rounded-pill pill my-3 p-lg-4 position-relative">
                                    <p className="pill-text px-5 "> Personal Account Advisor </p>
                                    <p className="pill-innertext px-5 "> You will have a committed Account Advisor who will work with zeal and focus to guide you through the process and clarify any queries you may have along the way.</p>
                                    <img className="position-absolute top-50 start-20  translate-middle "
                                     src={DedicatedAdvisorsicon1} alt='' />
                                </div>
                            </div>
                        </div>
                        <p className="mb-5" />
                    </div>
                </section></div>
        </>);}
}