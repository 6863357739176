import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../assets/BlogarticleBanner.jpg";
import Articles1 from "../assets/Article1.jpg";
import Articles2 from "../assets/Article2.png";
import Articles3 from "../assets/Article3.png";
import Date from "../assets/calender_icon.png";
import { Form, Field } from 'react-final-form';
import Button from "../functions/button";
import arrow from "../assets/arrow.png";
// import HomeNews from "./homeNews";
import CaseStudies from "./caseStudies";
import MarketNews from "./marketNews";
import Events from "./events";
import BlogBanner from "../assets/Blog-vector.svg";
import BlogMobileBanner from "../assets/Blog_Mobile_Banner.png";


const BlogArticle=(props)=> {
  useEffect(() => {
    document.title = "Finplex: Blog";
  }, []);
  const onSubmit = async (values) => {
    try {
      if (values.email !== undefined) {
        alert("Form submitted successfully")
        window.location.reload();
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  return (
    <>
    
    <div className="container banner-top">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6">
          <p className="heading center-text md-center">Blog</p>
          <p className="blogBannerText  center-text md-center">Read the Trendy and Informative Articles</p>               
        </div>
        <div className="col-lg-6">
        <img className="img-fluid banner-img" src={BlogBanner} />              
        </div>
      </div>
    </div>    
    
           
            <section className=" continer-xxl m-auto ">
            <p className="sub-title text-center pt-5">Trending</p>               
               <CaseStudies noOfTiles='3' MarketCaseStudy={props.insightsprops} />
                <div className="d-flex mt-md-4 justify-content-center">
                <Link className="text-decoration-none" to="/trending"><button className="d-flex align-items-center text-decoration-none  justify-content-center secondary-btn ">Read More</button></Link>
  
                </div>
            </section>      

            <div className="bg-light_gray" >
            <p className="sub-title text-center pt-5"> Banking</p>              
                <MarketNews noOfTiles='3' MarketNews={props.newsprops}/>
                
                <div className="d-flex mt-md-4 justify-content-center">
                <Link className="text-decoration-none" to="/banking"><button className="d-flex align-items-center text-decoration-none  justify-content-center secondary-btn ">Read More</button></Link>
  
                </div>
            </div>
           

            <section>
            <p className="sub-title text-center pt-5">Technology</p>       
                <Events noOfTiles="3" MarketEvents={props.eventsprops} />
                
                <div className="d-flex mt-md-4 justify-content-center">
                <Link className="text-decoration-none" to="/technologys"><button className="d-flex align-items-center text-decoration-none  justify-content-center secondary-btn ">Read More</button></Link>
  
                </div>
            </section>
    </>
  );
}
export default BlogArticle;