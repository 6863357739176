import React from "react";
import TabsComponent from "./tabNavigation";
import MarketNews from "./marketNews";
// import Contact from "./contactUs";
// import NewsHeader from "../assets/NewsHeader.jpg";
// import News1 from "../assets/News1.jpg";
// import Head from "next/head";

export default function News(props) {
    return(
        <>
{/* 
      <Head>
        <meta name="title" content="API and Platform News" />
        <meta name="description" content="Keep abreast of relevant news related to APIs and Developer Platform globally" />        
        <meta name="og:title" property="og:title" content="API and Platform News" />   
        <meta name="og:description" property="og:description" content="Keep abreast of relevant news related to APIs and Developer Platform globally" />                        
        <meta name="image" src="./images/NewsHeader.jpg" />
        <meta name="og:image" property="og:image" src="./images/NewsHeader.jpg" /> 
      </Head> */}
        {/* <div className="relative min-h-0">
                 <img src="/images/NewsHeader.jpg" alt="" className="hidden md:block  w-screen   " style={{width:'1440' ,height: '350' }} />
                <div className="absolute top-0 right-0 bottom-0 left-0 " >
                    <div className="flex justify-center items-center h-full">
                        <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]"> API & Platform News</p>
                            <p className="text-[1rem] md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Keep abreast of relevant news related to APIs and Developer Platform globally</p>
                        </div>
                    </div>
                </div>
                </div>
        <div className="relative min-h-0">
                 <img src="/images/News1.jpg" alt="" className="md:hidden block  w-screen   "style={{width:'375' ,height: '350'}} />
                <div className="absolute top-0 right-0 bottom-0 left-0 " >
                    <div className="flex justify-center items-center h-full">
                        <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 md:hidden block">
                            <p className="text-3xl  font-bold mt-0  text-white lg:leading-[58px]"> API & Platform News</p>
                            <p className="text-[1rem] md:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Keep abreast of relevant news related to APIs and Developer Platform globally</p>
                        </div>
                    </div>
                </div>
        </div> */}

                    {/* <div className="relative overflow-hidden bg-no-repeat bg-cover bg- hidden 2xl:block" style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]"> API & Platform News</p>
                            <p className="text-[1rem] md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Keep abreast of relevant news related to APIs and Developer Platform globally</p>
                             </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-hidden bg-no-repeat bg-cover bg- hidden 2xl:hidden lg:block" style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]"> API & Platform News</p>
                            <p className="text-[1rem] md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Keep abreast of relevant news related to APIs and Developer Platform globally</p>
                             </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-hidden bg-no-repeat bg-cover bg- hidden sm:block lg:hidden " style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden sm:block ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]"> API & Platform News</p>
                            <p className="text-[1rem] md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Keep abreast of relevant news related to APIs and Developer Platform globally</p>
                        </div>
                            </div>
                        </div>
                    </div>
            
           
                <div className="relative overflow-hidden bg-no-repeat bg-cover bg- block sm:hidden" style={{ backgroundPosition: '50%',  height: '350px' }}>
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                    <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12  block sm:hidden ">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]"> API & Platform News</p>
                            <p className="text-[1rem] md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3"> Keep abreast of relevant news related to APIs and Developer Platform globally</p>
                        </div>
                </div>
            </div>
        </div> */}
        
        <div className="container-xxl  m-auto">
            <div className=" space-top">
        <div className=" text-light_gray_950 mx-lg-5 mx-3 text-decoration-none"><a href="./blog" className="text-decoration-none home-text">  Blog </a> <span className="heading-text"> / </span><a className="text-decoration-none heading-text"> Banking </a></div> 
        <p className="subtitle  text-left mx-lg-5 mx-3 "> Banking</p> 
        </div>
        </div>
        < TabsComponent MarketNews={props} />
       
         </>
    )
}