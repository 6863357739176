import React, { useState, useEffect } from "react";
import Hidenavbar from "./Hidenavbar";
import Validation from "./Validation";
import agentPartner from "../assets/agentPartner.svg";
import channelPartner from "../assets/channelPartner.svg";
import { Form, Field } from "react-final-form";
import Button from "../functions/button";
import { savePartnerWithUs } from "../functions/function";

export default function PartnerWithUs() {
  const [requestSuccess, setRequestSuccess] = useState(false);

  useEffect(() => {
    document.title = "Finplex: Partner With Us";
  }, []);

  const onSubmit = async (values) => {
    try {
      if (
        values.name !== undefined &&
        values.email !== undefined &&
        values.mobilenumber !== undefined
      ) {
        alert("Form submitted successfully");
        window.location.reload();
      }
      /*   const result = await savePartnerWithUs(values);
            if( result.data.status === "Mail Sent") {
              setRequestSuccess (true)
              setTimeout(() => {
                setRequestSuccess(false);
                window.location.reload();
              },4000);
            } 
         */
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="about">
      <div className="container px-3 px-xl-5 margin-top">
        <div className="row ">
          {requestSuccess ? (
            <div class="succes-message">
              <div class="success-text">Request sent Successfully.</div>
            </div>
          ) : (
            ""
          )}
          <p className="heading text-center">Partner With Us</p>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 px-4">
            <div className="row">
              {/* <p className="sub-heading mt-1 center-sm">Agent Partners</p> */}
              <div className="d-flex align-items-center justify-content-center mt-3">
                <img src={agentPartner} className="img-fluid" />
              </div>
              <div className="mt-3 center-sm">
                <p className="content-text  center-sm" id="partnerWithUs">
                  In our quest to broaden our business lending realm, we're
                  excited to team up with visionary experts and ambitious
                  professionals. Join us in connecting small and medium
                  businesses, sparking mutual growth. Together, we'll forge
                  fresh avenues and power business success across the spectrum.
                </p>
              </div>
            </div>
            {/*    <div className="row mt-5">
                            <p className="sub-heading mt-1 center-sm">Channel Partners</p>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <img src={channelPartner} className="img-fluid" />
                            </div>
                            <div className="mt-3 center-sm">
                                <p className="content-text  center-sm">
                                    Finplex is always on the exploration of seeking channel partners who are accredited as the best technocrats in the industries offering impeccable client-oriented services and products that enhance the automation of financial processes to make business lending to small and medium businesses simple, swift, secure and innovative.</p>
                            </div>
                        </div>
 */}
          </div>
          <div className="col-lg-6 px-4  px-lg-5 mt-5 mt-lg-0 leftBorder">
            <p className="sub-heading mt-1  center-sm">Get In Touch With Us</p>
            <p className="content-text lh-sm  center-sm" id="partnerWithUs">
              Eager to make waves in tech-driven business loans and top-notch
              service? Join us by registering, and together, let's reshape
              business financing for lasting success. Welcome to our exciting
              journey!
            </p>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                name: "",
                email: "",
                mobilenumber: "",
                organization: "",
                message: "",
              }}
              validate={(values) => {
                const errors = {};

                function validateEmail(email) {
                  var re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return re.test(String(email).toLowerCase());
                }

                function validateTextOnly(data) {
                  var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                  return re.test(String(data));
                }

                function validateNumberOnly(data) {
                  var re = /^[0-9]*$/;
                  return re.test(Number(data));
                }

                if (!values.name) {
                  errors.name = "Required";
                } else if (!validateTextOnly(values.name)) {
                  errors.name = "Please enter letters only";
                } else if (values.name.length < 3) {
                  errors.name = "Please enter minimum 3 characters";
                }

                if (!values.email) {
                  errors.email = "Required";
                } else if (!validateEmail(values.email)) {
                  errors.email = "Please enter valid email";
                }

                if (!values.mobilenumber) {
                  errors.mobilenumber = "Required";
                } else if (!validateNumberOnly(values.mobilenumber)) {
                  errors.mobilenumber = "Please enter numbers  only";
                } else if (values.mobilenumber.length < 10) {
                  errors.mobilenumber =
                    "Phone Number should contain 10 numbers";
                }

                if (values.organization) {
                  if (!validateTextOnly(values.organization)) {
                    errors.organization = "Please enter letters only";
                  } else if (values.organization.length < 3) {
                    errors.organization = "Please enter minimum 3 characters";
                  }
                }

                return errors;
              }}
              render={({ handleSubmit, values }) => (
                <form className="row g-3 field-text" onSubmit={handleSubmit}>
                  {/*  <div className="form-check col-6 mt-5 padding">
          <input type="radio" className="form-check-input mt-2" id="radio1" name="optradio" defaultValue="Agent Partner" defaultChecked />
          <label className="form-check-label" htmlFor="radio1">Agent Partner</label>
        </div>
        <div className="form-check col-6 mt-5">
          <input type="radio" className="form-check-input mt-2" id="radio2" name="optradio" defaultValue="Channel Partner" />
          <label className="form-check-label" htmlFor="radio2">Channel Partner</label>
        </div> */}
                  <div className="col-12 mt-5">
                    <Field
                      name="name"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="name" className="form-label">
                            Name
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-12 marginTop">
                    <Field
                      name="email"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-12 marginTop">
                    <Field
                      name="mobilenumber"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="mobilenumber" className="form-label">
                            Phone Number
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-12 marginTop">
                    <Field
                      name="organization"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="organization" className="form-label">
                            Organisation{" "}
                            <span className="fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-12 marginTop">
                    <Field
                      name="message"
                      render={({ input, meta }) => (
                        <div>
                          <label htmlFor="message" className="form-label">
                            Message <span className="fontSize">(Optional)</span>
                          </label>
                          <input
                            {...input}
                            className={
                              meta.touched && meta.error
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {meta.touched && meta.error && (
                            <span className="invalid-feedback">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-12 marginTop flex">
                    <Button
                      type="submit"
                      label="SUBMIT"
                      className="nav-btn float-end content-text mt-3 text-white text-center"
                      handleClick={onSubmit}
                    />
                  </div>
                </form>
              )}
            ></Form>
          </div>
          {/*  <div className="col-lg-6 px-4  px-lg-5 mt-5 mt-lg-0 leftBorder">
                        <p className="sub-heading mt-1  center-sm">Get In Touch With Us</p>
                        <p className="content-text lh-sm  center-sm">If you are interested in partnering with Finplex and matching our mission to make a marked difference in the field of technically powered business loans, and offering top-notch services to clients, you are welcome to join us. Please fill in the registration form.</p>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{name: '', email: '', mobilenumber: '', organization: '', message: '' }}
                            validate={values => {
                                const errors = {};

                                function validateEmail(email) {
                                    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    return re.test(String(email).toLowerCase());
                                }

                                function validateTextOnly(data) {
                                    var re = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
                                    return re.test(String(data));
                                }

                                function validateNumberOnly(data) {
                                    var re = /^[0-9]*$/;
                                    return re.test(Number(data));
                                }

                                if (!values.name) {
                                    errors.name = "Required";
                                } else if (!validateTextOnly(values.name)) {
                                    errors.name = "Please enter letters only";
                                } else if (values.name.length < 3) {
                                    errors.name = 'Please enter minimum 3 characters';
                                }

                                if (!values.email) {
                                    errors.email = "Required";
                                } else if (!validateEmail(values.email)) {
                                    errors.email = "Please enter valid email";
                                }

                                if (!values.mobilenumber) {
                                    errors.mobilenumber = "Required";
                                } else if (!validateNumberOnly(values.mobilenumber)) {
                                    errors.mobilenumber = "Please enter numbers  only";
                                } else if (values.mobilenumber.length < 10) {
                                    errors.mobilenumber = "Phone Number should contain 10 numbers";
                                }

                                if (values.organization) {
                                    if (!validateTextOnly(values.organization)) {
                                        errors.organization = "Please enter letters only";
                                    } else if (values.organization.length < 3) {
                                        errors.organization = 'Please enter minimum 3 characters';
                                    }
                                }
                                if (!values.AgentPartner) {
                                    errors.reception = 'Required'
                                }
                                if (!values.ChannelPartner) {
                                    errors.reception = 'Required'
                                }

                                return errors;
                            }}
                            render={({ handleSubmit, values }) => (
                                <form className="row g-3 field-text" onSubmit={handleSubmit}>
                                    
                                    <div className="form-check col-md-6 mt-5 ms-2 ms-md-0">
          <input type="radio" className="form-check-input" id="radio1" name="optradio" defaultValue="Agent Partner" defaultChecked />
          <label className="form-check-label" htmlFor="radio1">Agent Partner</label>
        </div>
        <div className="form-check col-md-6 mt-5 ms-2 ms-md-0">
          <input type="radio" className="form-check-input" id="radio2" name="optradio" defaultValue="Channel Partner" />
          <label className="form-check-label" htmlFor="radio2">Channel Partner</label>
        </div>
        
                                    <div className="col-12 mt-5">
                                        <Field
                                            name="name"
                                            render={({ input, meta }) => (
                                                <div>
                                                    <label htmlFor="name" className="form-label">
                                                        Name
                                                    </label>
                                                    <input
                                                        {...input}
                                                        className={
                                                            meta.touched && meta.error
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <span className="invalid-feedback">{meta.error}</span>
                                                    )}
                                                </div>

                                            )}
                                        />
                                    </div>
                                    <div className="col-12 marginTop">
                                        <Field
                                            name="email"
                                            render={({ input, meta }) => (
                                                <div>
                                                    <label htmlFor="email" className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        {...input}
                                                        className={
                                                            meta.touched && meta.error
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <span className="invalid-feedback">{meta.error}</span>
                                                    )}
                                                </div>

                                            )}
                                        />
                                    </div>

                                    <div className="col-xl-5 marginTop">
                                        <Field
                                            name="mobilenumber"
                                            render={({ input, meta }) => (
                                                <div>
                                                    <label htmlFor="mobilenumber" className="form-label">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        {...input}
                                                        className={
                                                            meta.touched && meta.error
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <span className="invalid-feedback">{meta.error}</span>
                                                    )}
                                                </div>

                                            )}
                                        />
                                    </div>
                                    <div className="col-xl-7 marginTop">
                                        <Field
                                            name="organization"
                                            render={({ input, meta }) => (
                                                <div>
                                                    <label htmlFor="organization" className="form-label">
                                                        Organisation  <span className="fontSize">(Optional)</span>
                                                    </label>
                                                    <input
                                                        {...input}
                                                        className={
                                                            meta.touched && meta.error
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <span className="invalid-feedback">{meta.error}</span>
                                                    )}
                                                </div>

                                            )}
                                        />
                                    </div>
                                    <div className="col-12 marginTop">
                                        <Field
                                            name="message"
                                            render={({ input, meta }) => (
                                                <div>
                                                    <label htmlFor="message" className="form-label">
                                                        Message  <span className="fontSize">(Optional)</span>
                                                    </label>
                                                    <input
                                                        {...input}
                                                        className={
                                                            meta.touched && meta.error
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                    />
                                                    {meta.touched && meta.error && (
                                                        <span className="invalid-feedback">{meta.error}</span>
                                                    )}
                                                </div>


                                            )}
                                        />
                                    </div>
                                    <div className="col-12 marginTop flex">
                                        <Button
                                            type="submit"
                                            label="SUBMIT"
                                            className="nav-btn float-end content-text mt-3 text-white text-center"
                                            handleClick={onSubmit}
                                        />
                                    </div>


                                </form>
                            )}
                        ></Form>

                    </div> */}
        </div>
      </div>
      <Hidenavbar />
      <Validation />
    </div>
  );
}
