import React from "react";
import Polygon1 from "../assets/Polygon 1.svg";
import docsloan from "../assets/docs-loan.svg";

export default class docrequired extends React.Component {
  render() {
    return (
      <>
        <div>
          <div className="loan-bg">
            <div className="container-fluid px-xxl d-none d-lg-block">
              <div className="row px-3 px-xl-5">
                <p className="mt-5" />
                <p className="sub-title ">Documents Required For Loan</p>
                <div className="col-12 col-lg-9 mt-4 ">
                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>
                    <span>
                      <b> Proof of Identity & Age Proof Passport</b> <br />{" "}
                      Valid driving license, Voters ID Card, Pan card, Aadhaar
                      card
                    </span>
                  </p>
                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>
                    <span>
                      <b> Proof of Address & Signature</b>
                      <br /> Valid driving license, Leave & License Agreement,
                      Register sale deed or sale agreement
                    </span>
                  </p>
                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>{" "}
                    <b> Proof of Address of the Firm</b>
                    <br /> Latest Telephone Bill (or) Electricity Bill (or) Bank
                    Statement (or) Leave and licence agreement
                  </p>
                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>{" "}
                    <b> Proof of Financial Statement</b>
                    <br /> Audited Financial Statement, ITR of the last 2 or 3
                    years along with the schedules, Notes to Accounts and Audit
                    Report & All relevant annexures
                  </p>

                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>{" "}
                    <b> Proof of Bank Statement </b>
                    <br />
                    Last 6 or 12 months, Self-Certified Provisional Balance
                    Sheet and P&L Account of latest Year Sheet
                  </p>

                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>{" "}
                    <b> Proof of Company Documents Firm</b>
                    <br /> Registration (or) Shop Establishment (or) CST (or)
                    VAT (or) GST Certificate.
                  </p>

                  <p className="content-text">
                    <span className="me-2 ">
                      <img src={Polygon1} alt="" />
                    </span>{" "}
                    <b> Proof of Property</b>
                    <br /> Property paper including Title Deed, Completion
                    Certificate, Occupancy Certificate, Tax Receipt, Sanction
                    Plan, etc.
                  </p>
                </div>
                <div className="col-12 col-lg-3 mt-5 mt-xl-2 text-center">
                  <img className="img-fluid  mt-md-5" src={docsloan} alt="" />
                </div>
                <p className="mb-5" />
              </div>
            </div>
          </div>
          <div className="loan-bg d-lg-none">
            <div className="container-fluid px-xxl">
              <div className="row px-3">
                <p className="sub-title center-sm">
                  Documents Required For Loan
                </p>
                <div className="row px-3 px-xl-5 flex-column-reverse">
                  <div className="col-12 col-lg-9 mt-4 ">
                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>
                      <span>
                        <b>Proof of Identity & Age Proof Passport</b>
                        <br /> Valid driving license, Voters ID Card, Pan card,
                        Aadhaar card
                      </span>
                    </p>
                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>
                      <span>
                        <b> Proof of Address & Signature</b>
                        <br /> Valid driving license, Leave & License Agreement,
                        Register sale deed or sale agreement
                      </span>
                    </p>
                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>{" "}
                      <b> Proof of Address of the Firm</b>
                      <br /> Latest Telephone Bill (or) Electricity Bill (or)
                      Bank Statement (or) Leave and licence agreement
                    </p>
                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>{" "}
                      <b> Proof of Financial Statement</b>
                      <br /> Audited Financial Statement, ITR of the last 2 or 3
                      years along with the schedules, Notes to Accounts and
                      Audit Report & All relevant annexures
                    </p>

                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>{" "}
                      <b> Proof of Bank Statement </b>
                      <br />
                      Last 6 or 12 months, Self-Certified Provisional Balance
                      Sheet and P&L Account of latest Year Sheet
                    </p>

                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>{" "}
                      <b> Proof of Company Documents </b>
                      <br />
                      Firm Registration (or) Shop Establishment (or) CST (or) VAT
                      (or) GST Certificate.
                    </p>

                    <p className="content-text">
                      <span className="me-2 ">
                        <img src={Polygon1} alt="" />
                      </span>{" "}
                      <b> Proof of Property</b>
                      <br /> Property paper including Title Deed, Completion
                      Certificate, Occupancy Certificate, Tax Receipt, Sanction
                      Plan, etc.
                    </p>
                    <p className="mb-5" />
                  </div>
                  <div className="col-12 col-lg-3 text-center mt-5">
                    <img className="img-fluid" src={docsloan} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
