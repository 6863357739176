import React from 'react'
import { FormGroup, Label, Input } from "reactstrap";
import { Form, Field } from "react-final-form";

function LoanDetails(props) {
    return (
        <div className="">
        <Form
            onSubmit={ props.sub}
            initialValues={{ 
                stepForm : 2,
                 loanAmount : props.stateValue.loanAmount,
               }}
            validate={values => {
          const errors = {};

          function validateNumberLoan(data) {
            var re = /^[0-9]*$/;
            return re.test(Number(data));
          }

          if (!values.loanAmount) {
            errors.loanAmount = "Required";
          } else if (!validateNumberLoan(values.loanAmount)) {
            errors.loanAmount = "Please enter valid amount";
          } else if(values.loanAmount<300000){
            errors.loanAmount = "Loan Amount should be greater than 3 Lakhs"
          } else if(values.loanAmount>4000000){
            errors.loanAmount = "Loan Amount should be less than 40 Lakhs"
          }
        return errors;
      }}
      render={({ handleSubmit }) => (
        <section id="contact-form" className="form-font-styles">
        <main className="form-container form-font-styles w-container row">
          <h2 className="heading text-center">Apply Now</h2>
          <div className="col-11">
          <form onSubmit={handleSubmit}>
          
          <h3 className="sub-heading">Loan Details</h3>
          <div className="formgroup row">
          <div className="fieldgroup left about col-md-5">
              <FormGroup>
                  <Label for="loanAmount" className="field-label content-text about">Loan Amount</Label>
                  <Field name="loanAmount">
                  {({ input, meta }) => (
                      <div>
                      <Input
                          {...input}
                          type="text"
                          className="text-field w-input mb-2"
                          invalid={meta.error && meta.touched}
                      />
                      {meta.error && meta.touched && <span className="error-message error">{meta.error}</span>}
                      </div>
                  )}
                  </Field>
              </FormGroup>
          </div>
          <div className="fieldgroup right">
          </div>
        </div>
        <div className="formgroup row">
        <div className="fieldgroup right-align-btn">
        </div>
        <div className="row marginTop justify-content-md-end align-items-center px-3">
        <a href="#" onClick={()=> props.preBtn(0)} className="link-color text-decoration-underline content-text text-center col-12 col-md-2 m-md-0">PREVIOUS</a>
                      <button className="w-button nav-btn col-12 col-md-4 mt-4 mt-md-0 content-text text-white text-center" type="submit" value="Next" data-wait="Please wait...">Next</button>
                    </div>
        </div>
        <div className="copy-right-text-2"><label className="mt-3 about content-text">© 2022 Finplex. All rights reserved and registered trademarks of Finplex.in. Terms and conditions, features, support, pricing, and service options subject to change without notice.</label></div>
        </form>
        </div>
        </main>
    </section>
      )}
    />
    </div>
    )
}

export default LoanDetails;
