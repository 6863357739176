import { useState, useEffect } from "react";
import axios from "axios";
import Card from "./card";
import { baseCMSUrl } from "./constant";
import { Link } from "react-router-dom";

export default function Events(props) {

  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    document.title = "finplex : Blog";

    const fetchPosts = async () => {
      setLoading(true);
      const response = await axios.get(`${baseCMSUrl}/api/technologies?populate=*`);
      setEventsData(response.data.data.sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt)));
      setLoading(false);
    };
    fetchPosts();
  }, []);


  return (
    <>
      <div className="container-xxl m-auto">
        <div className="px-lg-5 px-2 row">
          {eventsData?.slice(0, props.noOfTiles).map((blog) =>
          <div className="col-xl-4 col-md-6  p-3">
          <Link className="text-decoration-none" key={blog.id} to={`/TechnologyStory?id=${blog.id}`}>  
            <Card date={blog.attributes.date} view={blog.attributes.view} venue={blog.attributes.venue} title={blog.attributes.title} desc={blog.attributes.description} image={`${baseCMSUrl}` + blog.attributes.image.data.attributes.url} />
          </Link>
          </div>
          )}
        </div>
      </div>
    </> 
  )
}