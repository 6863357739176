import {React, useState, useEffect} from "react";
import Card from "./card";
import axios from "axios";
// import { baseCMSUrl } from "./constant";
import { Link } from "react-router-dom";
export default function CaseStudies(props) {

    const [caseStudyData, setCaseStudyData] = useState([]);
 const [loading, setLoading] = useState([]);
 useEffect(() => {
    
    const fetchPosts = async () => {
      setLoading(true);
      const response = await axios.get(`https://cms.jaam.co/api/trendings?populate=*`);
         setCaseStudyData(response.data.data.sort((a, b) => new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt)));        
      setLoading(false);
    };
    fetchPosts();
  }, []);


    return (
        <>
            <div className="container-xxl  m-auto">
               
                <div className="px-lg-5  row">
                    {caseStudyData?.slice(0, props.noOfTiles).map((blog) =>
                    <div className="col-xl-4 col-md-6 p-3">
                     <Link className="text-decoration-none" key={blog.id} to={`/TrendingStory?id=${blog.id}`}>  
                        <Card date={blog.attributes.date} view={blog.attributes.view} videoUrl={blog?.attributes?.VideoId} title={blog.attributes.title} desc={blog.attributes.description} image={`https://cms.jaam.co` + blog.attributes.image.data.attributes.url} />
                   </Link>
                   </div>
                    )}
                </div>

            </div>
        </>
    )
}