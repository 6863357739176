import React from "react";
import { Link } from "react-router-dom";

import Loaneligibility from "./LoanEligibility";
import Docrequired from "./documentsRequired";
import Namepurpose from "./youNameYourPurpose";
import Differentfrom from "./differentFromOthers";
import FreqAskedQuestions from "./frequentlyAskedQuestion";
import percentage from "../assets/competitiveInterest_Rate.svg";
import rupee from "../assets/Rupee_Icon.svg";
import LoanIcon from "../assets/LAPLoanIcon.svg";
import ABB_Loan_Page_Animation from "../assets/ABB_Loan_Page_Animation.gif";
import Calc from "../functions/calculate";
import HidenavBar from "../functions/hideNavBar";

export default class securedloan extends React.Component {
  componentDidMount() {
    document.title = "Finplex: Secured Loans";
  }
  render() {
    return (
      <>
        <div className="fontStyle about">
          <section>
            <div className="container-fluid container-xxl">
              <div className="margin-top d-none d-xl-block">
                <div className="row px-5 px-xxl-1 mt-4">
                  <div className="col-8">
                    <div className="heading-min row">
                      <p className="sec_color">Secured Loans</p>
                    </div>
                    <div className="heading row">
                      <p>Searching for long-term financing for your business or for a property?</p>
                    </div>

                    <div className="row">
                      <p className="heading-min pe-5">
                        Wide spectrum of financial solutions: Loan against Property, Housing Loan, Refinancing of Property, Composite Loan, Balance Transfer, Top-up Loan, Home Improvement and Extension Loan, Construction Loan, and Commercial Property Purchase.
                      </p>
                    </div>

                    <div class="mt-1 mb-5">
                      <Link class="text-white nav-btn" to="/quick">
                        QUICK APPLY
                      </Link>
                    </div>
                  </div>
                  <div className="col-4 d-flex align-items-end mt-5">
                    <img
                      alt=""
                      className="ABB-image"
                      src={ABB_Loan_Page_Animation}
                    />
                  </div>
                </div>
              </div>
              <div className="d-xl-none px-3 px-md-4">
                <p className="heading-min margin-top sec_color">Secured Loans</p>
                <p className="heading">
                  Searching for long-term financing for your business or for a property?
                </p>
                <div className="row">
                  <div className="col-11 col-md-10">
                    <p className="heading-min">
                      Wide spectrum of financial solutions: Loan against Property, Housing Loan, Refinancing of Property, Composite Loan, Balance Transfer, Top-up Loan, Home Improvement and Extension Loan, Construction Loan, and Commercial Property Purchase.
                    </p>
                      <div class="mb-5 mt-4">
                        <Link
                          class="text-white d-flex align-items-center justify-content-center nav-btn"
                          to="/quick"
                        >
                          QUICK APPLY
                        </Link>
                      
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                  <div className="col-12 col-md-7 col-lg-5 text-center">
                    <img
                      alt=""
                      className="img-fluid"
                      src={ABB_Loan_Page_Animation}
                    />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="loan-bg">
                    <div className="container-xxl container-xl container-lg px-sm-5 px-md-3 px-lg-0">
                        <div className="px-3 px-sm-5 px-md-3 py-5 mt-5 mt-xl-0 p-xl-5">
                            <section className="loan-bg card p-3 shadow border-0">
                                <div className="row justify-content-center align-items-center p-3 px-sm-5 px-md-0">
                                  <div className="col-lg-10">
                                  <div className="row justify-content-center align-items-center">
                                    <div className="col-12 col-md-2 py-4 py-md-0 text-center">
                                        <img
                                            alt=""
                                            className="img-fluid"
                                            src={LoanIcon}
                                        />
                                        <hr className="mt-5 px-5 d-block d-md-none" />

                                    </div>
                                    <div className=" col-md-10 mt-1 border-left d-none d-md-block">
                                        <div className="row"> <p className="fw-400">Loan Against Property & Affordable Housing Loans</p></div>
                                        <div className="row">
                                            <div className="col-md-4 border-right py-3  py-sm-4 py-md-0">
                                                <div className="fw-400">Loan amount</div>
                                                <div className="loanAmount">5 - 75 Lakhs</div>
                                            </div>
                                            <div className="col-12 col-md-4 border-right py-3  py-sm-4 py-md-0 pl-md-4 pr-md-2 pr-lg-4">
                                                <div className="fw-400">Tenure</div>
                                                <div className="loanAmount">5 - 20 Years</div>
                                            </div>
                                            <div className="col-12 col-md-4 pl-md-4 py-3 py-sm-4 py-md-0">
                                                <div className="fw-400">Loan approval with in</div>
                                                <div className="loanAmount">72 Hours*</div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>


                                    <div className=" col-md-10 mt-1 d-block d-md-none ">
                                        <div className="row"> <p className="fw-400">Loan Against Property & Affordable Housing Loans</p></div>
                                        <div className="row">
                                            <div className="col-md-4 col-lg-3">
                                                <div className="fw-400">Loan amount</div>
                                                <div className="loanAmount">5 - 75 Lakhs</div>
                                                <hr className=" px-5 my-4" />
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-3 pl-md-4 pr-md-2 pr-lg-4">
                                                <div className="fw-400">Tenure</div>
                                                <div className="loanAmount">5 - 20 Years</div>
                                                <hr className="px-5 my-4" />
                                            </div>
                                            <div className="col-12 col-md-4 pl-md-4">
                                                <div className="fw-400">Loan approval with in</div>
                                                <div className="loanAmount">72 Hours*</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="container-md justify-content-center mt-5">
                                <div className="row jusyify-content-center align-items-center">
                                    <div className="col-md-1 col-lg-2"></div>
                                    <div className="col-md-5 col-lg-4 text-md-center">
                                        <span> <img
                                            alt=""
                                            className="img-fluid"
                                            src={percentage}
                                        />
                                        </span>
                                        <span className="ml-2 fw-400">Competitive Interest Rate</span>
                                    </div>
                                    <div className="col-md-5 col-lg-4 mt-3 mt-md-0 text-md-center">
                                        <span > <img
                                            alt=""
                                            className="img-fluid"
                                            src={rupee}
                                        />
                                        </span>
                                        <span className="ml-2 fw-400">No Hidden Charges</span>
                                    </div>
                                    <div className="col-md-1 col-lg-2"></div>
                                </div>
                            </div>
                            <div className="mt-4 text-md-center loanImgTxtcolor">* Receive loan amount in your bank account within 72 hours* of approval, and in certain instances, possibly even sooner.</div>
                        </div>
                    </div>
                </div>
          <Loaneligibility />
          <Docrequired />
          <Namepurpose />
          <Differentfrom />
          <div className="mt-5 loan-bg">
            <div className="container-fluid container-xxl">
              <div className="row mt-5">
                <div className="col-sm-12 mt-sm-4 px-xl-5">
                  <h1 className="text-center sub-title">
                   Secured Loan Calculator
                  </h1>
                  <Calc />
                  <p className="mb-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid container-xxl">
            <FreqAskedQuestions />
          </div>
        </div>
        <HidenavBar />
      </>
    );
  }
}
