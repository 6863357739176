import React from "react";
import { Link } from "react-router-dom";
export default class PrivacyPolicy extends React.Component
{  componentDidMount(){
  document.title = "Finplex: Privacy Policy"
}

    render()
{
    return(
        <div className="about">
        <div className="container px-sm-5 px-lg-5 mt-5">
          <div className="px-xl-5 content-text">
            <h1 className="text-center heading" style={{ marginTop: "160px" }}>
              {" "}
              Privacy Policy
            </h1>
            <p className="mb-3 sub-heading margin-top ">
              Introduction And Summary
            </p>
            <p className="my-3">
              We are committed to protecting your privacy, whether you are a
              current or a former user of our platform, and we appreciate your
              trust. This Policy describes how we treat user information we
              collect on{" "}
              <Link className="link-color text-decoration-none" to="/">
                {" "}
                http://www.finplex.in,
              </Link>{" "}
              any mobile app or other offline sources ("Sites"). The Sites are
              owned and managed by Finplex Technologies Private Limited
              ("Finplex", "we", "us" or "our"), a company registered under the
              Companies Act 2013 having its registered office at Ground Floor,
              Plot no 7 , Institutional Area, Vasant Kunj, New Delhi-110070 . By
              visiting and/or using our Sites, or accessing our services through
              any other means, you agree to be bound by this Privacy Policy so,
              if you do not, you must immediately cease the use of our Sites and
              any services provided by us. We will amend this Privacy Policy
              from time to time to comply with applicable laws and regulations
              or to meet our changing business needs. You are requested to
              review this Privacy Policy from time- to-time.
            </p>
            <p className="my-3 sub-heading mt-5">How We Collect Information</p>
            <p className="my-3">
              If you engage with us in any way, we may collect information about
              you and, if applicable, about your business, your partners,
              beneficial owners, directors, any guarantors etc., through:
            </p>
            <ul>
              <li className="my-2">
                <p>
                  Our website, any mobile application and through our
                  application processes.
                </p>
              </li>
              <li className="my-2">
                <p>
                  Communications with you - telephonic communications, written
                  communications (email or physical).
                </p>
              </li>
              <li className="my-2">
                <p>
                  Information provided to us on your behalf by your
                  representatives or agents.
                </p>
              </li>
              <li className="my-2">
                <p>
                  Information through engagement on social media, blogs, forums
                  etc.,
                </p>
              </li>
            </ul>
            <p className="my-3 mt-4">
              We also collection information, including personal information,
              from third-party sources, in order to provide you our services.
              This includes:
            </p>
            <ul>
              <li className="my-2">
                <p>
                  Information from credit bureaux, GST Suvidha provider,
                  verification agencies, fraud prevention agencies, insolvency
                  practitioners etc.,
                </p>
              </li>
              <li className="my-2">
                <p>Marketing databases and other sources of commercial data.</p>
              </li>
              <li className="my-2">
                <p>Other publicly available information.</p>
              </li>
            </ul>
            <p className="my-3 mt-5 sub-heading">What Information We Collect</p>
            <p className="my-2">
              If you engage with us, we may need the following information about
              you (and where applicable, your partners, directors, shareholders,
              guarantors, advisors and any business associates and
              representatives):
            </p>
            <ul>
              <li className="my-2">
                <p>
                  Personally Identifiable Information ("PII"), including your
                  personal details and KYC information such as name, date of
                  birth, Permanent Account Number ("PAN"), Aadhar, current and
                  previous addresses, your education and career history, family
                  details, contact information including your telephone numbers,
                  current and previous addresses, your email addresses, fax
                  etc.,
                </p>
              </li>
              <li className="my-2">
                <p>
                  Financial Information, including your bank statements,
                  financial statements, Income Tax details, GST Information,
                  Credit history and reports, your assets and spending habits
                  etc.,
                </p>
              </li>
              <li className="my-2">
                <p>
                  Other information, including, but not limited to, your
                  suggestions, feedback or any comments shared on our website or
                  on other forums, websites, by email or telephone etc.,
                </p>
              </li>
              <li className="my-2">
                <p>
                  We may also collect information related to your interaction
                  with our website and platform, including information such as
                  your IP address, location, operating system, browser type,
                  details of the activities performed by you on our website
                  etc., Like many commercial websites, we use "Cookies", a small
                  file that is downloaded on your device. Cookies allow our web
                  servers to recognise you each time you visit and log
                  information about the pages you visit, the time spent on each
                  page, websites visited before and after your visit to our
                  website etc., Cookies also help in verifying your login
                  credentials, so that you don't have to input them multiple
                  times. This information is collected on an aggregate basis and
                  is not personally linked to you. You are free to disable or
                  delete cookies by changing your browser settings.
                </p>
              </li>
              <li className="my-2">
                <p>
                  All personal information provided to us shall be used in the
                  manner stipulated under the Information technology (Reasonable
                  Security Practices and Procedures and Sensitive Personal Data
                  or Information) Rules, 2011 and any other data protection law
                  in force in India from time to time.
                </p>
              </li>
            </ul>
            <p className="my-3 mt-5 sub-heading">
              Sharing And Disclosing Information
            </p>
            <p className="my-2 ">We may share your data</p>
            <ul>
              <li className="my-2">
                <p>With our companies in our group and our affiliates.</p>
              </li>
              <li className="my-2">
                <p>
                  Our third-party service providers, suppliers, contractors, any
                  sub-contractors, partners, representatives, agents and other
                  such third parties.
                </p>
              </li>
              <li className="my-2">
                <p>
                  With other registered members of our platform, including with
                  banks and NBFCs with whom we have an arrangement to provide
                  services to you.
                </p>
              </li>
              <li className="my-2">
                <p>With credit reference and fraud prevention agencies.</p>
              </li>
              <li className="my-2">
                <p>
                  To a third party, if it acquires, or is in discussions to
                  acquire, all or part of our business or assets.
                </p>
              </li>
              <li className="my-2">
                <p>If you are represented by an agent, to your agent.</p>
              </li>
              <li className="my-2">
                <p>
                  As required, in our good-faith belief, by any applicable law
                  or regulation.
                </p>
              </li>
              <li className="my-2">
                <p>
                  With any other person with your prior consent, express or
                  implied, to do so.
                </p>
              </li>
            </ul>
            <p className="my-3 mt-5">
              You provide your consent for the disclosure of information to us
              or to any third party and it is hereby expressly declared that we
              shall not be responsible for use of your personal information by
              any third party.
            </p>
            <p className="my-3 mt-5 sub-heading">Use Of Information</p>
            <p className="my-3 ">The information shall be used for:</p>
            <ul>
              <li className="my-2">
                <p>
                  Providing services and any service-related activities and for
                  service-improvements.
                </p>
              </li>
              <li className="my-2">
                <p>
                  Marketing new products and services by Finplex or any of its
                  affiliates, service partners or third parties or contact you
                  about products and services that may interest you.
                </p>
              </li>
              <li className="my-2">
                <p>
                  Develop and improve our services, products and business,
                  including analysing and improving our credit analysis models
                  and customer offerings, conduct market research and testing.
                </p>
              </li>
              <li className="my-2">
                <p>
                  To carry out mandatory or other regulatory checks and comply
                  with our legal and regulatory obligations.
                </p>
              </li>
              <li className="my-2">
                <p>
                  For prevention and detection of fraud, money laundering or
                  other such activities.
                </p>
              </li>
            </ul>
            <p className="my-3 mt-5 sub-heading">
              Questions, Complaints Or Concerns
            </p>
            <p className="my-2 mb-5">
              {" "}
              If you have any questions, concerns or complaints about our
              Privacy Policy, or other policies, please email our Grievance
              Officer, Mr. Davender Ahuja, at{" "}
              <a
                href="mailto:grievance@finplex.in"
                className="link-color text-decoration-none"
              >
                grievance@finplex.in.
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
