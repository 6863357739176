// import Contact from './contactUs';
import MarketEvent from "./marketEvents";
import TabsEventComponent from "./tabNavigationEvents";
// import EventsHeader from "../assets/EventsHeader.png";
// import Events from "../assets/Events.png";
// import Head from "next/head";

const EventPage = (props) => {
    
return (

    <>
    {/* <Head>
        <meta name="title" content="API and Platform Ecosystem Events" />
        <meta name="description" content="A comprehensive list of all events related to API and Platform Ecosystems" />        
        <meta name="og:title" property="og:title" content="API and Platform Ecosystem Events" />   
        <meta name="og:description" property="og:description" content="A comprehensive list of all events related to API and Platform Ecosystems" />                        
        <meta name="image" src="./images/EventsHeader.png" />
        <meta name="og:image" property="og:image" src="./images/EventsHeader.png" />
      </Head> */}
        {/* <div className=" relative min-h-0">
        <img src="/images/EventsHeader.png" alt="" className="hidden md:block  w-screen   " style={{width:'1440' ,height: '350' }} />
            <div className="absolute top-0 right-0 bottom-0 left-0">
                <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12  hidden md:block">
                        <p className=" md:text-4xl lg:text-5xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-sm md:text-lg lg:text-xl font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                    </div>
                </div>
            </div>
        </div>
       

        <div className=" relative min-h-0">
        <img src="/images/Events.png" alt="" className="block md:hidden  w-screen   " style={{width:'375' ,height: '350'}} />
            <div className="absolute top-0 right-0 bottom-0 left-0">
                <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6  block md:hidden">
                        <p className="text-3xl md:text-4xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                    </div>
                </div>
            </div>
        </div> */}
{/* 
<div className="relative overflow-hidden bg-no-repeat bg-cover bg-eventsHeader hidden 2xl:block" style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                     </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-hidden bg-no-repeat bg-cover bg-eventsHeader1 hidden 2xl:hidden lg:block" style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 hidden md:block ">
                            <p className="text-3xl md:text-4xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                      </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-hidden bg-no-repeat bg-cover bg-eventsHeader2 hidden sm:block lg:hidden " style={{ backgroundPosition: '50%', height: '350px' }}>
                        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                            <div className="flex justify-center items-center h-full">
                            <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12 sm:block lg:hidden ">
                            <p className="text-3xl md:text-4xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                    </div>
                            </div>
                        </div>
                    </div>
            
           
                <div className="relative overflow-hidden bg-no-repeat bg-cover bg-eventsHeader3 block sm:hidden" style={{ backgroundPosition: '50%',  height: '350px' }}>
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style={{}}>
                    <div className="flex justify-center items-center h-full">
                    <div className="text-center text-white px-6 md:px-12 lg:px-52 2xl:w-9/12  block sm:hidden ">
                    <p className="text-3xl md:text-4xl font-bold mt-0  text-white lg:leading-[58px]">API & Platform Ecosystem Events</p>
                        <p className="text-[1rem] sm:text-lg  font-medium mb-8 text-light_gray_50 md:px-12 lg:px-14 mt-3">A comprehensive list of all events related to API & Platform Ecosystems</p>
                    </div>
                </div>
            </div>
        </div> */}


        <div className="container-xxl m-auto ">
        <div className=" space-top">
            <div className="  text-light_gray_950 mx-lg-5 mx-3  lg:mx-16"><a href="./blog" className='text-decoration-none home-text'>  Blog </a> <span className="heading-text"> / </span> <a className="heading-text text-decoration-none">Technology</a> </div>
            <p className="subtitle  text-left mx-lg-5 mx-3 ">Technology</p>
        </div>
        </div>
        <div className='lg:px-2 mb-10'>
            <TabsEventComponent eventsprops={props}/>
            </div>
           
    </>
)
}
export default EventPage;