import React from "react";
import {Link} from "react-router-dom";
import Hidenavbar from "../functions/hideNavBar";


export default class TermsandCondition extends React.Component {
  componentDidMount(){
    document.title = "Finplex: Terms And Condition"
  }

  render() {
    return (
      <div className="about">
  

        <div className="container px-sm-3 px-md-5 mt-5">
          <div className="px-xl-5 mt-5 content-text">
            <h1 className="  text-center heading margin-top">
              {" "}
              Terms &amp; Condition
            </h1>
            <p className="mb-4 sub-heading margin-top">
              How We Collect Information
            </p>
            <p className="my-3">
              I/We confirm that we have carefully read and understood all the
              terms and conditions and privacy policy of availing services
              offered by Finplex group website at{" "}
              <Link
                className="link-color text-decoration-none"
                to="/termsandcondition"
              >
                {" "}
                https://www.finplex.in/terms-and-conditions{" "}
              </Link>{" "}
              and{" "}
              <Link
                className="link-color text-decoration-none"
                to="/privacypolicy"
              >
                {" "}
                https://www.finplex.in/privacy
              </Link>
              .
            </p>
            <p className="my-4">
              I/We accept the terms and conditions unconditionally and agree
              that these terms and conditions may be amended from time-to-time
              and I agree to abide by these revised terms and conditions.
            </p>
            <p className="my-3">
              I/We understand that the sanction of any loan is at the sole
              discretion of NBFCs and Banks that are offering these loans on
              Finplex platform (Lending Partners). Any sanction of loan(s) is
              subject to my/our execution of necessary documents, providing any
              securities and other requirements to the satisfaction of Lending
              Partners. I/We agree to furnish any additional documents or
              information required by Finplex from time-to-time. Finplex and its
              Lending Partners reserve the right to retain any documents
              submitted and may not return the same.
            </p>
            <p className="mt-4">I/we warrant and represent that</p>
            <ul>
              <li className="my-2">
                <p>
                  I/we am/are resident of India and I/we shall promptly inform
                  Finplex of any possible change in my residence status
                </p>
              </li>
              <li className="my-2">
                <p>
                  {" "}
                  All the information provided by me/us is true and accurate and
                  I/we have not withheld any material information from Finplex.
                </p>
              </li>
              <li className="my-2">
                <p>
                  {" "}
                  There are no insolvency proceedings against me/us nor have
                  I/we have ever been adjudged insolvent.
                </p>
              </li>
              <li className="my-2">
                <p>
                  {" "}
                  I/we shall inform Finplex about any changes in my/our
                  employment/occupation and to provide further information if
                  required by Finplex and;
                </p>
              </li>
              <li className="my-2">
                <p>
                  {" "}
                  I/we agree with the Privacy policy of Finplex, as revised from
                  time-to-time.
                </p>
              </li>
            </ul>
            <p className="mt-4">I/we undertake and acknowledge that</p>
            <ul>
              <li className="my-2">
                <p>
                  Finplex and/or its Lending Partners may access credit report
                  with information about credit history from any credit
                  information company authorised by RBI for all the applicants
                  and co-applicants. I have obtained consent from all
                  co-applicants in this regard and
                </p>
              </li>
              <li className="my-2">
                <p>
                  {" "}
                  Credit information companies may use and process all
                  information about me/us provided by Finplex in any manner that
                  they seem fit.
                </p>
              </li>
            </ul>
            <p className="my-4">
              {" "}
              I/We further authorise Finplex and its affiliates to keep me/us
              informed about all their products, services and any promotional
              schemes.
            </p>
          </div>
        </div>
       
        <Hidenavbar />
      </div>
    );
  }
}
